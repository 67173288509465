import Cookies from "js-cookie";
import i18n from "i18next";
import {useEffect, useState} from "react";

const permittedLanguages = ['en', 'ca', 'es'];

//check if language is valid, it not valid, return defaul language
//if is valid, return the language passed by param
export function isValidLanguage(language) {
    if(!permittedLanguages.includes(language)) {
        return 'es';
    }
    return language;
}

//return a current language set or a default language
//in function of the set language is valid or not
export function getLanguage() {
    return isValidLanguage(Cookies.get('language'));
}

//return app permitted languages array
export function getPermittedLanguages() {
    return permittedLanguages;
}

export function translateFromObject(text, config = {}) {
    if(typeof text === 'string' || text instanceof String) {
        text = i18n.t(text, config)
    }
    return text;
}

//set a new app language. If new  language is valid, set the new language,
//if is not valid, set the default language
export function setLanguage(language, callback = () => {}) {
    //fix for message verification
    if(window.messageVerificationNotyf) { window.messageVerificationNotyf.dismissAll(); }
    //get valid language
    const lang =  isValidLanguage(language);
    //set language too cookie
    Cookies.set('language', lang, { expires: 365 });
    //update cookies message
    setCookiesMessage(language);
    //if is already set in i18n, not change interface
    if(lang === i18n.language) { return; }
    //set to interface
    i18n.changeLanguage(lang);
    //call to callback
    callback(lang);
}

//set hook to highlight a language selected
export default function useLanguageTextHighlight() {
    const [languageTextToHighlight, setLanguageTextToHighlight] = useState(getLanguage());

    useEffect(() => {
        //set language to interface and cookie
        setLanguage(languageTextToHighlight);
    }, [languageTextToHighlight]);

    return { languageTextToHighlight, setLanguageTextToHighlight };
}

//return a random loading message
//https://gist.github.com/meain/6440b706a97d2dd71574769517e7ed32
const loadingMessages = [
    {'ca': 'Els bits s\'estan reproduint. Ajuda!', 'en': 'The bits are breeding. Help!', 'es': 'Los bits se están reproduciendo. Ayuda!' },
    {'ca': 'Pollastre, carn o tofu?', 'en': 'Would you prefer chicken, steak, or tofu?', 'es': 'Pollo, carne o tofu?'},
    {'ca': '(No prestis atenció a l\'home que hi ha darrere de la cortina)', 'en': '(Pay no attention to the man behind the curtain)', 'es': '(No prestes atención al hombre que hay detrás de la cortina)'},
    {'ca': '...i gaudeix de la música de l\'ascensor...', 'en': '...and enjoy the elevator music...', 'es': '...y disfruta de la música del ascensor...'},
    {'ca': 'Si us plau, esperi mentre els elfs fan la seva feina', 'en': 'Please wait while the little elves draw your map', 'es': 'Por favor, espere mientras los elfos hacen su trabajo'},
    {'ca': 'No et preocupis - uns quants bits han intentat escapar, però els hem atrapat', 'en': 'Don\'t worry - a few bits tried to escape, but we caught them', 'es': 'No te preocupes - unos cuántos bits han intentado escapar, pero los hemos atrapado'},
    {'ca': 'S\'està comprovant la constant gravitacional a la vostra localitat...', 'en': 'Checking the gravitational constant in your locale...', 'es': 'Se está comprobando la constante gravitacional en vuestra localidad...'},
    {'ca': 'Estem arribant... mantingues l\'esperança!', 'en': 'Go ahead -- hold your breath!', 'es': 'Ya queda poco... mantén al esperanza'},
    {'ca': 'Per què no demanar un sandvitx?', 'en': 'Why don\'t you order a sandwich?', 'es': 'Por qué no pedir un sándwich?'},
    {'ca': 'Mentre el satèl·lit es mou a la seva lozalització', 'en': 'While the satellite moves into position', 'es': 'Mientras el satélite se mueve a su posición'},
    {'ca': 'keep calm and npm install', 'en': 'keep calm and npm install', 'es': 'keep calm and npm install'},
    {'ca': 'No ets tu. Soc jo.', 'en': 'It\'s not you. It\'s me.', 'es': 'No eres tú. Soy yo.'},
    {'ca': 'Comptant cap enrere des de l\'infinit', 'en': 'Counting backwards from Infinity', 'es': 'Contando hacia atrás desde el infinito'},
    {'ca': 'Tranquil·litat i seny!', 'en': 'Don\'t panic...', 'es': 'Que no cunda el pánico...'},
    {'ca': 'No marxis! Som els teus amics!', 'en': 'Do not run! We are your friends!', 'es': 'No marches! Somos tus amigos!'},
    {'ca': 'Si us plau, esperi mentre reescalfarem el nostre cafè', 'en': 'Please hold on as we reheat our coffee', 'es': 'Por favor, espere mientras recalentamos nuestro café'},
    {'ca': 'Un moment, estem convertint aquest error en una característica...', 'en': 'Kindly hold on as we convert this bug to a feature...', 'es': 'Un momento, estamos convertiendo este error en una característica...'},
    {'ca': 'Winter is coming...', 'en': 'Winter is coming...', 'es': 'Winter is coming...'},
    {'ca': 'Un moment, estic buscant algú que em porti la cervesa', 'en': 'Finding someone to hold my beer', 'es': 'Un momento, estoy buscando a alguien que me traiga la cerveza'},
    {'ca': 'Torno aviat, estic treballant en un altre projecte', 'en': 'BRB, working on my side project', 'es': 'Vuelvo pronto, estoy trabajando en otro proyecto'},
    {'ca': '@todo Ficar un missatge de càrrega aquí', 'en': '@todo Insert witty loading message', 'es': '@todo Poner un mensaje de carga aquí'},
    {'ca': 'Aw, quisso! No..', 'en': 'Aw, snap! Not..', 'es': 'Aw, gos! No..'},
    {'ca': 'Fem el que fem, no miris darrere teu...', 'en': 'Whatever you do, don\'t look behind you...', 'es': 'Hagamos el que Hagamos, no mires detrás de ti...'},
    {'ca': 'Espera... Estem donant una ullada al manual...', 'en': 'Please wait... Consulting the manual...', 'es': 'Espera... Estamos consultando el manual...'},
    {'ca': 'Esperant que Daenerys digui tots els seus títols...', 'en': 'Waiting Daenerys say all her titles...', 'es': 'Esperando que Daenerys diga todos sus títulos...'},
    {'ca': 'Tingues llibertat de donar voltes sobre la cadira', 'en': 'Feel free to spin in your chair', 'es': 'Ten libertad de girar y girar en la silla'},
    {'ca': 'Ajuda, estic atrapat en un \'loader\'!', 'en': 'Help, I\'m trapped in a loader!', 'es': 'Ayuda, estoy atrapado en un \'loader\'!'},
    {'ca': 'Si us plau, espera mentre purguem els Decepticons. Sí, ens pots donar les gràcies més endavant!', 'en': 'Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!', 'es': 'Por favor, espere mientras purgamos los Decepticons. ¡Sí, nos puede dar las gracias más adelante!'},
    {'ca': 'En Chuck Norris no porta rellotge. Decideix quina hora és.', 'en': 'Chuck Norris doesn’t wear a watch. HE decides what time it is.', 'es': 'En Chuck Norris no lleva reloj. Decide qué hora es.'},
    {'ca': 'Quin temps fa, eh?', 'en': 'How about this weather, eh?', 'es': 'Qué tiempo hace, eh?'},
    {'ca': 'Tot en aquest univers és o no és una patata', 'en': 'Everything in this universe is either a potato or not a potato', 'es': 'Todo en este universo es o no es una patata'},
]

export function getRandomLoadingMessage(lang) {
    const messagesAvailable = loadingMessages.length;
    const messageNumberSelected = Math.floor(Math.random() * (messagesAvailable - 0)) + 0;
    return loadingMessages[messageNumberSelected][lang];
}

const cookiesMessage = {
    "ca": "Utilitzem galetes pròpies i de tercers per a obtenir dades estadístiques d'ús de l'aplicatiu i millorar l'experiència d'usuari. Si continua navegant, considerem que accepta el seu ús. Pot obtenir més informació fent clic <a href='/cookies' target='_blank' rel='noopener noreferrer'>aquí</a>.",
    "en": "We use our own cookies and third-party cookies to obtain statistical applicability data and improve user experience. If it continues on the web, we consider it accepts its use. You can learn more by clicking <a href='/cookies' target='_blank' rel='noopener noreferrer'>here</a>.",
    "es": "Utilizamos cookies propias y de terceros para obtener datos estadísticos de uso del aplicativo y mejorar la experiencia de usuario. Si continúa navegando, consideramos que acepta su uso. Puede obtener más información <a href='/cookies' target='_blank' rel='noopener noreferrer'>aquí</a>."
}

export const setCookiesMessage = (language) => {
    const message = getCookiesMessage(language);
    const element = document.querySelector(".notify-cookies .notyf__message");
    //if element exists update content with a new message
    if(element) {
        element.innerHTML = message;
    }
}

export const getCookiesMessage = (language) => {
    if(cookiesMessage[language]) {
        return cookiesMessage[language];
    } else {
        return cookiesMessage.es;
    }
}