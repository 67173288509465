import React, {useRef, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalWindow from "components/Modal/ModalWindow";
import { Menu, Dropdown, Button } from 'antd';
import {CalendarOutlined, ReloadOutlined} from '@ant-design/icons';
import RangePickerCustom from "components/Picker/RangePickerCustom";
import useWindowDimensions from "utils/Dimensions";
import {getDataPickerData, translateDatesFromStringToServer} from "utils/Time";

//drop down list
export function DropdownTime({...props}) {
    //get configuration
    const configuration = props.configuration;
    //translations
    const {t} = useTranslation();
    //ref for button
    const buttonRef = useRef();
    //use state to save date
    const [dates, setDates] = useState({dates: null, dateStrings: null});
    const {width} = useWindowDimensions();
    //to force refresh component by key
    const buttonText = t(configuration.text);
    //when element on list is clicked
    const handleMenuClick = (e) => {
        const [text, datesInterval, endpoint] = getDataPickerData(e.domEvent.target.closest('.ant-dropdown-menu-item').getAttribute('data-text'));
        props.handler({ ...configuration, isLoading: true, text: text, dates: datesInterval, url: endpoint, value: configuration.defaultValue });
     } //check if element is disabled
    const isDisabled = (text) => {
        return configuration.text === text;
    } //set selected date
    const rangePickerChange = (dates, dateStrings) => {
        setDates({dates: dates, dateStrings: dateStrings});
    } //set configuration for modal
    const modalConfiguration = {
        button: {
            accept: {
                text: "customize",
                handler: (modalId, callback) => {
                    callback(1);
                    //get data from data picker
                    const [text, datesInterval, endpoint] = getDataPickerData({text: "customize", dates: translateDatesFromStringToServer(dates.dateStrings)});
                    props.handler({ ...configuration, isLoading: true, text: text, dates: datesInterval, url: endpoint, value: configuration.defaultValue });
                }
            }
        },
        title: 'time.modal.title',
        body: <><RangePickerCustom defaultValue={dates.dates} onChange={rangePickerChange} /></>
    }

    useEffect(() => {
        //get header
        const header = buttonRef.current.closest('.card-header');
        //if not header
        if(!header || header.clientWidth > 319) { return; }
        //get button wrapper
        const buttonWrapper = buttonRef.current.closest('.ant-btn');
        //hide button
        if(header.clientWidth < 319) {
            buttonWrapper.style.display = 'none';
        //show button
        } else {
            buttonWrapper.style.visibility = undefined;
        }
    },[width, buttonText] );

    //set default menu
    let defaultMenu = ['time.today','time.month','time.year'];
    //if is menu is set
    if(configuration && configuration.menu && configuration.menu.length > 0) {
        defaultMenu = configuration.menu;
    }

    let custom = <>
        <Menu.Divider />
        {/* Modal and modal caller */}
        <ModalWindow configuration={modalConfiguration} caller={
            <Menu.Item key={"customize"} className={'dropdown-item-small'}>
                { t("customize") }
            </Menu.Item>
        } />
    </>
    //if is menu is set
    if(configuration && configuration.notCustom && configuration.notCustom === true) {
        custom = <></>;
    }

    //create a menu
    const menu = (
         <Menu>
             { /* default links */
                 defaultMenu.map((text, index) => (
                     <Menu.Item key={text} data-text={text} onClick={handleMenuClick} disabled={isDisabled(text)} className={'dropdown-item-small'}>
                         { t(text) }
                     </Menu.Item>
                 ))
             }
             { custom }
        </Menu>
    );

    return (
        <Dropdown.Button key={buttonText} size='small' trigger='click' overlay={menu} placement="bottomRight" icon={<CalendarOutlined />} >
            <span ref={buttonRef}>{ buttonText }</span>
        </Dropdown.Button>
    )
}

export default function TimeMenu({...props}) {
    //reference to delete events of button
    const button = useRef();
    //translations
    const {t} = useTranslation();
    const elText = t(props.configuration.text);
    //disable all event on button
    useEffect(() => {
        const elem = button.current.querySelector('.ant-btn-group button:first-child');
        elem.replaceWith(elem.cloneNode(true));
    }, [elText]);

    //reload button
    const handler = (e) => {
        //remove blur on click button
        e.target.closest('.no-focus').blur();
        //handler to reload
        props.handler({ ...props.configuration, isLoading: true });
    }

    let refresh = <div style={{marginLeft: "-1px"}}>
        <Button className={"no-focus"} icon={<ReloadOutlined />} size='small' onClick={handler}/>
    </div>

    if(props.configuration.notRefresh && props.configuration.notRefresh === true) {
        refresh = <></>
    }

    return (
        <div className="time-card-wrapper d-flex align-content-center" ref={button}>
            <DropdownTime {...props} />
            { refresh }
        </div>
    )

}