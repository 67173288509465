import React from "react";
import {useTranslation} from "react-i18next";
import {AlertCircle, Trash2} from "react-feather";
import {useForm} from "react-hook-form";
import {deleteAccountRequest} from "utils/Requests/Auth";
import SettingsPasswordInput from "components/Forms/Inputs/settings/SettingsPasswordInput";
import {getPasswordValue} from "utils/Forms";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function CardDeleteAccount({...props}) {
    const {t} = useTranslation();
    //call to use hook
    const methods = useForm({
        mode: 'onSubmit',
        defaultValues: {
            user_password: ''
        } }); //get used methods
    const { handleSubmit, formState: { isSubmitting, isDirty } } = methods;
    //event to request...
    const onSubmit = async (data) => {
        await deleteAccountRequest({ user_password: getPasswordValue(data.user_password) });
    }

    return (
        <>
            <div className={`card ${isMobileOnly && "card-mbl"}`}>
                <div className={`card-header ${!isMobileOnly && "border-bottom"}`}>
                    <h4 className="card-title d-flex flex-row">
                       <Trash2 size={20}/>
                        <span style={{marginLeft: "5px"}}>{t("account.delete_account")}</span>
                    </h4>
                </div>
                <div className={`card-body ${isMobileOnly ? "card-body-mbl pt-20-mbl" : "py-2 my-25"}`}>
                    <div className="alert alert-danger fade show" role="alert">
                            <h4 className="alert-heading d-flex flex-row">
                                <AlertCircle size={17} />
                                <span style={{marginLeft: "5px"}}>{t("account.delete_account_question")}</span>
                            </h4>
                        <div className="alert-body fw-normal">
                            { t("account.delete_account_message") }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12">
                            <SettingsPasswordInput methods={methods} />
                        </div>
                        <div className="mt-1">
                            <button type="submit" className="btn btn-danger" disabled={!isDirty || isSubmitting}>
                                {t("delete")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}