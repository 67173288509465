import sha256 from "sha256";
import $ from 'jquery';

//return all forms params in query string
export function serialize(formData) {
    const data = new URLSearchParams();

    Object.keys(formData).forEach(function(key) {
        data.append(key, formData[key]);
    });

    return data;
}

//return a form
export function getForm(e) {
    return e.target.closest('form');
}

//disable submit button
export function disableElementRequestCaller(e) {
    if(e.target.tagName.toLowerCase() === 'a') {
        $(e.target).addClass('disabled');
    } else if(e.target.tagName.toLowerCase() === 'button') {
        $(e.target).prop('disabled', true);
    }
}

//enable submit button
export function enableElementRequestCaller(e) {
    if(e.target.tagName.toLowerCase() === 'a') {
        $(e.target).removeClass('disabled');
    } else if(e.target.tagName.toLowerCase() === 'button') {
        $(e.target).prop('disabled', false);
    }
}

//validate a form by boostrap way and
//return if this is valid or not
export function validate(e) {
    // get from
    const form = getForm(e);
    //if not form this button is not for a form
    if(!form) { return true; }
    //ad to form a was validated
    form.classList.add('was-validated')
    //check the form
    return form.checkValidity();
}

export function getPasswordValue(value) {
    return sha256(value);
}
//return the value parsed fot authentication forms
//case: password is a sha256 value
export function getValue(event) {
    let value = event.target.value;
    if(event.target.name === 'user_password') {
        value = getPasswordValue(value);
    } else if(value === 'on' || value === true || value === 'yes') {
        value = 1;
    } else if(value === 'off' || value === false || value === 'no') {
        value = 0;
    }
    return value;
}

export function clearRemoteValidatorError(formReference) {
    //mark field to invalid
    const el = $(formReference.current);
    //add class to validate on client if this has validate before
    if(el.hasClass('was-validated-yet')) {
        //remove class was validated from form
        el.removeClass('was-validated-yet').addClass('was-validated');
    } //get helpBlock
    const helpBlock = el.find(`.invalid-remote`);
    //display help block message
    helpBlock.css('display', 'none');
    //mark validate fields
    helpBlock.prevAll('div').removeClass('hidden').addClass('invalid-feedback');
    helpBlock.prevAll('input').removeClass('is-invalid');
}

//show a custom error on form
export function showRemoteValidateError(formReference, invalidHelperClassId) {
    //mark field to invalid
    const el = $(formReference.current);
    //remove class was validated from form
    el.removeClass('was-validated').addClass('was-validated-yet');
    //get helpBlock
    const helpBlock = el.find(`.${invalidHelperClassId}`);
    //display help block message
    helpBlock.css('display', 'block');
    //mark invalid field
    helpBlock.prevAll('div').removeClass('invalid-feedback').addClass('hidden');
    helpBlock.prevAll('input').addClass('is-invalid');
}