import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {getRandomLoadingMessage, isValidLanguage, setLanguage} from "utils/Language";
import {Link, useParams} from "react-router-dom";
import {ValidateEmailRequest} from "utils/Requests/Auth"
//css
import 'assets/css/loader.css';

// message for loader full page
const Loader = ({ ...props }) => {
    //FIX FOR SUSPEND LOADER (TRANSLATIONS NOT LOADED AT THIS POINT)
    //get current language
    const lang = isValidLanguage(Cookies.get('language'));
    //default text...
    let title = 'Getting everything ready';
    //change text in function of language
    if(lang === 'ca') {
        title = 'Preparant la plataforma';
    } else if(lang === 'es') {
        title = 'Preparando la plataforma';
    }

    //return component
    return (
        <div>
            <h2 className="mt-1"> { title } </h2>
            <p className="mt-0 ps-2 pe-2"> { getRandomLoadingMessage(lang) }</p>
        </div>

    );
}

//message for error 404 page
const Error404 = ({ ...props }) => {
    const { t } = useTranslation();

    return (
        <div>
            <h2 className="mt-1"> { t('page_not_found.title') } </h2>
            <p className="ps-2 pe-2">
                <Trans i18nKey="page_not_found.description" components={[<br />]} />
            </p>
            <Link to="/" className="mb-2 btn btn-primary">
                <span>{ t('back_to_home') }</span>
            </Link>
        </div>
    );
}

//message for validate user
const Validate = ({ ...props }) => {
    const { t } = useTranslation();
    //save state when user is confirmed or not
    const [confirmed, setConfirmed] = useState(0);
    //get uuid
    const { uuid } = useParams();
    //after component is rendered
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //only if not end process confirmation
        if(confirmed === 0 ) {
            //start progress bar
            window.progressReference.current.continuousStart();
            //if not set uuid is not confirmed
            if (!uuid) {
                //end complete progress bar
                window.progressReference.current.complete();
                //set to not confirmed
                setConfirmed(-1);
            } else {
                //sent request to validate email
                ValidateEmailRequest(uuid, (status) => {
                    //prevent crash with async function
                    if (!isMounted) return null;
                    setConfirmed(status);
                });
            }
        } //prevent crash with async function
        return () => { isMounted = false; };
    },[confirmed, uuid]);

    //on first load set default text
    let text = t('user_validation.verify_process');
    //if user is confirmed...
    if(confirmed === 1) {
        text = t('user_validation.verify_success');
        //if confirmation fails...
    } else if(confirmed === -1) {
        text = <Trans i18nKey="user_validation.verify_fail" components={[<br />, process.env.REACT_APP_SUPPORT_EMAIL]} />
    }

    return (
        <div>
            <h2 className="mt-1"> { t('user_validation.title') } </h2>
            <p className="ps-2 pe-2">
                { text }
            </p>
            { (confirmed !== 0) &&
            <>
                <Link to="/" className="mb-2 me-1 btn btn-primary">
                    <span>{ t('home') }</span>
                </Link>
                <Link to="#" onClick={() => window.close() } className="mb-2 btn btn-secondary">
                    <span>{ t('close') }</span>
                </Link>
            </>
            }
        </div>
    );
}

// ** MAIN COMPONENTS
export default function PageMessages({ ...props }) {
    //set language in function of url
    const search = window.location.search;
    const params = new URLSearchParams(search);
    ///if is set language in query params, set the language
    if(params.get('lang')) { setLanguage(params.get('lang')); }

    //render function to get a correct component to layout
    const Render = (props) => {
        if(props.element === 'loader') {
            return <Loader {...props} />;
        } else if (props.element === '404'){
            return <Error404 {...props} />;
        } else if (props.element === 'validate'){
            return <Validate {...props} />;
        }
    }

    //layout for render a components
    return (
        <div>
            <div id="loading-mask" />
            <div id="loading">
                <div id="loading-ind" className="loading-indicator">
                    <img id="loading-image" className="loading-image-animate" src={'/' + process.env.PUBLIC_URL + global.config.brand_url} alt="loader" />
                    <Render {...props} />
                </div>
            </div>
        </div>
    )
}