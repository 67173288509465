//libraries
import Brand from "components/Authentication/Brand";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {getValue} from "utils/Forms";
import { useTranslation } from 'react-i18next';
import EmailInput from "components/Forms/Inputs/EmailInput";
// import LanguagesFooter from "components/Langauge";
import DividerEdit from "components/Forms/DividerEdit";
import {RecoveryRequest} from "utils/Requests/Auth"
import { ChevronLeft } from 'react-feather';
import {useNavigate} from "react-router-dom";

export default function Forgot({ ...props }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ user_email: null })
    //on each input change...
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name] : getValue(event) })
    } //when submit form...
    const handleSubmit = (e) => { RecoveryRequest(e, formData, () => { navigate('/login'); }); };

    return (
        <div className="card-body">
            <Brand />
            <h4 className="card-title mb-1 mt-2"> { t('forgot.title') } 🔒</h4>
            <p className="card-text mb-2">{ t('forgot.description') }</p>
            <DividerEdit />
            { /* Form */ }
            <form className="auth-login-form mt-2">
                <EmailInput onChange={handleInputChange} forgot="true" tabIndex="1"/>
                <button className="btn btn-primary w-100 btn-submit" onClick={handleSubmit} tabIndex="2">
                    { t('forgot.send_email') }
                </button>
            </form>
            <p className="text-center mt-2">
                <Link to="/login">
                    <ChevronLeft />
                    <span className="align-middle">{ t('forgot.back') }</span>
                </Link>
            </p>
            {/*<LanguagesFooter />*/}
        </div>
    )
}
