import { Select, Tag } from 'antd';
import {Label} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {getColorByEvent, getColorByStatus} from "utils/Types";

function tagRenderTimeEvents(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color={getColorByEvent(value)}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
}

export default function EventsInput({ ...props}) {
    const {t} = useTranslation();
    const {type} = props;

    return (
        <>
            <Label for='name'>{props.label}</Label>
            <Select
                mode="multiple"
                showArrow
                tagRender={tagRenderTimeEvents}
                style={{ width: '100%' }}
                options={(() => {
                    if(type === 'alerts') {
                        return [
                            { value: '2', label: t('events.no_reading')},
                            { value: '5', label: t('events.overproduction')},
                            { value: '6', label: t('events.underproduction')},
                        ]
                    }
                    return [
                        { value: '1', label: t('events.ok')},
                        { value: '2', label: t('events.no_reading')},
                        { value: '5', label: t('events.overproduction')},
                        { value: '6', label: t('events.underproduction')},
                        { value: '7', label: t('events.in_process')},
                        { value: '8', label: t('events.off')},
                        { value: '0', label: t('events.no_sync')},
                    ]
                })()}
                name={props.name}
                value={props.value}
                onChange={(val) => { props.onChange(props.name, val) }}
            />
        </>
    )
}

function tagRenderStatusEvents(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color={getColorByStatus(value)}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
}

export const globalStatusOptions = [
    { value: '1', label: 'events.offer_pending_approval', id: 'offer_pending_approval', selectedStyle: { border: '2px solid rgb(129 129 129)' } },
    { value: '1', label: 'events.ok', id: 'ok', selectedStyle: { border: '2px solid rgb(40 199 111 / 65%)' } },
    { value: '5,6,7,0,8', label: 'events.warning', id: 'warning', selectedStyle: { border: '2px solid rgb(255 159 67 / 72%)' }},
    { value: '2,3,4', label: 'events.fail', id: 'fail', selectedStyle: { border: '2px solid #ff000073' }}
];

export function StatusInput({ ...props}) {
    const {t} = useTranslation();

    return (
        <>
            {/*<Label for='name'>{props.label}</Label>*/}
            <Select
                {...props}
                placeholder={t("searches.placeholders.status")}
                mode="multiple"
                showArrow
                tagRender={tagRenderStatusEvents}
                style={{ width: '100%' }}
                options={props.options.map((val) => {
                    return {...val, key: val.id, label: t(val.label)};
                })}
                name={props.name}
                value={props.val}
                onChange={(val) => { props.onChange(props.name, val) }}
            />
        </>
    )
}
