import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";

const { v4: uuid } = require('uuid');
//the modal id
const modalId = uuid().replaceAll('-','');

export default function ModalWindow({...props}) {
    const {t} = useTranslation();
    const configuration = props.configuration;
    const [modal, setModal] = useState(null);
    const toggleModal = function(id) {
        if (modal !== id) {
            return setModal(id);
        }
        return setModal(null);
    }

    return (
        <>
            { /* only show caller if it is set */ }
            { props.caller && <span onClick={ () => toggleModal(modalId) }> { props.caller } </span> }
            <Modal isOpen={modal === modalId} toggle={() => toggleModal(modalId)} className={'modal-dialog-centered modal-xs'} key={modalId} fade={false}>
                <ModalHeader animation="false" toggle={() => toggleModal(modalId)}> { t(configuration.title) } </ModalHeader>
                <ModalBody>
                    { configuration.body }
                </ModalBody>
                <ModalFooter>
                    {
                        configuration.button && configuration.button.accept &&
                        <Button size='sm' color='btn btn-outline-primary' onClick={() => configuration.button.accept.handler(modalId, toggleModal) }>
                            { t(configuration.button.accept.text) }
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </>
    )
}