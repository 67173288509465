import moment from "moment";
import {getLanguage} from "utils/Language";
import 'moment-timezone';
import 'moment/min/locales';
import localeESP from 'antd/es/date-picker/locale/es_ES';
import localeCAT from 'antd/es/date-picker/locale/ca_ES';
import localeENG from 'antd/es/date-picker/locale/en_GB';
import {isObject} from "utils/Types";
import capitalize from "capitalize-the-first-letter";
import _ from "lodash";

const { v4: uuid } = require('uuid');
//for sentence case
const sentence = require('to-sentence-case');

export function getCurrentYear() {
    return moment().locale(getLanguage()).format('Y');
}

export function getDaysInMonth(year, month) {
   return moment(`${year}-${month}`, "YYYY-MM").daysInMonth()
}

export function getDateStringFormat() {
    const language = getLanguage();
    if(language === 'es' || language === 'ca' ) {
        return 'DD/MM/YYYY';
    } else {
        return 'YYYY/MM/DD';
    }
}

export function getDateTimeStringFormat() {
    return getDateStringFormat() + ' | HH:mm'
}

export function getYearMonthStringFormat() {
    const language = getLanguage();
    if(language === 'es' || language === 'ca' ) {
        return 'MM/YYYY';
    } else {
        return 'YYYY/MM';
    }
}

export function translateDatesFromStringToServer(arr) {
    const originalFormat = getDateStringFormat()
    return _.map(arr, (val) => {
        return moment(val, originalFormat).format('YYYY-MM-DD');
    })
}

export function geTimeByTimeLine(timeLine) {
    //get text from object
    const text = timeLine.text;
    const clicks = timeLine.clicks;
    //get moment current
    const today = moment().locale(getLanguage());
    //set date in function of text
    if(text === 'time.today') {
        return today.subtract(clicks * -1, 'days').format('ll');
    } else if(text === 'time.month') {
        return sentence(today.subtract(clicks * -1, 'month').format('MMMM Y'));
    } else if(text === 'time.year') {
        return today.subtract(clicks * -1, 'year').format('Y');
    }
}

export function getTimeByTextLink(activeLink) {
    //get text from object
    const text = activeLink.text;
    //get moment current
    const today = moment().locale(getLanguage());
    //set date in function of text
    if(text === 'time.today') {
        return today.format('lll');
    } else if(text === 'time.month') {
        return sentence(today.format('MMMM Y'));
    } else if(text === 'time.year') {
        return today.format('Y');
    } else if(text === 'time.yesterday') {
        return today.subtract(1, 'days').format('ll');
    } else if(text === 'time.last_month') {
         return sentence(today.subtract(1, 'month').format('MMMM Y'));
    } else if(text === 'time.last_year') {
        return today.format('Y') - 1;
    } else if(text === 'custom') {
        //get moment objects
        const timeStart = moment(activeLink.dates[0], 'YYYY-MM-DD').locale(getLanguage());
        const timeEnd = moment(activeLink.dates[1], 'YYYY-MM-DD').locale(getLanguage());
        //if dates are equal
        if(timeStart.isSame(timeEnd)) {
            //is one day...
            return timeStart.format('ll');
        } else {
            return timeStart.format('L') + ' - ' + timeEnd.format('L');
        }
    } //not controlled case...
    return 'Error...';
}

export function getIntervalByTextLink(text) {
    if(text === 'time.today') {
        return [moment(), moment()]; //.subtract(1, 'days')
    } else if(text === 'time.month') {
        return [moment().startOf('month'), moment().endOf('month')];
    } else if(text === 'time.year') {
        return [moment().startOf('year'), moment().endOf('year')];
    } else if(text === 'time.yesterday') {
        return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    } else if(text === 'time.last_month') {
        return [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    } else if(text === 'time.last_year') {
        return [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];
    }
}

export function getTimeLocal() {
    //get current language
    const currentLanguage = getLanguage();
    //return local by language
    if(currentLanguage === 'en') {
        return localeENG;
    } else if(currentLanguage === 'ca') {
        return localeCAT;
    } else if(currentLanguage === 'es') {
        return localeESP;
    }
}

export function setInstantInterval(func, interval) {
    func();
    return setInterval(func, interval);
}

export function getUniqueID() {
    return uuid().replaceAll('-', '');
}

export function getDataPickerData(selected) {
    if (isObject(selected)) {
        if(!selected.dates || !selected.dates[0]) { //if not selected time set to time.today
            return [ 'time.today', null, 'time.today' ];
        }
        return [ 'custom', selected.dates, `custom?start=${selected.dates[0]}&end=${selected.dates[1]}` ];
    } //return selected if is a text time...
    return [ selected, null, selected ];
}


export function getTimeLine(text, clicks) {
    //get today
    const today = moment();
    let time = {};
    //update time to show
    if(text === "time.year") {
        time = {
            text: text,
            url: `${text}?year=${today.subtract(clicks * -1, 'year').format('YYYY')}`
        }
    } else if(text === "time.today") {
        time = {
            text: text,
            url: `${text}?day=${today.subtract(clicks * -1, 'days').format('YYYY-MM-DD')}`
        }
    } else if(text === "time.month") {
        const month = today.subtract(clicks * -1, 'month');
        time = {
            text: text,
            url: `${text}?year=${month.format('YYYY')}&month=${month.format('MM')}`
        }
    }
    return time;
}

export function minusSelectedDate(timeLine, callback) {
    //get current clicks
    let clicks = timeLine.clicks - 1;
    let text = timeLine.text;
     //call callback
    callback(getTimeLine(text, clicks), {text: text, clicks: clicks});
}

export function plusSelectedDate(timeLine, callback) {
    //get current clicks
    let clicks = timeLine.clicks + 1;
    let text = timeLine.text;
    //call callback
    callback(getTimeLine(text, clicks), {text: text, clicks: clicks});
}

export function isToday(date) {
    const today = moment().locale(getLanguage());
    return today.format('ll') === date;
}

export function isThisMonth(date) {
    const today = moment().locale(getLanguage());
    return sentence(today.format('MMMM Y')) === date;

}

export function isThisYear(date) {
    const today = moment().locale(getLanguage());
    return today.format('Y') === date;
}

export function getTimeDateText(dateString) {
    return moment(dateString, 'YYYY-MM-DD').locale(getLanguage()).format(getDateStringFormat());
}

export function getTimeDateTextFull(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm').locale(getLanguage()).format( getDateTimeStringFormat());
}

export function getTimeDateMonthText(dateString) {
    return moment(dateString, 'YYYY-MM').locale(getLanguage()).format(getYearMonthStringFormat());
}

export function getTimeHourText(dateString) {
    return moment(dateString, 'YYYY-MM-DD HH:mm').locale(getLanguage()).format('HH:mm');
}

export function getMonthName(numberSting) {
    return capitalize(moment(`02-${numberSting}-2021`, 'DD-MM-YYYY').locale(getLanguage()).format('MMMM'));
}
