import React from "react";
import 'assets/css/loader.css';
import CardDeleteAccount from "components/Cards/CardDeleteAccount";
import CardProfileDetails from "components/Cards/CardProfileDetails";
import CardChangePassword from "components/Cards/CardChangePassword";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function PageUser({...props}) {
    return (
        <>
            { !isMobileOnly && <div className="content-header row mt-2" /> }

            <div className="content-body">
                <CardProfileDetails {...props} />
                <CardChangePassword {...props} />
                <CardDeleteAccount {...props} />
            </div>
        </>
    )
}