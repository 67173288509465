import React from "react";
import NavbarSimple from "components/Navbar/NavbarSimple";
import {useTranslation} from "react-i18next";
import SimpleDivider from "components/Divider/SimpleDivider";
import useWindowDimensions from "utils/Dimensions";

export default function LayoutSimplePage({...props}) {
    const { t } = useTranslation();
    const { width } =useWindowDimensions();

    //default size text for enterprise
    let sizeText = "16px";
    //if size is less than 300px
    if(width <= 350) { sizeText = "14px"; }

    return (
        <div className="simple-layout wrapper vertical-layout navbar-floating footer-static vertical-menu-modern menu-collapsed" data-col="1-column">
            <div className="app-content content overflow-hidden pt-2" style={{paddingTop: "2px !important"}}>
                <div className="content-wrapper container p-0" style={{fontSize: "16px"}}>
                    { /* navbar */ }
                    <NavbarSimple />
                    { /* Content */ }
                    <div className="ps-1 pe-1">
                        { props.children }
                        <SimpleDivider />
                        <div className="auth-footer-btn d-flex justify-content-center mb-3" style={{ textAlign: "center", fontSize: sizeText }}>
                            - { t('enterprise_brand') } · { new Date().getFullYear() } -
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}