import React from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "utils/Dimensions";

export default function NameInput({ ...props}) {
    const { t } = useTranslation();
    //get windows dimensions
    const { width } = useWindowDimensions();
    //attribute to fix a style fot the second field
    //for responsive
    let styleFix = "col-md-6 ";
    //fix when window is less than 767px
    if (parseInt(width) <= 767) {
        styleFix += "mt-1 ";
    } else {
        styleFix += "ps-0 ";
    }

    return (
        <div className="row mb-1">
            <div className="col-md-6">
                <label htmlFor="user_name" className="form-label">{ t('name') }</label>
                <input {...props} type="text" className="form-control" id="user_name" name="user_name" placeholder="John" aria-describedby="user_name" autoFocus required/>
                <div className="invalid-feedback"> { t('validations.required') } </div>
            </div>
            <div className={ styleFix }>
                <label htmlFor="user_last_name" className="form-label">{ t('last_name') }</label>
                <input {...props} type="text" className="form-control" id="user_last_name" name="user_last_name" placeholder="Doe" aria-describedby="user_last_name" autoFocus required/>
                <div className="invalid-feedback"> { t('validations.required') } </div>
            </div>
        </div>
    )
}