import axios from "axios";
import i18n from 'i18next';
import {Notyf} from "notyf";
import {afterSubmit, beforeSubmit, commonFailure, getHeaders} from "utils/Requests/Axios";
import {getLanguage, setLanguage} from "utils/Language";
import {disableElementRequestCaller, serialize, showRemoteValidateError} from "utils/Forms";
//css
import 'notyf/notyf.min.css';
import _ from "lodash";

export function removeTokensSessions() {
    //remove tokens
    localStorage.removeItem('t1');
    localStorage.removeItem('t2');
}

export function removeSession() {
    //remove tokens
    removeTokensSessions();
    //set un auth user
    window.setIsAuth(0);
}

//get is user is auth
export function isAuthOnLoad() {
    const authToken = localStorage.getItem('t1');
    const refreshToken = localStorage.getItem('t2');
    //if not tokens, not user auth
    if(!authToken || !refreshToken) { return window.setIsAuth(0); }
    //get data to set in request
    const data = {token_refresh: refreshToken};
    //make a request to refresh token to
    //determine if user is logged in
    axios.post(
        `${global.config.api_url_v1}refresh_token`,
        serialize(data),
        getHeaders(),
    ).then(function (response) {
        localStorage.setItem("t1", response.data.user_token);
        //set render logic
        window.renderLogic = _.get(response, 'data.first_render', {})
        window.setIsAuth(1);
    }).catch(function (error) {
        commonFailure(error);
    });
}

export function showNotifyNoTValidatedMail(user) {
    //if exists yet this notification...
    if(window.messageVerificationNotyf) { window.messageVerificationNotyf.dismissAll(); }
    //overwrite with new notification data
    window.messageVerificationNotyf = new Notyf({
        types: [{ type: 'error', className: 'notify-not-validated' }]
    }); //set handler for this notification
    window.resendVerification = function() { sendValidationMail(user); }
    //open the notification
    window.messageVerificationNotyf.open({
        duration: 0,
        position: {x:'center',y:'top'},
        type: 'error',
        message: i18n.t('login.not_verified', {
            'new_line': '<br />',
            'here': `<a onClick='window.resendVerification()'>${ i18n.t("here").toLowerCase() }</a>`
        }),
        dismissible: true
    });
}

export function sendValidationMail(user) {
    //start progress reference
    window.progressReference.current.continuousStart();
    //reset the component
    window.messageVerificationNotyf.dismissAll();
    //remove handlers for link
    window.messageVerificationNotyf = null;
    window.resendVerification = null;

    //success event when request is good
    const successHandler = (response) => {
        //end complete progress bar
        window.progressReference.current.complete();
        //if ok...
        if(response.data.success) {
            global.config.notyf_object.success(i18n.t('login.email_verified_send', {
                'new_line': '<br />'
            }));
        } else {
            global.config.notyf_object.error(i18n.t('request.unknown'));
        }
    }

    //fail event when request fail
    const failHandler = (error) => {
        //end complete progress bar
        window.progressReference.current.complete();
        //for each error status
        commonFailure(error);
    }

    //make a request
    axios.post(
        `${global.config.api_url_v1}validate/email`,
        serialize({ user_id: user.user_id, code: user.code }),
        getHeaders(),
    ).then(function (response) {
        successHandler(response);
    }).catch(function (error) {
        failHandler(error);
    });
}

export function ValidateEmailRequest(uuid, callback) {
    //success event when request is good
    const successHandler = (response) => {
        //end complete progress bar
        window.progressReference.current.complete();
        callback(1);
    }

    //fail event when request fail
    const failHandler = (error) => {
        //end complete progress bar
        window.progressReference.current.complete();
        //check errors
        callback(-1);
    }

    axios.get( `${global.config.api_url_v1}validate/${uuid}`, getHeaders())
        .then(function (response) { successHandler(response); })
        .catch(function (error) { failHandler(error); });
}

export function ChangePasswordRequest(e, formData, uuid, callBack) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //success event when request is god
        const successHandler = (response) => {
            //call after submit
            afterSubmit(e, () => {
                //on success true
                if(response.data.success) {
                    //disable submit button
                    disableElementRequestCaller(e);
                    //show message of success sign up
                    global.config.notyf_object.success(i18n.t('reset_password.success', {'new_line': '<br />'}));
                    //event to redirect
                    setTimeout(callBack, 2000);
                }  else { //other events
                    global.config.notyf_object.error(i18n.t('request.unknown'));
                }
            });
        }

        //fail event when request fail
        const failHandler = (error) => {
            afterSubmit(e, () => {
                if (error.response && error.response.status) {
                    if (error.response.status === 403) {
                        return global.config.notyf_object.error(i18n.t('reset_password.forbidden'));
                    }
                }
                //for each error status
                commonFailure(error);
            });
        }

        //make a request
        axios.post(
            `${global.config.api_url_v1}user_recovery/${uuid}`,
            serialize(formData),
            getHeaders(),
        ).then(function (response) {
            successHandler(response);
        }).catch(function (error) {
            failHandler(error);
        });
    });
}

export function RecoveryRequest(e, formData, callBack) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //success event when request is god
        const successHandler = (response) => {
            //call after submit
            afterSubmit(e, () => {
                //on success true
                if(response.data.success) {
                    //disable submit button
                    disableElementRequestCaller(e);
                    //show message of success sign up
                    global.config.notyf_object.success(i18n.t('forgot.success', {'new_line': '<br />'}));
                    //event to redirect
                    setTimeout(callBack, 2000);
                }  else { //other events
                    global.config.notyf_object.error(i18n.t('request.unknown'));
                }
            });
        }

        //fail event when request fail
        const failHandler = (error) => {
            afterSubmit(e, () => {
                //for each error status
                commonFailure(error);
            });
        }

        //make a request
        axios.post(
            `${global.config.api_url_v1}user_recovery/email`,
            serialize(formData),
            getHeaders(),
        ).then(function (response) {
            successHandler(response);
        }).catch(function (error) {
            failHandler(error);
        });
    });
}
//make a login request to API
export function LoginRequest(e, formData, navigate) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //success event when request is god
        const successHandler = (response) => {
            //call after submit
            afterSubmit(e, () => {
                //on success true
                if(response.data.success) {
                    //error no validated user
                    if(response.data.errno === 1000) {
                        showNotifyNoTValidatedMail(response.data);
                    } else {
                        //disable submit button
                        disableElementRequestCaller(e);
                        //set language from user
                        setLanguage(response.data.user_language);
                        //set welcome message customized
                        global.config.notyf_object.success(i18n.t('login.success', {'name': `${response.data.user_name} ${response.data.user_last_name}`}));
                        //set tokens to session storage
                        localStorage.setItem("t1", response.data.user_token);
                        localStorage.setItem("t2", response.data.user_refresh_token);
                        //set event after login to true
                        window.afterLogin = 1;
                        //set render logic
                        window.renderLogic = _.get(response, 'data.first_render', {})
                        // set user auth
                        setTimeout(function() { window.setIsAuth(1); }, 2000);
                    }
                }  else { //other events
                    global.config.notyf_object.error(i18n.t('request.unknown'));
                }
            });
        }

        //fail event when request fail
        const failHandler = (error) => {
            afterSubmit(e, () => {
                if (error.response && error.response.status){
                    if (error.response.status === 400) {
                        return global.config.notyf_object.error(i18n.t('login.not_found'));
                    }
                } //for each error status
                commonFailure(error);
            });
        }

        //make a request
        axios.post(
            `${global.config.api_url_v1}user_login`,
            serialize(formData),
            getHeaders(),
        ).then(function (response) {
            return successHandler(response);
        }).catch(function (error) {
            return failHandler(error);
        });
    });
}

//make a login request to API
export function SignUpRequest(e, formData, formRef, successCallback = () => {}, errorCallback = () => {}) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //set user_language
        formData.user_language = getLanguage();

        //success event when request is good
        const successHandler = (response) => {
            //call after submit
            afterSubmit(e, () => {
                //if ok signup...
                if(response.data.success) {
                    //disable submit button
                    disableElementRequestCaller(e);
                    //show message of success sign up
                    global.config.notyf_object.success(i18n.t('signup.success', {'new_line': '<br />'}));
                    //event to redirect
                    setTimeout(successCallback, 2000);
                } else if(response.data.errno && response.data.errno === 1062) {
                    //info for dupe element...
                    global.config.notyf_object.error(i18n.t('signup.dupe'));
                    //show error on the field...
                    showRemoteValidateError(formRef, 'duplicated');
                } else {
                    global.config.notyf_object.error(i18n.t('request.unknown'));
                }
            });
        }

        //fail event when request fail
        const failHandler = (error, response) => {
            //call after submit
            afterSubmit(e, () => {
                //for each error status
                commonFailure(error);
                // if has errors from server
                if(error.response && error.response.data && error.response.data.errors) {
                    const err = error.response.data.errors;
                    const errors = {}
                    for(let i = 0; i < err.length; i++) {
                        errors.field = err[i].param
                        errors.text = err[i].msg
                    }
                    errorCallback(errors)
                }
            });
        }

        axios.post( `${global.config.api_url_v1}user_signup`, serialize(formData), getHeaders())
            .then(function (response) { successHandler(response); })
            .catch(function (error) { failHandler(error); });
    });
}

export function LogOutRequest(e) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //success event when request is good
        const successHandler = (response) => {
            afterSubmit(e, () => {
                //disable submit button
                disableElementRequestCaller(e);
                //remove tokens
                removeSession();
            });
        }

        //fail event when request fail
        const failHandler = (error) => {
            //call after submit
            afterSubmit(e, () => {
                //for each error status
                commonFailure(error);
            });
        }

        axios.get( `${global.config.api_url_v1}user_logout`, getHeaders())
            .then(function (response) { successHandler(response); })
            .catch(function (error) { failHandler(error); });
    });
}

export function deleteAccountRequest(data) {
    //success event when request is good
    const successHandler = (response) => {
        if(response.data.success) {
            //when session expires...
            global.config.notyf_object.error(i18n.t('account.delete_account_success'));
            //remove tokens
            removeTokensSessions();
            //event to redirect
            setTimeout(() => { window.setIsAuth(0) }, 2000);
        } else {
            global.config.notyf_object.error(i18n.t('request.unknown'));
        }
    }

    axios.post( `${global.config.api_url_v1}user_delete`, serialize(data), getHeaders())
        .then(function (response) { successHandler(response); })
        .catch(function (error) {
            if (error.response && error.response.status && error.response.status === 403) {
                return global.config.notyf_object.error(i18n.t('account.delete_account_invalid_password'));
            }
            return commonFailure(error); });
}

export function updateAccountRequest(data, successCallBack) {
    //success event when request is good
    const successHandler = (response) => {
        if(response.data.success) {
            //when session expires...
            global.config.notyf_object.success(i18n.t('account.update_account_success'));
            successCallBack();
        } else if(response.data.errno && response.data.errno === 1062) {
            //info for dupe element...
            global.config.notyf_object.error(i18n.t('signup.dupe'));
        } else {
            global.config.notyf_object.error(i18n.t('request.unknown'));
        }
    }

    axios.put( `${global.config.api_url_v1}user_update`, serialize(data), getHeaders())
        .then(function (response) { successHandler(response); })
        .catch(function (error) { return commonFailure(error); });
}

export function  changeUserPassword(data, successCallBack) {
    //success event when request is good
    const successHandler = (response) => {
        if(response.data.success) {
            //when session expires...
            global.config.notyf_object.success(i18n.t('account.update_account_success'));
            successCallBack();
        } else {
            global.config.notyf_object.error(i18n.t('request.unknown'));
        }
    }

    axios.put( `${global.config.api_url_v1}/user_update/password`, serialize(data), getHeaders())
        .then(function (response) { successHandler(response); })
        .catch(function (error) {
            if (error.response && error.response.status && error.response.status === 403) {
                return global.config.notyf_object.error(i18n.t('account.delete_account_invalid_password'));
            }
            return commonFailure(error);
        });
}
