import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import { Alert } from 'reactstrap';
import { useForm } from "react-hook-form";
import { PlantsAdd } from "utils/Requests/Plant";
import { AlertCircle, ThumbsUp } from "react-feather";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;
const defaultMessageConfig = { color: 'info', message: "", icon: "" };

export default function PagePlantsAdd({ ...props }) {
    //hook for translations
    const {t} = useTranslation();
    //for messages after submit
    const [ messageConfig, setMessageConfig ] = useState(defaultMessageConfig);
    //hooks for forms
    const { register, handleSubmit, formState: { errors, isDirty, isSubmitting, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            reclaim: null
        }
    }); //call on submit
    const onSubmit = async (data) => {
        setMessageConfig(defaultMessageConfig);
        await PlantsAdd(data, (text) => {
            setMessageConfig({color: "success", message: text, icon: <ThumbsUp size={15} />});
        }, (text) => {
            setMessageConfig({color: "danger", message: text, icon: <AlertCircle size={15} />});
        });
    }

    return (
        <>
            {
                messageConfig.message &&
                    <Alert className={"mt-1"} color={messageConfig.color} isOpen={true} toggle={() => setMessageConfig(defaultMessageConfig)}>
                        <div className='alert-body d-flex justify-content-start align-items-center'>
                            { messageConfig.icon }
                            <span style={{paddingLeft: "10px"}}>{ t(messageConfig.message) }</span>
                        </div>
                    </Alert>
            }
            <div className={`card ${isMobileOnly ? "card-mbl" : "mt-1"}`}>
                <div className="card-header">
                    <h4 className="card-title">{ t('plant.add') }</h4>
                </div>
                <div className={`card-body ${isMobileOnly && "card-body-mbl pt-20-mbl"}`}>
                    <form className={"from-label-size-14"} onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="mb-1 col-sm-12">
                                <label htmlFor="reclaim" className="form-label form-label">
                                    { t('plant.add_instructions') }
                                </label>
                                <textarea {...register("reclaim", { required: true })} placeholder={t("plant.add_placeholder")} className="form-control" style={{minHeight: "100px"}}/>
                                {/* errors will return when field validation fails  */}
                                {errors.reclaim && <div className="invalid-feedback-always">{ t("validations.required") }</div>}
                            </div>
                            <div className="col-sm-12">
                                <div className="d-flex">
                                    <button type="submit" disabled={!isDirty || !isValid || isSubmitting} className="me-1 btn btn-primary">{ t("add") }</button>
                                    <button type="reset" className="btn btn-outline-secondary">{ t("reset") }</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}