//energy scale
import i18next from "i18next";
import moment from "moment";
import _ from "lodash";

const wh = 0;
const kwh = 1000;
const mwh = kwh * 1000;
const gwh = mwh * 1000;
const twh = gwh * 1000;
const pwh = twh * 1000;
const ewh = pwh * 1000;
const zwh = ewh * 1000;
const ywh = zwh * 1000;

const getOffset = (val) => {
    return (val * 10) - 1
}

const getIntValue = (val, units) => {
    return `${parseInt(val)}${units}`
}
//return a form
// 4 significative values
export function getEnergyFormat(energy) {
    energy = parseFloat(energy??0);

    if(energy >= wh && energy <= getOffset(kwh) ) {
        return getIntValue(energy, 'Wh')
    } else if(energy >= kwh && energy <= getOffset(mwh)) { //kWh
        return getIntValue(energy/kwh, 'kWh')
    } else if(energy >= mwh && energy <= getOffset(gwh)) {
        return getIntValue(energy/mwh, 'MWh')
    } else if(energy >= gwh && energy <= getOffset(twh)) {
        return getIntValue(energy/gwh, 'GWh')
    } else if(energy >= twh && energy <= getOffset(pwh)) {
        return getIntValue(energy/twh, 'TWh')
    } else if(energy >= pwh && energy <= getOffset(ewh)) {
        return getIntValue(energy/pwh, 'PWh')
    } else if(energy >= ewh && energy <= getOffset(zwh)) {
        return getIntValue(energy/ewh, 'EWh')
    } else if(energy >= zwh && energy <= getOffset(ywh)) {
        return getIntValue(energy/zwh, 'ZWh')
    } else if(energy >= ywh) {
        return getIntValue(energy/ywh, 'YWh')
    }
}

//water scale
const l = 0;
const dal = 1000;
const hl = dal * 1000;
const kl = hl * 1000;
const ml = kl * 1000;
const gl = ml * 1000;
const tl = gl * 1000;
const pl = tl * 1000;
const el = pl * 1000;
const zl = el * 1000;
const yl = zl * 1000;

export function getWaterFormat(water) {
    water = parseFloat(water??0);

    if(water >= l && water < dal ) {
        return water.toFixed(2) + 'L';
    } else if(water >= dal && water < hl) { //kWh
        return (water/dal).toFixed(2) + 'daL';
    } else if(water >= hl && water < kl) {
        return (water/hl).toFixed(2) + 'hL';
    } else if(water >= kl && water < ml) {
        return (water/kl).toFixed(2) + 'kL';
    } else if(water >= ml && water < gl) {
        return (water/ml).toFixed(2) + 'ML';
    } else if(water >= gl && water < tl) {
        return (water/gl).toFixed(2) + 'GL';
    } else if(water >= tl && water < pl) {
        return (water/tl).toFixed(2) + 'TL';
    } else if(water >= pl && water < el) {
        return (water/pl).toFixed(2) + 'PL';
    } else if(water >= el && water < zl) {
        return (water/el).toFixed(2) + 'EL';
    } else if(water >= zl && water < yl) {
        return (water/zl).toFixed(2) + 'ZL';
    } else if(water >= yl) {
        return (water/yl).toFixed(2) + 'YL';
    }
}

//return a form
export function getWeightCO2Format(energy) {
    energy = parseFloat(energy??0);

    if(energy >= 0 && energy < 1000 ) {
        return energy.toFixed(2) + 'KgCO₂';
    } else if(energy >= 1000) { //kWh
        return (energy/1000).toFixed(2) + 'tCO₂';
    }
}

//general scale
const d = 0;
const k = 1000;
const m = k * 1000;
const g = m * 1000;
const t = g * 1000;
const p = t * 1000;
const e = p * 1000;
const z = e * 1000;
const y = z * 1000;

export function getNormalizedFormat(number, fixed = 2) {
    number = parseFloat(number??0);

    if(number >= d && number < k ) {
        return number.toFixed(fixed);
    } else if(number >= k && number < m) {
        return (number/k).toFixed(fixed) + 'k';
    } else if(number >= m && number < g) {
        return (number/m).toFixed(fixed) + 'M';
    } else if(number >= g && number < t) {
        return (number/g).toFixed(fixed) + 'G';
    } else if(number >= t && number < p) {
        return (number/t).toFixed(fixed) + 'T';
    } else if(number >= p && number < e) {
        return (number/p).toFixed(fixed) + 'P';
    } else if(number >= e && number < z) {
        return (number/e).toFixed(fixed) + 'E';
    } else if(number >= z && number < y) {
        return (number/z).toFixed(fixed) + 'Z';
    } else if(number >= y) {
        return (number/y).toFixed(fixed) + 'Y';
    }
}

export function getSolarPhotovoltaicEnergyNormalizedArrayForPlot(numbers) {
    for(let j = 0; j < numbers.length; j++) {
        if(numbers[j] === null) { continue; }
        numbers[j] = (numbers[j] / 1000).toFixed(2);
    }
    return numbers;
}

export function getNormalizedArrayForPlot(numbers) {
    for(let j = 0; j < numbers.length; j++) {
        if(numbers[j] === null) { continue; }
        numbers[j] = numbers[j].toFixed(2);
    }
    return numbers;
}

export function getMoneyUnit(string = false) {
    if(string) {
        return 'Euros (€)';
    }
    return '€';
}

export function getEcoSavings(numbers) {
    return getNormalizedFormat(numbers) + getMoneyUnit();
}

export function apexChartLabel(labels, each = 4, increment = 0) {
    let simplified = [];
    for(let j = 0; j < labels.length; j++) {
        /*if(j === 0) {
            simplified[j] = labels[j];
        } else */if((j+increment) % each === 0) {
            simplified[j] = labels[j];
        } else {
            simplified[j] = ' ';
        }
    }
    return simplified;
}

export function hoursTooltipFormatter(val, opts) {
    return `${moment({ hour: (val - 1)}).format('HH:mm')}`;
}

export function hoursGeneralTooltipFormatter(val, opts) {
    return `${moment({ hour: (val - 1)}).format('HH:mm')}, ${this.initialConfig.xaxis.tooltip.prefix}`;
}

export function dayTooltipFormatter(val, opts) {
    return `${i18next.t('time.day')} ${val}`;
}

export function dayGeneralTooltipFormatter(val, opts) {
    return `${i18next.t('time.day')} ${val}, ${this.initialConfig.xaxis.tooltip.prefix}`;
}

export function yearTooltipFormatter(val, opts) {
    return `${this.categoryLabels[val - 1]}`;
}

export function yearGeneralTooltipFormatter(val, opts) {
    return `${_.capitalize(opts.w.config.chart.originalLabels[val-1])}, ${this.initialConfig.xaxis.tooltip.prefix}`;
}