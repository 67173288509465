import React, {useState} from "react";
import { Eye, EyeOff } from 'react-feather';
import {useTranslation} from "react-i18next";
import HelpBlockInput from "components/Forms/HelpBlockInput";

export default function CodeInput(props) {
    const { t } = useTranslation();
    const [codeShown, setCodeShown] = useState(false);

    //toggle to show or not show a password
    const toggleCode = (e) => { setCodeShown(!codeShown); }

    return (
        <div className="mb-1">
            <div className="d-flex justify-content-between">
                <label className="form-label" htmlFor="admin_code">{ t('code') }</label>
            </div>
            <div className="input-group input-group-merge form-password-toggle">
                <input { ...props } type={ codeShown ? "text" : "password" } style={{  }} className="form-control form-control-merge" id="admin_code" name="admin_code" minLength="8" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="admin_code" required/>
                <span className="input-group-text cursor-pointer" style={{ borderLeft: "2px solid #D8D6DE", borderRadius: "0 0.357rem 0.357rem 0", backgroundColor: "rgb(235 233 233 / 10%)" }} onClick={toggleCode}>
                    { codeShown ? <EyeOff /> : <Eye /> }
                </span>
                <div className="invalid-feedback"> { t('validations.admin_code') } </div>
                { (props.errors && props.errors.field === "admin_code") && <div className="invalid-feedback" style={{'display': 'unset'}}> { props.errors.text } </div>  }
            </div>
            <div><HelpBlockInput text={t('signup.admin_code_helper')} /></div>
        </div>
    )
}
