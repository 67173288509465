import {Notyf} from "notyf";
import Cookies from "js-cookie";
import { getLanguage, getCookiesMessage} from "utils/Language";

const showCookieToast = () => {
    //get current language
    const language = getLanguage();
    //open new notification
    new Notyf({
        types: [{
            type: 'info',
            background: '#007bff',
            icon: false,
            className: 'notify-cookies'
        }]
    }).open({
        duration: 0,
        position: {x:'center',y:'bottom'},
        type: 'info',
        message: getCookiesMessage(language),
        dismissible: true
    }).on('dismiss', ({target, event}) => {
        //set language too cookie
        Cookies.set('accept_cookies', 1, { expires: 365 });
    });
}

export const cookiesBanner = () => {
    if(!Cookies.get('accept_cookies')) {
        showCookieToast();
    }
}