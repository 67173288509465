import Loader from "react-loader-spinner";
import {useTranslation} from "react-i18next";

export function DataTableLoader({...props}) {
    return (
        <span className={"mb-1 pt-1 d-flex justify-content-center"} style={{width: "100%"}}>
            <Loader type="Bars" color="#00BFFF" height={80} width={80} />
        </span>
    )
}

export function DataTableError({...props}) {
    return (
        <span className={"mb-1 pt-1 d-flex justify-content-center"} style={{width: "100%"}}>
            Error...
        </span>
    )
}

export function DataTableNoRecords({...props}) {
    const {t} = useTranslation();
    return (
        <span className={"mb-1 pt-1 d-flex justify-content-center"} style={{width: "100%"}}>
            { t("table.no_records") }
        </span>
    )
}