import {useTranslation} from "react-i18next";
import Select from 'react-select';
import {Controller} from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import React from "react";

const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
});

const selectStyle = {
    control: (base) => {
        return {
            ...base,
            borderColor: '#d8d6de'
        };
    }
};

export default function SettingsComboInput({ ...props }) {
    const { t } = useTranslation();

    //get use hook form methods
    const { control, setValue, formState: { errors } } = props.methods;
    //set a validation object
    const validation = {
        required: "validations.required"
    };

    return (
        <>
            <label htmlFor={props.name} className="form-label form-label">{ props.label }</label>
            { props.isLoading && <div><Skeleton style={{lineHeight: "39px"}} /></div> }
            { !props.isLoading && <Controller
                control={control}
                name={props.name}
                rules={(props.validation)?{ ...props.validation, ...validation }:validation}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                        name={props.name}
                        inputRef={ref}
                        styles={selectStyle}
                        theme={selectThemeColors}
                        className={`react-select ${(errors[props.name]?.message)?"is-invalid":""}`}
                        classNamePrefix='select'
                        options={props.options}
                        value={props.options.find(c => c.value === value)}
                        onChange={val => {
                                setValue(props.name, val.value, { shouldValidate: true, shouldDirty: true });
                            }
                        }/>
                )}
            /> }
            { /* <Select name="color" options={options} className={`${(errors[props.name]?.message)?"is-invalid":""}`} /> */ }
            {  errors[props.name]?.message && <div className="invalid-feedback-always">{t(errors[props.name]?.message)}</div> }
        </>
    )
}