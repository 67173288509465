import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import React, {useState} from "react";
import {Home, Feather, Sun, Droplet, Zap, Thermometer, Plus, Settings} from "react-feather";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import NavbarButtonUser from "../Navbar/Buttons/UserButtonNavbar";
import BrandSimple from "../Titles/BrandSimple";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { CgMenuLeftAlt } from "react-icons/cg";
import {Space} from "antd";
import config from "/app/src/config.js";
import {_SOLAR_PHOTOVOLTAIC, _SOLAR_THERMAL, _THERMAL, _WATER, getIconComponentByCaller} from "../../utils/Types";
import { TiChevronLeft } from "react-icons/ti";

const isMobileOnly = config.mbl_version;

/* Define some buttons */
const HomeButton = function({...props}) {
    const {t} = useTranslation();

    return (
        <Link to="/dashboard">
            <div style={{display: "flex", justifyContent: "space-between", color: "#6e6b7b"}}>
                <Home className="me-50" size={20} />{t('dashboard')}
            </div>
        </Link>
    )
}

const AddButton = function({...props}) {
    const {t} = useTranslation();

    return (<Link to="/dashboard/plants/add">
        <div style={{display: "flex", justifyContent: "space-between", color: "#6e6b7b"}}>
            <Plus className="me-50" size={20}/>{t('add')}
        </div>
    </Link>
)
}

/* dropdown information */
const DropdownItemLink = function ({...props}) {
    return (
        <Link to={props.path} className={`d-flex align-items-center`}>
            <span className="me-50">{props.icon}</span><span>{props.name}</span>
        </Link>
    )
}

/* Define dropdowns items */
const DropdownItemPlants =  function ({...props}) {
    const {t} = useTranslation();

    return (<>
        <DropdownItem>
            <DropdownItemLink path="/dashboard/plants/thermal" icon={<Sun size={18}/>} name={t('thermal')}/>
        </DropdownItem>
        <DropdownItem>
            <DropdownItemLink path="/dashboard/plants/photovoltaic" icon={<Zap size={18}/>} name={t('photovoltaic')}/>
        </DropdownItem>
        <DropdownItem>
            <DropdownItemLink path="/dashboard/plants/temperature" icon={<Thermometer size={18}/>} name={t('temperature')}/>
        </DropdownItem>
        <DropdownItem>
            <DropdownItemLink path="/dashboard/plants/water" icon={<Droplet size={18}/>} name={t('water')}/>
        </DropdownItem>
        {/*<DropdownItem disabled>*/}
        {/*    <DropdownItemLink className={"disabled"} path="/dashboard" icon={<FontAwesomeIcon icon={faWater}/>} name={t('gray_water')}/>*/}
        {/*</DropdownItem>*/}
    </>)
}

/* Define dropdowns */
const DropdownPlantsButton = function({...props}) {
    const {t} = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown className={'rd-mobile-dropdown rd-dropdown'} isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
            <DropdownToggle className={'rd-dropdown-toggle-white'} caret>
                <span className="me-50"><Feather size={20}/></span>
                <span style={{color: "#6e6b7b", fontWeight: 'normal'}}>{t('plants')}</span>
            </DropdownToggle>
            <DropdownMenu {...props} persist={false}>
            <DropdownItemPlants />
            </DropdownMenu>
        </Dropdown>
    )
}

const MobileHamburgerMenu = function({...props}) {
    const {t} = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown className={'rd-mobile-dropdown rd-dropdown'} isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
            <DropdownToggle className={`rd-dropdown-toggle-white ${props.path !== 'dash' ? 'rd-dropdown-toggle-pl-0' : ''}`} caret>
                <CgMenuLeftAlt size={20} />
            </DropdownToggle>
            <DropdownMenu persist={false}>
                <DropdownItem>
                    <DropdownItemLink path="/dashboard" icon={<Home size={18}/>} name={t('dashboard')}/>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItemPlants />
                <DropdownItem divider />
                <DropdownItem>
                    <DropdownItemLink path="/dashboard/plants/add" icon={<Plus size={18}/>} name={t('add')}/>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

const HeaderBy = ({...props}) => {
    const {t} = useTranslation();
    const {caller} = props;

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column-reverse'}}>
        <div>{
            (() => {
                if(caller === 'settings') {
                    return t('settings')
                } else if(caller === 'add') {
                    return t('add')
                }
            })()
        }</div>
        <div style={{paddingBottom: "2px"}}>
            {
                (() => {
                    if(caller === 'settings') {
                        return <Settings size={20}/>
                    } else if(caller === 'add') {
                        return <Plus size={20}/>
                    }
                })()
            }
        </div>
    </div>
}

const HeaderByPlantType = ({...props}) => {
    const {t} = useTranslation();
    const {caller} = props;

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column-reverse'}}>
        <div>{
            (() => {
                if(caller === _SOLAR_THERMAL) {
                    return t('thermal')
                } else if(caller === _SOLAR_PHOTOVOLTAIC) {
                    return t('photovoltaic')
                } else if(caller === _THERMAL) {
                    return t('temperature')
                } else if(caller === _WATER) {
                    return t('water')
                }
            })()
        }</div>
        <div style={{paddingBottom: "2px"}}>
            {getIconComponentByCaller(props, {size: 20})}
        </div>
    </div>
}

const PreviousRouteLink = ({...props}) => {
    // set default to
    let to = '';
    // pats go to dashboard previous
    const toDashboard = [
        'plants/add', 'user', 'plants/thermal', 'plants/photovoltaic',
        'plants/temperature', 'plants/water',
    ]
    // set tos
    if(toDashboard.includes(props.path)) {
        to = '/dashboard'
    } else if(props.path.includes('/:id')) {
        // concrete plants
        to = `/dashboard/${props.path.replace('/:id','')}`;
    }

    return (<>
        { props.path === 'dash'
            ? <></>
            : <Link to={to} style={ (props.path !== 'dash' && to) ? {} : {pointerEvents: "none"} }>
                <div style={{color: (props.path !== 'dash' && to) ? "#6e6b7b" : "rgb(189 189 189)"}}>
                    &nbsp;&nbsp;<TiChevronLeft size={20} />&nbsp;
                </div>
            </Link>
        }
    </>)
}

export default function DashboardMenu({...props}) {

    return (<>
            <div className={`navbar-fixed-2 ${isMobileOnly && 'navbar-fixed-2-mbl'}`}>
                <div
                    className={`dropdown-on-hover header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border ${isMobileOnly && 'menu-border-mbl'}`} role="navigation" data-menu="menu-wrapper" data-menu-type="floating-nav">
                    {/* nav menu content */}
                    {isMobileOnly? <> {/* mobile view */}
                        {/* left section: hamburger menu */}
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '5px'}}>
                            <PreviousRouteLink {...props} />
                            <MobileHamburgerMenu {...props} />
                        </div>
                        {/* center section: dynamic */}
                        {(() => {
                            return (
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    { /* dash main page */}
                                    {props.path === 'dash' && <BrandSimple to="/dashboard"/>}
                                    {/*{ /* user path *!/*/}
                                    {props.path === 'user' && <HeaderBy caller={'settings'} />}
                                    { /* add plant */}
                                    {props.path === 'plants/add' && <HeaderBy caller={'add'} />}
                                    { /* thermal - _SOLAR_THERMAL */}
                                    {/*{props.path === 'plants/thermal' && <>{t('thermal')}</>}*/}
                                    {/*{props.path === 'plants/thermal/:id' && <>{t('thermal')}</>}*/}
                                    {props.path === 'plants/thermal' && <HeaderByPlantType caller={_SOLAR_THERMAL}/>}
                                    {props.path === 'plants/thermal/:id' && <HeaderByPlantType caller={_SOLAR_THERMAL}/>}
                                     { /* photovoltaic - _SOLAR_PHOTOVOLTAIC */}
                                    {props.path === 'plants/photovoltaic' && <HeaderByPlantType caller={_SOLAR_PHOTOVOLTAIC}/>}
                                    {props.path === 'plants/photovoltaic/:id' && <HeaderByPlantType caller={_SOLAR_PHOTOVOLTAIC}/>}
                                    { /* temperature - _THERMAL */}
                                    {props.path === 'plants/temperature' && <HeaderByPlantType caller={_THERMAL}/>}
                                    {props.path === 'plants/temperature/:id' && <HeaderByPlantType caller={_THERMAL}/>}
                                    { /* temperature - _WATER */}
                                    {props.path === 'plants/water' && <HeaderByPlantType caller={_WATER}/>}
                                    {props.path === 'plants/water/:id' && <HeaderByPlantType caller={_WATER}/>}
                                </div>
                            )
                        })()}
                        {/* right section: user button */}
                        <div style={{flex: 1,display: 'flex',justifyContent: 'flex-end', alignItems: 'center', paddingRight: '5px'}}>
                            <NavbarButtonUser {...props} />
                        </div>
                    </>:<> {/* other devices views */}
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '20px'}}>
                            <Space size={'small'}>
                                <HomeButton />
                                <DropdownPlantsButton />
                            </Space>
                        </div>
                        {/* right section: add button */}
                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '20px'}}>
                            <AddButton />
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}


