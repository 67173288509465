import {AvatarSuchAsIcon} from "components/Icon/AvatarSuchAsIcon";
import React, {useState} from "react";
import { Tooltip } from 'antd';
import _uniqueId from 'lodash/uniqueId';

export function CardDotWithDataInline({...props}) {
    return (
        <>
            <div className="d-flex flex-row">
                <div className={`avatar ${props.colorClass} me-2`}>
                    <AvatarSuchAsIcon icon={props.icon}/>
                </div>
                <div className="my-auto">
                    <h4 className="fw-bolder mb-0">{ props.number }</h4>
                    <p className="card-text font-small-3 mb-0">{props.text}</p>
                </div>
            </div>
        </>
    )
}

export function CardDotWithDataVertical({...props}) {
    return (
        <>
            <div className={"d-flex justify-content-center"} style={{paddingTop: '1rem'}}>
                <div className={`avatar ${props.colorClass} mb-1`}>
                    <AvatarSuchAsIcon {...props} icon={props.icon}/>
                </div>
            </div>
            <div className={"d-flex justify-content-center"}>
                <h4 className="fw-bolder mb-0">{ props.number }</h4>
            </div>
            <div className={"d-flex justify-content-center"}>
                <p className="card-text font-small-1 mb-0">{props.text}</p>
            </div>
        </>
    )
}

export function CardDotWithDataInlineNoSub({...props}) {
    const [elementId] = useState(_uniqueId('event-'));

    return (
        <>
            <Tooltip placement="topLeft" title={props.text} overlayStyle={{fontSize: '13px'}}>
                <div className="d-flex flex-row mt-1">
                    <div id={elementId} className={`avatar ${props.colorClass} me-1`}>
                        <AvatarSuchAsIcon icon={props.icon}/>
                    </div>
                    <div className="my-auto">
                        <h4 className="fw-bolder" style={{marginBottom: 0}}>{ props.number }</h4>
                    </div>
                </div>
            </Tooltip>
        </>
    )
}