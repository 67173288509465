//libraries
import Brand from "components/Authentication/Brand";
import React, {useState} from "react";
import {getValue} from "utils/Forms";
import { useTranslation } from 'react-i18next';
import EmailInput from "components/Forms/Inputs/EmailInput";
import PasswordInput from "components/Forms/Inputs/PasswordInput";
// import LanguagesFooter from "components/Langauge";
import DividerEdit from "components/Forms/DividerEdit";
import {LoginRequest} from "utils/Requests/Auth"
import FooterLink from "components/Forms/Auth/FooterLink";

export default function Login({ ...props }) {
    //translation hook
    const { t } = useTranslation();
    //form data hook
    const [formData, setFormData] = useState({ user_email: null, user_password: null});
    //handler for each input change
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name] : getValue(event) })
    } //handler for submit a form
    const handleSubmit = (e) => { LoginRequest(e, formData); };

    return (
        <>
        <div className="card-body">
            <Brand />
            <h4 className="card-title mb-0 mt-2">{ t('login.title') } 👋</h4>
            <p className="card-text mb-2"> { t('login.description') }</p>
            <DividerEdit />
            { /* Form */ }
            <form className="auth-login-form mt-2">
                <EmailInput onChange={handleInputChange} tabIndex="1"/>
                <PasswordInput onChange={handleInputChange} tabIndex="2" login="true" />
                <button className="btn btn-primary w-100 btn-submit" onClick={handleSubmit} tabIndex="3">
                    { t('login.sign_in') }
                </button>
            </form>
            <FooterLink text={ [t('login.new_on'), t('login.create_account')] } to="/signup" />
            {/*<LanguagesFooter />*/}
        </div>
        </>
    )
}
