import React from "react";
import {Link} from "react-router-dom";
import useWindowDimensions from "utils/Dimensions";

export default function FooterLink({...props}) {
    //get windows dimensions
    const { width } = useWindowDimensions();

    return (
        <>
            <p className="text-center mt-2">
                <span>{ props.text[0] }&nbsp;</span>
                { (width <= 480) && <br />}
                <Link to={props.to}> <span>{ props.text[1] }</span></Link>
            </p>
        </>
    )
}