import React from "react";
import { DatePicker } from 'antd';
import {getIntervalByTextLink, getTimeLocal, getDateStringFormat} from "utils/Time";
import {useTranslation} from "react-i18next";

const { RangePicker } = DatePicker;

export default function RangePickerCustom({...props}) {
    //translations
    const {t} = useTranslation();
    //pre ranges to select
    const ranges = {};
    //for each preselect option...
    ['time.yesterday', 'time.last_month', 'time.last_year'].forEach((text) => {
        ranges[t(text)] = getIntervalByTextLink(text);
    });

    return (
        <>
            <RangePicker defaultValue={props.defaultValue} locale={getTimeLocal()} ranges={ranges} bordered={false}
                         format={getDateStringFormat()} onChange={props.onChange} />
        </>
    )
}