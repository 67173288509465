//compatibility
//error process
//npm install --save --save-exact -D react-error-overlay@6.0.9
//-- ES6 Promise polyfill in order to work
//libraries
// https://reactrouter.com/docs/en/v6/getting-started/overview
// https://dev.to/amarjits/basic-guide-to-use-react-router-v6-35do
// https://www.npmjs.com/package/react-loading-icons
// https://www.npmjs.com/package/react-loading-skeleton
// -- ICONS
// https://react-icons.github.io/react-icons
// https://fontawesome.com/v5.15
// https://feathericons.com/
// https://ant.design/components/icon/
// - SENTENCE CASES
// https://medium.com/@robertsavian/javascript-case-converters-using-lodash-4f2f964091cc
import React, {useRef, useState} from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";
import Previous from "utils/Pages/Previous";
import ProtectedRoute from "components/Router/ProtectedRoute";
import PageAuthentication from "view/Authentication/PageAuthentication";
import PageMessages from "view/PageMessages";
import PageTerms from "view/PageTerms";
import PageCookies from "view/PageCookies";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useTranslation} from "react-i18next";
// import {cookiesBanner} from "utils/CookiesBanner.js"
import {isAuthOnLoad} from "utils/Requests/Auth";
import LoadingBar from "react-top-loading-bar";
import PageDashboard from "./view/PageDashboard";
import removeFbclid from "remove-fbclid";
import 'moment/min/locales';
import 'antd/dist/antd.css';
import 'assets/css/bootstrap.css';
import 'assets/css/bootstrap-extended.css';
import 'assets/css/colors.css';
import 'assets/css/components.css';
import 'assets/css/themes/dark-layout.min.css';
import 'assets/css/themes/bordered-layout.min.css';
import 'assets/css/themes/semi-dark-layout.min.css';
import 'assets/css/core/menu/menu-types/horizontal-menu.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'app.scss';

// COMPONENT TO SET
// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/demo-2/components/badges
// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/demo-2/components/breadcrumbs
// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/demo-2/extensions/pagination
// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/demo-2/extensions/export
// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/demo-2/extensions/export-selected
//TODO HEADER SE BE AL SCROLL
//TODO REVISAR RESPONSIVE DE LAS CARDS DEL MAIN

// remove fbclib param
// https://www.npmjs.com/package/remove-fbclid
removeFbclid();
//set progress bar color
window.progressBarColor = '#b9b9b9';
//general interval time (45min)
window.analysisTimerRefresh = 1800000;

//main app
export default function App() {
    const {t} = useTranslation();
    //save progress bar reference on window
    window.progressReference = useRef(null);
    // use hook for aut state
    // -1 not checked :: 0 not auth :: 1 auth
    const [isAuth, setIsAuth] = useState(-1);
    //save hook on window
    window.setIsAuth = setIsAuth;
    window.session_expired = false;
    //return a main app
    return (
        <HelmetProvider>
            <Helmet>
                <title>{t('meta.title')}</title>
                <meta name="description" content={t('meta.description')}/>
            </Helmet>
            <LoadingBar color={window.progressBarColor} ref={window.progressReference} />
            { /* if user is not checked is auth on load page */ }
            { isAuth < 0 && <PageMessages element="loader" /> }
            { /* if user is checked */ }
            { isAuth > -1 &&
                <BrowserRouter>
                    <ScrollToTop />
                    <Previous />
                    <Routes>
                        { /* auth routes :: only if user is not auth */ }
                        <Route exact path='/login' element={ <ProtectedRoute caller="login" isAuth={isAuth} /> } />
                        {/* mysqldump --opt --no-create-info --no-create-db --extended-insert=false --complete-insert -u[USER] -p[PASS] -h [HOST] [DATABASE] [TABLE] --where='user_id>22'*/ }
                        <Route exact path='/signup' element={ <ProtectedRoute caller="signup" isAuth={isAuth} /> } />
                        { /* auth routes :: show all time */ }
                        <Route path="/forgot" element={<PageAuthentication path="forgot"/>}/>
                        <Route path="/forgot/:uuid" element={<PageAuthentication path="forgot"/>}/>
                        { /* legal routes */ }
                        <Route path="/terms" element={<PageTerms path="tos"/>}/>
                        <Route path="/cookies" element={<PageCookies path="cookies"/>}/>
                        <Route path="/validate/:uuid" element={<PageMessages element="validate"/>}/>
                        { /* dashboard -> protected routes */ }
                        <Route exact path='/' element={ <ProtectedRoute caller="root" isAuth={isAuth} /> }>
                            { /* if auth and root directory, redirect to dashboard */ }
                            <Route path='/' element={<Navigate to="/dashboard" />}/>
                            { /* nested route on dashboard */}
                            <Route path="dashboard">
                                { /* dashboard */}
                                <Route path="" element={<PageDashboard path="dash" />} />
                                { /* user */}
                                <Route path="user" element={<PageDashboard path="user" />} />
                                { /* plants */}
                                <Route path="plants" element={<PageDashboard path="plants" />} />
                                { /* add new plants */ }
                                <Route path="plants/add" element={<PageDashboard path="plants/add" />} />
                                { /* thermal plants */ }
                                <Route path="plants/thermal" element={<PageDashboard path="plants/thermal" />} />
                                <Route path="plants/thermal/:id-:name" element={<PageDashboard path="plants/thermal/:id" />} />
                                { /* photovoltaic plants */ }
                                <Route path="plants/photovoltaic" element={<PageDashboard path="plants/photovoltaic" />} />
                                <Route path="plants/photovoltaic/:id-:name" element={<PageDashboard path="plants/photovoltaic/:id" />} />
                                { /* temperature plants */ }
                                <Route path="plants/temperature" element={<PageDashboard path="plants/temperature" />} />
                                <Route path="plants/temperature/:id-:name" element={<PageDashboard path="plants/temperature/:id" />} />
                                { /* water plants */ }
                                <Route path="plants/water" element={<PageDashboard path="plants/water" />} />
                                <Route path="plants/water/:id-:name" element={<PageDashboard path="plants/water/:id" />} />
                            </Route>
                        </Route>

                        { /* not found routes */ }
                        <Route path="*" element={<PageMessages element="404"/>}/>
                    </Routes>
                </BrowserRouter>
            }
            { /* if user is not checked yet if is auth in load page */ }
            { isAuth < 0 && isAuthOnLoad() }
            { process.env.NODE_ENV === "development" && <div className="devel-banner">Development</div>}
        </HelmetProvider>
    );
}
