import React from "react";

export function CardImage({...props}) {
    return (
        <div className="card card-statistics lg-show" style={{border: "0", backgroundColor: "transparent", overflow: "hidden"}}>
            <div className="card-body" style={{padding: 0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img alt={""} {...props} />
            </div>
        </div>
    );
}