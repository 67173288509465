import React, {useEffect, useState} from "react";
import {getEventsPlotData} from "../../utils/Requests/Analysis";
import {
    isSolarPhotovoltaicByCaller,
    isSolarThermalByCaller, isThermalByCaller,
    isWaterByCaller
} from "../../utils/Types";
import {AlertTriangle, ThumbsUp} from "react-feather";
import {Badge, Space} from "antd";
import {AiOutlineAlert, AiOutlineSignature} from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {globalStatusOptions} from "../Forms/Inputs/EventsInput";

export const getStatusSelected = (props, location) => {
    const { status } = location.state || -1
    const selectedStatus = _.find(globalStatusOptions, { 'id': status })
    const badgeSelectedId = _.get(selectedStatus, 'id', -1)
    const badgeSelectedStyle = _.get(selectedStatus, 'selectedStyle', {})
    // get field filter
    let statusNameField = (isSolarThermalByCaller(props) || isSolarPhotovoltaicByCaller(props))?'plant_daily_alert_id':'plant_hourly_alert_id'
    // set field to filter
    if(badgeSelectedId === 'offer_pending_approval'){ statusNameField = 'plant_manual_status' }
    // return selected status with some data
    return {...selectedStatus, badgeSelectedId, badgeSelectedStyle, field: statusNameField}
}

//when request is success
const successRequest = (configuration, setConfiguration, response) => {
    // get alerts -> water and thermal
    let data = _.get(response, 'alerts', {});
    // get status -> photovoltaic and solar thermal
    if(isSolarThermalByCaller(configuration) || isSolarPhotovoltaicByCaller(configuration)) {
        data = _.get(response, 'status', {});
    }
    const summary = { // set data for component
        offer_pending_approval: _.get(data, 'offer_pending_approval', 0),
        ok: _.get(data, 'ok', 0),
        warning: _.get(data, 'overproduction', 0) +
            _.get(data, 'underproduction', 0) +
            _.get(data, 'unsync', 0) +
            _.get(data, 'waiting', 0) +
            _.get(data, 'off', 0),
        fail: _.get(data, 'no_reading', 0),
    }

    setConfiguration({...configuration, text: configuration.text, url: configuration.url, isLoading: false, isError: false, data: summary })
}
//when request fails
const failRequest = (configuration, setConfiguration, response) => {
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, isLoading: false, isError: true, data: {}})
}

const RouteLink = (props) => {
    const id = _.find(globalStatusOptions, { 'id': props.type }).id;
    const url = (() => {
        if (isSolarThermalByCaller(props)) {
            return `/dashboard/plants/thermal`
        } else if (isSolarPhotovoltaicByCaller(props)) {
            return `/dashboard/plants/photovoltaic`
        } else if (isWaterByCaller(props)) {
            return `/dashboard/plants/water`
        } else if (isThermalByCaller(props)) {
            return `/dashboard/plants/temperature`
        }
    })();

    return (<>
        <Link onClick={(e) => {
            e.preventDefault();
            props.handlerOnClick(url, { status: id }, props)
        }} to={url} state={{ status: id }}>
            {props.children}
        </Link>
    </>)
}

const BadgeLink = ({...props}) => {
    const wrapperIconStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '0.358rem',
        ...props.badgeSelectedStyle
    }

    return (
        <RouteLink handlerOnClick={props.handlerOnClick} {...props} type={props.type}>
            <Badge className={props.className} count={props.count} overflowCount={100} color={props.color}>
                <div className={props.className} style={wrapperIconStyle}>
                    {props.icon}
                </div>
            </Badge>
        </RouteLink>
    )
}

export function BadgeGlobalStatusSummary({...props}) {
    const location = useLocation()
    const navigate = useNavigate();
    const { badgeSelectedId, badgeSelectedStyle } = getStatusSelected(props, location)

    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.today',
        url: 'time.today',
        timeLine: {text: 'time.today'},
        isLoading: true,
        isError: false,
        data: {},
        clickCount: { fail: 0, ok: 0, warning: 0 }
    });

    //event to first load plot...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if (!configuration.isLoading) { return }
        //set energy request
        const setData = (configuration, callback = () => {}) => {
            getEventsPlotData({...configuration, plantId: configuration.plantId, interval: configuration.url}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                successRequest(configuration, setConfiguration, response, callback);
            }, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                failRequest(configuration, setConfiguration, response);
            });
        } //call to load data
        setData(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    // on click badge
    const handlerOnClick = (url, state, props) => {
        // control when click same badge or other...
        const typeStatusLink = _.get(props, 'type', -1);
        // if already selected...
        if(typeStatusLink === badgeSelectedId) {
            navigate(url, { replace: false });
        } else { // apply filter
            navigate(url, {replace: false, state });
        }
    }

    return (<>
        { configuration.isLoading && !configuration.isError && <Skeleton style={{width: '150px'}} /> }
        { configuration.isError && <span className={"mt-1 ms-1"}>Error...</span>}
        { !configuration.isLoading && !configuration.isError && <Space size={'middle'}>
            <BadgeLink {...props} handlerOnClick={handlerOnClick} type={'ok'} className={'bg-light-success'} count={_.get(configuration, 'data.ok', 0)} overflowCount={100} color="#28c76f"
                       icon={<ThumbsUp style={{width: '28px', height: '28px', strokeWidth: '2px'}}/>}
                       badgeSelectedStyle={badgeSelectedId === 'ok' ? badgeSelectedStyle : {}} />
            <BadgeLink {...props} handlerOnClick={handlerOnClick} type={'warning'} className={'bg-light-warning'} count={_.get(configuration, 'data.warning', 0)} overflowCount={100} color="#ff9f43"
                       icon={<AlertTriangle style={{width: '28px', height: '28px', strokeWidth: '2px'}}/>}
                       badgeSelectedStyle={badgeSelectedId === 'warning' ? badgeSelectedStyle : {}} />
            <BadgeLink {...props} handlerOnClick={handlerOnClick} type={'fail'} className={'bg-light-danger'} count={_.get(configuration, 'data.fail', 0)} overflowCount={100}
                       icon={<AiOutlineAlert style={{width: '28px', height: '28px', strokeWidth: '2px'}}/>}
                       badgeSelectedStyle={badgeSelectedId === 'fail' ? badgeSelectedStyle : {}} />
            <BadgeLink {...props} handlerOnClick={handlerOnClick} type={'offer_pending_approval'} className={'bg-approval-offer'} count={_.get(configuration, 'data.offer_pending_approval', 0)} overflowCount={100} color="rgb(129 129 129)"
                       icon={<AiOutlineSignature style={{width: '28px', height: '28px', strokeWidth: '2px'}} color={'rgb(129 129 129)'}/>}
                       badgeSelectedStyle={badgeSelectedId === 'offer_pending_approval' ? badgeSelectedStyle : {}} />

        </Space>
        }
    </>)
}