import axios from "axios";
import i18n from 'i18next';
import {afterSubmit, beforeSubmit, commonFailure, commonSuccess, getHeaders} from "utils/Requests/Axios";
import { setLanguage} from "utils/Language";
import { serialize } from "utils/Forms";
//css
import 'notyf/notyf.min.css';

//set language to user
export function ChangeUserLanguage(e, language) {
    //call to before submit handler
    beforeSubmit(e, () => {
        //success event when request is good
        const successHandler = (response) => {
            //call after submit
            afterSubmit(e, () => {
                //if ok signup...
                if(response.data.success) {
                    //set language to interface
                    setLanguage(language);
                    //get default language...
                    let message = 'Successfully changed their default language.';
                    if(language === 'ca') { //if changed to cat...
                        message = 'Ha canviat amb èxit el seu idioma predeterminat.';
                    } else if(language === 'es') { //if changed to es...
                        message = 'Ha cambiado con éxito su idioma predeterminado..';
                    } //show message of success change language
                    global.config.notyf_object.success(message);
                } else {
                    global.config.notyf_object.error(i18n.t('request.unknown'));
                }
            });
        }

        //fail event when request fail
        const failHandler = (error) => {
            //call after submit
            afterSubmit(e, () => { commonFailure(error); });
        }

        axios.post( `${global.config.api_url_v1}user/config/language`, serialize({'user_language': language}), getHeaders())
            .then(function (response) { successHandler(response); })
            .catch(function (error) { failHandler(error); });
    });
}

export function getUserData(successCallBack, failCallBack) {
    axios.get( `${global.config.api_url_v1}user/`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}