import React, {useEffect, useState} from "react";
import {getAnalysisSyncData} from "utils/Requests/Analysis";
import Skeleton from "react-loading-skeleton";
import config from "/app/src/config.js";
import {BadgeGlobalStatusSummary} from "../Badge/BadgeGlobalStatusSummary";
import {Link} from "react-router-dom";
import {
    isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller
} from "../../utils/Types";

const isMobileOnly = config.mbl_version;

const RouteLink = ({...props}) => {
    return (<>
        <Link style={{color: "inherit"}} to={(() => {
            if(!props.caller) {
                return `/dashboard`
            } else if (isSolarThermalByCaller(props)) {
                return `/dashboard/plants/thermal`
            } else if (isSolarPhotovoltaicByCaller(props)) {
                return `/dashboard/plants/photovoltaic`
            } else if (isWaterByCaller(props)) {
                return `/dashboard/plants/water`
            } else if (isThermalByCaller(props)) {
                return `/dashboard/plants/temperature`
            }
        })()}>
            {props.children}
        </Link>
    </>)
}

export function SyncSummary({...props}) {
    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        value: <Skeleton style={{width: "50px"}} />,
        isLoading: true
    });

    useEffect(() => {
        setConfiguration((configuration) => {
            if(configuration.isLoading) { return configuration; }
            return {...configuration, isLoading: true, value: props.number };
        });
    }, [props.number]);

    //after component is rendered...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //fix for load one moment
        if(!configuration.isLoading) { return }
        //get user data
        getAnalysisSyncData({...configuration},(data) => {
            //prevent crash with async function
            if (!isMounted) return null;
            //call to callback
            if(configuration.callback) configuration.callback(data);
            //set configuration
            setConfiguration({...configuration, isLoading: false, value: data.total});
        }, () => {
            //prevent crash with async function
            if (!isMounted) return null;
            //call to callback
            if(configuration.callback) configuration.callback();
            //set configuration
            setConfiguration({...configuration, isLoading: false, value: 'Error...'});
        });
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    return (<>
    { !isMobileOnly
        ? <div className="card" style={{ background: "transparent", border: "3px solid #EBE9F1"}}>
            { /* <CardHeaderWithIcon {...configuration} /> */}
            <div className="card-body flex flex-column"
                 style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div><h1 className="font-large-4 fw-bolder mt-0 mb-0">{configuration.value}</h1></div>
                <div><p className="card-text mb-1">{configuration.text}</p></div>
            </div>
        </div>
        : <div className="card card-mbl" style={{color: 'var(--bs-body-color)'}}>
            { /* <CardHeaderWithIcon {...configuration} /> */}
            <div className="card-body" style={{
                padding: "10px 20px 10px 20px", ...props.style
            }}>
                <p className="card-text" style={{marginBottom: '5px'}}>{configuration.text}</p>
                <div style={{display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                    justifyContent: 'space-between'}}>
                    <div>
                        <RouteLink caller={props.caller}>
                            <div style={{display: 'flex', alignContent: 'center', alignItems: 'center', gap: '5px'}}>
                                {configuration.icon}
                                <h1 className="font-large-1 fw-bolder mt-0 mb-0">{configuration.value}</h1>
                            </div>
                            {/*<div>*/}
                            {/*    <p className="card-text mb-1">{configuration.text}</p>*/}
                            {/*</div>*/}
                        </RouteLink>
                    </div>
                    {!props.noBadget && <div style={{marginRight: '2px'}}>
                        <BadgeGlobalStatusSummary caller={props.caller} total={configuration.value}/>
                    </div>}
                </div>
            </div>
        </div>}</>)
}