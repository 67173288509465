import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import React, {useState} from "react";
import {Settings, Power} from "react-feather";
import {useTranslation} from "react-i18next";
import {LogOutRequest} from "utils/Requests/Auth";
import {Link} from "react-router-dom";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

const Avatar = function({ ...props }) {
    const {t} = useTranslation();

    return (
        <>
            <a className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="/#" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name fw-bolder">{props.userName}</span>
                    <span className="user-status">{t('roles.user')}</span>
                </div>
                {props.userAvatar}
            </a>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user" style={{width: "auto !important"}}>
                { /* <span className="dropdown-header user-name">John Doe</span>
                                <hr className="dropdown-divider" /> */}
                <Link to={"/dashboard/user"} className={"dropdown-item"} data-bs-toggle="">
                    <Settings className="me-50"/>{t('settings')}
                </Link>
                <a className="dropdown-item" href="/#" onClick={LogOutRequest}>
                    <Power className="me-50"/>{t('logout')}
                </a>
            </div>
        </>
    )
}

const AvatarMobile = function({...props}) {
    const {t} = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown className={'rd-mobile-dropdown rd-dropdown'} isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
            <DropdownToggle className={'rd-dropdown-toggle-white'} caret>
                {props.userAvatar}
            </DropdownToggle>
            <DropdownMenu persist={false}>
                <DropdownItem>
                    <Link to={"/dashboard/user"} className={"d-flex align-items-center"}>
                        <Settings className="me-50" size={18} />{t('settings')}
                    </Link>
                </DropdownItem>
                <DropdownItem>
                    <a className="dropdown-item" href="/#" onClick={LogOutRequest}>
                        <Power className="me-50" size={18} />{t('logout')}
                    </a>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default function UserButtonNavbar({...props}) {
    return (<>
        { isMobileOnly
            ? <AvatarMobile {...props} />
            : <li className="nav-item dropdown dropdown-user">
                <Avatar {...props} />
            </li>
        }
    </>)
}