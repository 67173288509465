import React, {useEffect, useState} from "react";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import {useTranslation} from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {getPlotComponentByLoader, getPlotSubtitle, getPlotTimeMenu} from "../../utils/Plots/PlotsCommon";
import {getEventsPlotChartsJs} from "../../utils/Plots/PlotsChartsJS";
import {getEventsPlotData} from "../../utils/Requests/Analysis";
import {isStatusByType} from "../../utils/Types";

ChartJS.register(ArcElement, Tooltip, Legend);

//when request is success
const successRequest = (configuration, setConfiguration, response) => {
    let data = {};
    if(configuration.type === 'status') {
        data = response.status;
    } else if(configuration.type === 'alerts') {
        data = response.alerts;
    }

    let total = 0;
    Object.keys(data).forEach(key => {
        total += data[key];
    });

    //get default object for plot
    let defaultPlotData = getEventsPlotChartsJs({ legend: configuration.legend, caller: configuration.caller, rawData: { ...data, total: total }});
    defaultPlotData.datasets[0].data[0] = ((data.overproduction / total) * 100).toFixed(2);
    defaultPlotData.datasets[0].data[1] = ((data.underproduction / total) * 100).toFixed(2);
    defaultPlotData.datasets[0].data[2] = ((data.no_reading / total) * 100).toFixed(2);
    defaultPlotData.datasets[0].data[3] = ((data.ok / total) * 100).toFixed(2);
    defaultPlotData.loaded = true;
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: false}, plot: defaultPlotData })
}
//when request fails
const failRequest = (configuration, setConfiguration, response) => {
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: true}, plot: configuration.plot })
}

export function CardPlotCircleEvents({...props}) {
    const {t} = useTranslation();
    const legend = `legend-${props.caller}-${props.type}`;

    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.today',
        url: 'time.today',
        plot: getEventsPlotChartsJs({ legend: legend, caller: props.caller }),
        loaders: {plot: false, request: true, error: false},
        timeLine: {text: 'time.today', clicks: 0, disabledHistory: true},
        isLoading: true,
        legend: legend
    });

    //event to first load plot...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if (!configuration.loaders.request) { return }
        //set energy request
        const setData = (configuration, callback = () => {}) => {
            getEventsPlotData({...configuration, plantId: configuration.plantId, interval: configuration.url}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                successRequest(configuration, setConfiguration, response, callback);
            }, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                failRequest(configuration, setConfiguration, response);
            });
        } //call to load data
        setData(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    //set char component by default
    const charComponent = <Doughnut data={configuration.plot} options={configuration.plot.options} plugins={configuration.plot.plugins} type={"doughnut"} height={150} width={150} />;
    const title = isStatusByType(configuration)?t('plant.alerts.daily'):t('plant.alerts.hourly');

    return (
        <div className={`card card-${configuration.caller}-plot`}>
            { /* header */ }
            <CardSimpleHeader subtitleClass="font-small-1" style={{paddingBottom: "0px"}} subtitle={ getPlotSubtitle(configuration, setConfiguration) } title={title} rightComponent={ getPlotTimeMenu(configuration, setConfiguration) } />
            { /* body */ }
            <div className={"d-flex flex-row card-body pb-1 pt-1"}>
            { /* first plot */ }
                <div className="chartjs-container align-self-center center-576-730">
                    { getPlotComponentByLoader({ ...configuration, loaderHeight: 140 }, charComponent) }
                </div>
                <div id={legend} className={"legend-wrapper align-self-center hide-576-730"} />
            </div>
        </div>
    )
}