import React from "react";
import 'assets/css/loader.css';
import {Trans, useTranslation} from "react-i18next";
import LayoutSimplePage from "components/Layout/LayoutSimplePage";
import PenToolDivider from "components/Divider/PenToolDivider";
import SimpleDivider from "components/Divider/SimpleDivider";

export default function PageTerms({...props}) {
    const { t } = useTranslation();

    return (
        <LayoutSimplePage >
            { /* Title */}
            <h2>{ t('tos.title') }</h2>
            <PenToolDivider />
            { /* Sobre aquestes condicions */}
            <h3 className="mb-1">{ t('tos.block_04_title') }</h3>
            <p className="text-justify">
                { t('tos.block_04_p1') }
            </p>
            <p className="text-justify">
                { t('tos.block_04_p2') }
            </p>
            <p className="text-justify">
                { t('tos.block_04_p3') }
            </p>
            <p className="text-justify">
                    <Trans i18nKey="tos.block_04_p4" components={ [<a href='https://www.rdmes.com/#contacte' target='_blank' rel="noreferrer">contact</a>] } />
            </p>
            <SimpleDivider />
            { /* Política de privacitat */}
            <h3 className="mb-1">{ t('tos.block_01_title') }</h3>
            <p className="text-justify">
                { t('tos.block_01_p1') }
            </p>
            <p className="text-justify">
                { t('tos.block_01_p2') }
            </p>
            <p className="text-justify">
                { t('tos.block_01_p3') }
            </p>
            <SimpleDivider />
            { /* Responsabilitats */}
            <h3 className="mb-1">{ t('tos.block_02_title') }</h3>
            <p className="text-justify">
                { t('tos.block_02_p1') }
            </p>
            <p className="text-justify">
                { t('tos.block_02_p2') }
            </p>
            <p className="text-justify">
                { t('tos.block_02_p3') }
            </p>
            <SimpleDivider />
            { /* Litigis */}
            <h3 className="mb-1">{ t('tos.block_03_title') }</h3>
            <p className="text-justify">
                { t('tos.block_03_p1') }
            </p>
            <p className="text-justify">
                { t('tos.block_03_p2') }
            </p>
        </LayoutSimplePage>
    )
}