import React from "react";
import {useTranslation} from "react-i18next";
import HelpBlockInput from "components/Forms/HelpBlockInput";

export default function SettingsPasswordInput({ ...props }) {
    const { t } = useTranslation();
    //get use hook form methods
    const { register, formState: { errors } } = props.methods;
    //set a validation object
    const validation = {
        required: "validations.required",
        minLength: {
            value: 8,
            message: "validations.incorrect_format" // JS only: <p>error message</p> TS only support string
        }
    }; //get name to set
    const name = props.name??"user_password";
    //create register
    const inputProps = register(name, (props.validation)?{ ...props.validation, ...validation }:validation);

    return (
        <>
            <label htmlFor={name} className="form-label">
                { props.label??t("account.delete_account_password_field")}
            </label>
            <input {...inputProps} placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" type="password" className="form-control" />
            { props.helpBlock??<HelpBlockInput text={t('account.delete_account_password_help')} /> }
            {  errors[name]?.message && <div className="invalid-feedback-always">
                {t(errors[name]?.message)}
            </div> }
        </>
    )
}