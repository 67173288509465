import {
    geTimeByTimeLine,
    getTimeByTextLink,
    isThisMonth,
    isThisYear,
    isToday,
    minusSelectedDate,
    plusSelectedDate
} from "../Time";
import Skeleton from "react-loading-skeleton";
import {ChevronLeft, ChevronRight} from "react-feather";
import React from "react";
import MenuTimeIntervals from "components/Menu/TimeIntervalsMenu";
import {isThermalByCaller} from "../Types";

const minusTime = (cfg, callback) => {
    //call to update a timeline
    minusSelectedDate(cfg.timeLine, (time, timeLine) => {
        callback({
            ...cfg, text: time.text, url: time.url,
            loaders: {plot: false, request: true, error: false}, timeLine: timeLine
        })
    });
}

const plusTime = (cfg, callback) => {
    //call to update a timeline
    plusSelectedDate(cfg.timeLine, (time, timeLine) => {
        callback({
            ...cfg, text: time.text, url: time.url,
            loaders: {plot: false, request: true, error: false}, timeLine: timeLine
        })
    });
}

//subtitle plot
export function getPlotSubtitle(cfg, callback) {
    if (!cfg.loaders.plot || cfg.plot.request) {
        return <Skeleton/>;
    } else if (cfg.loaders.error) {
        return 'Error...';
    } else if(cfg.timeLine && cfg.timeLine.disabledHistory) {
        return <>
            {getTimeByTextLink(cfg.timeLine)}
        </>;
    } else if(cfg.timeLine) {
        const wrapperStyle = {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '5px',
            paddingBottom: '0px',
            alignContent: 'center',
            flexWrap: 'wrap'
        }
        //only show left is this not current day...
        let nextIcon = <ChevronRight width={14} height={20} className={"cursor-pointer"} onClick={() => plusTime(cfg, callback)}/>;
        if (isToday(geTimeByTimeLine(cfg.timeLine)) || isThisMonth(geTimeByTimeLine(cfg.timeLine))
            || isThisYear(geTimeByTimeLine(cfg.timeLine))) {
            nextIcon = <></>
        } //return component with next is this is needed
        return <div style={wrapperStyle}>
            <ChevronLeft width={14} height={20} className={"cursor-pointer"} onClick={() => minusTime(cfg, callback)}/>
            {geTimeByTimeLine(cfg.timeLine)}
            {nextIcon}
        </div>;
    }
}


export function getPlotTimeMenu(cfg, callback) {
    let timeMenu = ['time.today', 'time.month', 'time.year'];
    if (isThermalByCaller(cfg)) { //is thermal caller
        timeMenu = ['time.today'];
    } //return menu intervals
    return <MenuTimeIntervals configuration={ {...cfg, menu: timeMenu} } handler={callback} />
}

export function getPlotComponentByLoader(cfg, component = false) {
    if (!cfg.loaders.plot && !cfg.loaders.error) {
        return <Skeleton height={cfg.loaderHeight??10}/>;
    } else if (cfg.loaders.plot && cfg.loaders.error) {
        return <div style={{height: cfg.loaderHeight??10}}><p>Error...</p></div>;
    } else if (cfg.loaders.plot && !cfg.loaders.error) {
        return component;
    }
}