import React from "react";
import {useTranslation} from "react-i18next";

export default function EmailInput(props) {
    const { t } = useTranslation();

    return (
        <div className="mb-1">
            { /* if caller is not forgot, show label */}
            {!props.forgot &&
                <label htmlFor="email" className="form-label">{t('email')}</label>
            }
            <input { ...props } type="email" className="form-control" id="email" name="user_email" placeholder="john@example.com" aria-describedby="user_email" autoFocus required/>
            <div className="invalid-feedback"> { t('validations.email') } </div>
            <div className="invalid-remote duplicated"> { t('signup.dupe') } </div>
        </div>
    )
}