import React from "react";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function LayoutAuthentication({ ...props }) {
    return (
        <div className="blank-page">
            <div className="app-content content ">
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className={`auth-wrapper auth-v1 ${isMobileOnly && 'auth-v1-mbl'} px-2`}>
                            <div className="auth-inner mt-1 mb-1" style={ props.path === 'signup' ? {width: "490px"} : {}}>
                                { /* injection */ }
                                <div className="card mt-0 mb-0">
                                    { props.children }
                                </div>
                                { /* injection */ }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}