import React from "react";
import {useTranslation, Trans} from "react-i18next";
import PrivacyPolicyLink from "components/Links/PrivacyPolicyLink";

export default function PrivacyPolicyCheckBox({...props}) {
    const { t } = useTranslation();

    return (
        <div className="mb-1">
            <div className="form-check">
                <input {...props} className="form-check-input" type="checkbox" id="user_accepted_tos" name="user_accepted_tos" required/>
                <label className="form-check-label" htmlFor="register-privacy-policy">
                    <Trans i18nKey="agree_with" components={[<PrivacyPolicyLink {...props} />]} />
                </label>
                <div className="invalid-feedback">
                    { t('validations.agree_required') }
                </div>
            </div>
        </div>
    )
}