import {AvatarSuchAsIcon} from "components/Icon/AvatarSuchAsIcon";
import React from "react";

export function CardHeaderWithIcon({...props}) {
    return (
        <>
            <div className="card-header pb-0">
                <div className="d-flex align-items-center justify-content-start">
                    <div className={`avatar bg-light-primary p-50 m-0 ${props.className??''}`} style={{cursor: "default"}}>
                        <AvatarSuchAsIcon icon={props.icon}/>
                    </div>

                    <div style={{marginLeft: "10px"}} className="card-title">
                        {props.title}
                    </div>
                </div>
                { props.rightComponent && props.rightComponent}
            </div>
        </>
    )
}

export function CardHeaderNoIcon({...props}) {
    return (
        <>
            <div className="card-header pb-0">
                <div className="d-flex align-items-center justify-content-start">
                    {props.icon}
                    <div className="card-title">
                        {props.title}
                    </div>
                </div>
                {props.subtitle && <p className="font-small-3 card-text">{props.subtitle}</p>}
            </div>
        </>
    )
}