import axios from "axios";
import {commonFailure, commonSuccess, getHeaders} from "./Axios";
import {isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller} from "utils/Types";

export function getAnalysisSyncData(config, successCallBack, failCallBack) {
    //url end point
    let urlPrefix = 'plant';
    //if caller type is two...
    if(parseInt(config.type) === 2) { urlPrefix = 'set'; }
    //set default end pint
    let defaultEndPoint = '/analysis/sync';
    //get solar thermal end point
    if(isSolarThermalByCaller(config)) {
        defaultEndPoint = '/analysis/solar/thermal/sync';
    //get photovoltaic end point
    } else if(isSolarPhotovoltaicByCaller(config)) {
        defaultEndPoint = '/analysis/solar/photovoltaic/sync';
    } else if(isThermalByCaller(config)) {
        defaultEndPoint = '/analysis/thermal/sync';
    } else if(isWaterByCaller(config)) {
        defaultEndPoint = '/analysis/water/sync';
    }

    axios.get( `${global.config.api_url_v1}${urlPrefix}${defaultEndPoint}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getAnalysisProduction(config, successCallBack, failCallBack) {
    //general end point
    let endPoint = 'plant/analysis';
    if(isWaterByCaller(config)) { //set end pont by caller
        endPoint = `${endPoint}/water/consumption`;
    } else {
        endPoint = `${endPoint}/solar/production`;
    }

    axios.get( `${global.config.api_url_v1}${endPoint}/${config.interval}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getAnalysisSolarCO2Savings(config, successCallBack, failCallBack) {
    //set default end pint
    let defaultEndPoint = 'plant/analysis/solar/thermal/savings/co2';
    // if pv plant
    if(isSolarPhotovoltaicByCaller(config)) {
        defaultEndPoint = 'plant/analysis/solar/photovoltaic/savings/co2';
    }

    axios.get( `${global.config.api_url_v1}${defaultEndPoint}/${config.interval}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getAnalysisEcoSavings(config, successCallBack, failCallBack) {
    let endPoint = 'plant/analysis';
    //set default end pint
    let defaultEndPoint = `${endPoint}/solar/thermal/savings/eco`;
    //set pv plant
     if(isSolarPhotovoltaicByCaller(config)) {
        defaultEndPoint = `${endPoint}/solar/photovoltaic/savings/eco`;
    } else if(isWaterByCaller(config)) {
         defaultEndPoint = `${endPoint}/water/cost`;
     }

    axios.get( `${global.config.api_url_v1}${defaultEndPoint}/${config.interval}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getPlotData(config, successCallBack, failCallBack) {
    let baseUrl = global.config.api_url_v1;
    if(isSolarThermalByCaller(config)) {
        baseUrl = `${baseUrl}plant/analysis/solar/thermal`;
    } else if(isSolarPhotovoltaicByCaller(config)) {
        baseUrl = `${baseUrl}plant/analysis/solar/photovoltaic`;
    } else if(isThermalByCaller(config)) {
        baseUrl = `${baseUrl}plant/analysis/thermal`;
    } else if(isWaterByCaller(config)) {
        baseUrl = `${baseUrl}plant/analysis/water`;
    } else {
        return {};
    }

    if(config.plantId) {
        baseUrl = `${baseUrl}/${config.plantId}`;
    }

    baseUrl = `${baseUrl}/plot/${config.interval}`;

    axios.get( `${baseUrl}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getEventsPlotData(config, successCallBack, failCallBack) {
    //set default end pint
    let defaultEndPoint;
    //set pv plant
    if(isSolarPhotovoltaicByCaller(config)) {
        defaultEndPoint = 'plant/analysis/solar/photovoltaic/events/plot/';
    } else if(isSolarThermalByCaller(config)){
        defaultEndPoint = 'plant/analysis/solar/thermal/events/plot/';
    } else if(isThermalByCaller(config)){
        defaultEndPoint = 'plant/analysis/thermal/events/plot/';
    } else if(isWaterByCaller(config)){
        defaultEndPoint = 'plant/analysis/water/events/plot/';
    } else {
        return {};
    }

    axios.get( `${global.config.api_url_v1}${defaultEndPoint}${config.interval}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getAnalysisEvents(config, successCallBack, failCallBack) {
    //set default end pint
    let defaultEndPoint = 'plant/analysis/solar/events/';
    //set pv plant
    if(isSolarPhotovoltaicByCaller(config)) {
        defaultEndPoint = 'plant/analysis/solar/photovoltaic/events/';
    } else if(isSolarThermalByCaller(config)){
        defaultEndPoint = 'plant/analysis/solar/thermal/events/';
    } else if(isThermalByCaller(config)){
        defaultEndPoint = 'plant/analysis/thermal/events/';
    } else if(isWaterByCaller(config)){
        defaultEndPoint = 'plant/analysis/water/events/';
    }

    axios.get( `${global.config.api_url_v1}${defaultEndPoint}${config.interval}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getPlantsTimeProduction(config, successCallBack, failCallBack) {
    //default endpoint
    let endpoint = "";
    //set endpoint in function of caller device
    if(isSolarThermalByCaller(config)) {
        endpoint += "plant/solar/thermal";
    } else if(isSolarPhotovoltaicByCaller(config)) {
        endpoint += "plant/solar/photovoltaic";
    } else if(isThermalByCaller(config)) {
        endpoint += "plant/solar/thermal";
    } else if(isWaterByCaller(config)) {
        endpoint += "plant/water";
    } else {
        return failCallBack();
    } //set endpoint
    endpoint = `${endpoint}/${config.plantId}/production/${config.time}`;

    //set limit...
    endpoint += '?limit=' + config.pagination.perPage;
    endpoint += '&offset=' + (config.pagination.perPage * (config.pagination.page - 1));
    endpoint += '&sortCol=' + config.sort.column;
    endpoint += '&sortDir=' + config.sort.direction;

    if(config.search) {
        endpoint += '&' + Object.keys(config.search).map(key => {
            if(!config.search[key] || (Array.isArray(config.search[key]) && config.search[key].length < 1)) {
                return '';
            } else {
                return key + '=' + config.search[key];
            }
        }).join('&');
    }

    axios.get( `${global.config.api_url_v1}${endpoint}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getPlantsDailyProduction(config, successCallBack, failCallBack) {
    //default endpoint
    let endpoint = "";
    //set endpoint in function of caller device
    if(isSolarThermalByCaller(config)) {
        endpoint += "plant/solar/thermal";
    } else if(isSolarPhotovoltaicByCaller(config)) {
        endpoint += "plant/solar/photovoltaic";
    } else {
        return failCallBack();
    } //set endpoint
    endpoint = `${endpoint}/${config.plantId}/${config.time}`;

    //set limit...
    endpoint += '?limit=' + config.pagination.perPage;
    endpoint += '&offset=' + (config.pagination.perPage * (config.pagination.page - 1));
    endpoint += '&sortCol=' + config.sort.column;
    endpoint += '&sortDir=' + config.sort.direction;

    if(config.search) {
        endpoint += '&' + Object.keys(config.search).map(key => {
            if(!config.search[key] || (Array.isArray(config.search[key]) && config.search[key].length < 1)) {
                return '';
            } else {
                return key + '=' + config.search[key];
            }
        }).join('&');
    }

    axios.get( `${global.config.api_url_v1}${endpoint}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}