import React, {useEffect, useRef, useState} from "react";
import DataTable from "react-data-table-component";
import {ChevronDown} from "react-feather";
import {CardBody, Col, Label, Row, Button} from "reactstrap";
import 'assets/css/react-dataTable.scss';
import {useTranslation} from "react-i18next";
import {getProductionColumnsTableByTime, isThermalByCaller} from "utils/Types";
import {Input} from 'antd';
import EventsSelect from "components/Forms/Inputs/EventsInput";
import { Search, RefreshCw} from 'react-feather';
import LoadingBar from "react-top-loading-bar";
import {removeBlurButton} from 'utils/Buttons';
import {getValue} from "utils/Forms";
import {SearchAlert} from "components/Alerts";
import {DataTableError, DataTableNoRecords, DataTableLoader} from "components/Table/PlantsTableCommon";
import {getPlantsTimeProduction} from "utils/Requests/Analysis";
import {CardOverlayLoader} from "components/Cards/CardOverlayLoader";
import i18next from "i18next";
import {useSearchParams} from "react-router-dom";
import moment from "moment";
import {getDateStringFormat, getDateTimeStringFormat, getYearMonthStringFormat} from "../../utils/Time";

const _ = require('lodash');

/* default pagination per page */
const defaultPaginationPerPage = 20;
const defaultPaginationNumber = [10, 15, 20, 25, 30];
/* default load config */
const defaultTableConfig = {
    sort: { column: 'plant_date', direction: 'DESC' },
    pagination: { perPage: defaultPaginationPerPage, page: 1}
}

/* grid form */
export function SearchPlantForm({...props}) {
    const {t} = useTranslation();
    const [formData, setFormData] = useState({ });
    //on each input change...
    const handleInputChange = (event) => {
        const obj = { ...formData, [event.target.name] : getValue(event) };
        if(!getValue(event)) {
            delete obj[event.target.name];
        } //set object data
        setFormData(obj);
    }
    //on each select change...
    const handleSelectChange = (name, value) => {
        const obj = { ...formData, [name] : value };
        if(value.length < 1) {
            delete obj[name];
        } //set object data
        setFormData(obj);
    }

    //call to search
    const handlerSearch = (e) => {
        //call to before submit handler
        props.handlerSearch(formData);
    }
    //call to cancel
    const handlerCancel = () => {
        setFormData({});
        props.handlerCancel();
    }

    const searchButton = <div className='ms-1'>
        <Button outline onClick={handlerSearch} color='primary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
            <Search size={15} />
        </Button>
    </div>;

    const refreshButton = <div style={{marginLeft: "5px"}}>
        <Button outline onClick={handlerSearch} color='secondary' size='sm' style={{padding: "0.5rem 0.5rem"}}>
            <RefreshCw size={15} />
        </Button>
    </div>

    return (<>
        <Row className='mt-0 mb-0 grid-form' disabled style={{paddingLeft: "15px"}}>
            <Col lg={(props.configuration.time === 'time.monthly')?'12':'6'} md={(props.configuration.time === 'time.monthly')?'12':'6'} style={{paddingLeft: "0px", marginBottom: "5px"}}>
                <Label for='plant_address'>{ (function(){
                    if (props.configuration.time === 'time.hourly') {
                        return i18next.t('datetime')
                    } else if(props.configuration.time === 'time.daily') {
                        return i18next.t('date');
                    } else if(props.configuration.time === 'time.monthly') {
                        return i18next.t('month');
                    }
                }()) }</Label>

                <div className={(props.configuration.time === 'time.monthly')?"mb-1 d-flex justify-content-between":'mb1'}>
                    <Input pattern="[7-9]{1}[0-9]{9}" placeholder={ (function(){
                        if (props.configuration.time === 'time.hourly') {
                            return `${getYearMonthStringFormat()} or ${getDateStringFormat()} or ${getDateTimeStringFormat()}`;
                        } else if(props.configuration.time === 'time.daily') {
                            return `${getDateStringFormat()} or ${getYearMonthStringFormat()}`;
                        } else if(props.configuration.time === 'time.monthly') {
                            return `${getYearMonthStringFormat()} or YYYY`;
                        }
                    }()) } value={formData.plant_date} name='plant_date' onChange={handleInputChange} onPressEnter={handlerSearch} />
                    { props.configuration.time === 'time.monthly' && searchButton }
                    { props.configuration.time === 'time.monthly' && refreshButton }
                </div>
            </Col>

            { props.configuration.time !== 'time.monthly' &&
                <Col lg='6' md='6' style={{paddingLeft: "0px"}}>
                    <Label for='alert_id'>{ t('alert') }</Label>
                    <div className={"mb-1 d-flex justify-content-between"}>
                        <EventsSelect type={'alerts'} value={formData.plant_alert??[]} name='plant_alert' onChange={handleSelectChange}/>
                        { searchButton }
                        { refreshButton }
                    </div>
                </Col>
            }

        </Row>
        { !_.isEmpty(props.searchApplied) && <SearchAlert handler={handlerCancel} /> }
    </>)
}

const translatePlantDateSearch = (currSearchDate) => {
    const sizeDate = currSearchDate.split('/').length - 1;
    let date = null;
    if(sizeDate === 0) {
        date = moment(currSearchDate, 'YYYY').format('YYYY');
    } else if(sizeDate === 1) {
        date = moment(currSearchDate, getYearMonthStringFormat()).format('YYYY-MM');
    } else if(sizeDate === 2) {
        if(currSearchDate.split(':').length - 1 === 0) {
            date = moment(currSearchDate, getDateStringFormat()).format('YYYY-MM-DD');
        } else {
            date = moment(currSearchDate, getDateTimeStringFormat()).format('YYYY-MM-DD HH:mm');
        }
    }
    if(!date || date === 'Invalid date') { return null; }
    return date;
}

/* grid */
export function PlantsProductionTable({...props}) {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    // get query params for set filter or not for 0 values
    const queryParams = Object.fromEntries([...searchParams]);
    const dataTableContainerReference = useRef();
    const loadingBarReference = useRef();
    const [configuration, setConfiguration] =  useState({ //fix to change time interval
        ...props,
        rows: [],
        isLoading: true,
        message: <DataTableNoRecords />,
        totalRows: 0,
        disabled: true,
        tableConfig: {...defaultTableConfig },
        filterZero: _.get(queryParams, 'filterzero', 1),
        paginationOption: {
            rowsPerPageText: t('pagination.rows_per_page'),
            rangeSeparatorText: t('pagination.page_separator'),
            selectAllRowsItem: false,
            selectAllRowsItemText: t('pagination.all'),
        }
    });

    //set translate use effect call
    const translateEffectDependency = t('plant.daily_status');
    //fix for pagination translation
    useEffect(() => {
        //translate
        setConfiguration(configuration => {
            if(configuration.isLoading) { return configuration; }
            return {...configuration, paginationOption: {
                    rowsPerPageText: i18next.t('pagination.rows_per_page'),
                    rangeSeparatorText: i18next.t('pagination.page_separator')
                }
            }
        });
    }, [translateEffectDependency]);

    //effect to load data...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //only on first load...
        if(!configuration.isLoading) { return; }
        //load data
        const requestRows = (config) => {
            //remove blur on buttons
            removeBlurButton(dataTableContainerReference.current).then(() => {});
            //only show in not first load
            if(!configuration.isLoading) { loadingBarReference.current.continuousStart(); }
            // params to send request
            let params = {...configuration.tableConfig}
            // if is set plant_date is needed translate dates
            const currSearchDate = _.get(configuration, 'tableConfig.search.plant_date', null);
            // translate date if exist for search
            if(currSearchDate) {
                params = { ...params, search: { ...configuration.tableConfig.search, plant_date: translatePlantDateSearch(currSearchDate) } }
            }

            //get user plants
            getPlantsTimeProduction({...configuration, ...params}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                // filter by 0 for thermal meter
                if(isThermalByCaller(configuration) && parseInt(configuration.filterZero) === 1) { // filter values with 0 value
                    // change all 0 to alert no reading
                    response.data.production = response.data.production.map((val, idx) => {
                        return { ...val, plant_alert: (val.plant_energy === 0 && val.plant_alert !== 2) ? 2 : val.plant_alert }
                    })
                }
                //set configuration
                setConfiguration({...configuration, rows: response.data.production, isLoading: false, message: <DataTableNoRecords />, totalRows: response.data.total, disabled: false });
                //end the progress bar...
                loadingBarReference.current.complete();
            }, () => {
                //prevent crash with async function
                if (!isMounted) return null;
                //set configuration
                setConfiguration({...configuration, rows: [], isLoading: false, message: <DataTableError />, disabled: false });
                //end the progress bar...
                loadingBarReference.current.complete();
            });
        }; //load data...
        requestRows(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    //on handle sort
    const handlerSort = (column, sortDirection) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, sort: { column: column.sortField, direction: sortDirection }}});
    }
    //on handle page
    const handlePageChange = (page) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: page}}});
    };
    //on handle per page
    const handlePerRowsChange = (perPage, page) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: perPage, page: 1}}});
    };
    //on handle search
    const handlerSearch = (formData) => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: 1}, search: formData }});
    }
    //on handle reset search
    const handlerCancel = () => {
        setConfiguration({...configuration, disabled: true, isLoading: true, tableConfig: { ...configuration.tableConfig, pagination: { perPage: configuration.tableConfig.pagination.perPage, page: 1}, search: null }});
    }

    if(!_.isEmpty(configuration.tableConfig.search)) {
        configuration.tableProps = {...configuration.tableProps, fixedHeaderScrollHeight: `${props.tableProps.fixedHeaderScrollHeight - 55}px`};
    } else if(!_.isEmpty(props.tableProps)) {
        configuration.tableProps = {...configuration.tableProps, fixedHeaderScrollHeight: `${props.tableProps.fixedHeaderScrollHeight}px`};
    }

    /* render component */
    return (
        <>
            <span ref={dataTableContainerReference}>
                <CardBody style={{marginBottom: 0, marginTop: 0, paddingTop: 0, paddingBottom: 0}}>
                    <SearchPlantForm configuration={configuration} searchApplied={configuration.tableConfig.search} handlerSearch={handlerSearch} handlerCancel={handlerCancel} disabled={configuration.disabled} />
                </CardBody>
                <div className={"react-dataTable"}>
                    <LoadingBar containerClassName="loading-bar-container" className="loading-bar" color='#f11946' ref={loadingBarReference} style={{position: "relative"}}/>
                    <DataTable noHeader pagination responsive paginationServer
                               columns={getProductionColumnsTableByTime(configuration)} sortIcon={<ChevronDown />} data={configuration.rows} paginationComponentOptions={configuration.paginationOption}
                               onSort={handlerSort} sortServer persistTableHead defaultSortFieldId={1} progressPending={configuration.isLoading} progressComponent={<DataTableLoader />}
                               noDataComponent={configuration.message} disabled={configuration.disabled} paginationTotalRows={configuration.totalRows}
                               onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} paginationPerPage={defaultPaginationPerPage}
                               paginationRowsPerPageOptions={defaultPaginationNumber} {...configuration.tableProps}
                               customStyles={{rows: { style: {fontSize: '13px'}}}}

                    />
                </div>
            </span>
            {/* overlay loader for a card */ }
            { configuration.isLoading && <CardOverlayLoader /> }
        </>
    );
}