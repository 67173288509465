import {Trans, useTranslation} from "react-i18next";
import {Alert} from "reactstrap";
import {Search, AlertOctagon} from "react-feather";
import React from "react";

export function SearchAlert({...props}) {
    const {t} = useTranslation();

    return (
        <Alert color='primary'>
            <div className='alert-body d-flex align-items-center' style={{cursor: "default"}}>
                <Search size={15} />
                <span className='ms-1'>
                    <Trans i18nKey={'search_applied'} components={[
                        <span style={{color: "#545452", cursor: "pointer"}} onClick={props.handler}>
                            { t("here").toLowerCase() }
                        </span>
                    ]} />
                </span>
            </div>
        </Alert>
    );
}

export function NotFoundPlantAlert({...props}) {
    const {t} = useTranslation();

    return (
        <Alert color='warning'>
            <div className='alert-body d-flex align-items-center' style={{cursor: "default"}}>
                <AlertOctagon size={15} />
                <span className='ms-1'>
                    { t("plant.not_found") }
                </span>
            </div>
        </Alert>
    );
}

