import React from "react";

const { v4: uuid } = require('uuid');

export default function CardTable({...props}) {
    //get all cells data for related row
    const getCells = (row) => {
        return row.map(cell => {
            return (<td key={uuid()}>{ cell.text }</td>)
        });
    } //set headers
    let headers = [];
    //for each header on table...
    for(let i = 0; i < props.table.headers.length; i++) {
        headers.push(<th key={uuid()} style={props.table.headers[i].style}>{ props.table.headers[i].text }</th>);
    }//set rows
    let rows = [];
    //for each row...
    for (let i = 0; i < props.table.rows.length; i++) {
        //set a row
        rows.push(<tr key={uuid()}>{ getCells(props.table.rows[i]) }</tr>);
    } //component
    return (
        <>
            <div className="col-sm-12">
                <div className="card-snippet card">
                    <div className="card-body">
                        <p className="card-text">
                            { props.table.title &&
                                <><b>{props.table.title}</b><br/></>
                            }
                            {props.table.description &&
                                props.table.description
                            }
                        </p>
                    </div>
                    <div className="table-responsive" style={{fontSize: "14px"}}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                { headers }
                            </tr>
                            </thead>
                            <tbody>
                                { rows }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}