//set on window the back page
export function setBackPage(link) {
    window.back_page = link;
}

//get from window the back page
export function getBackPage() {
    //if not set back page var on windows
    if(!window.back_page) {
        return '/';
    } //return back page saved
    return window.back_page;
}