import React from "react";
import Login from "view/Authentication/Forms/Login";
import Signup from "view/Authentication/Forms/Signup";
import Forgot from "view/Authentication/Forms/Forgot";
import Change from "view/Authentication/Forms/Change";
import LayoutAuthentication from "components/Layout/LayoutAuthentication";
import {useParams} from "react-router-dom";

export default function PageAuthentication({ ...props }) {
    const { uuid } = useParams();

    const Render = ({ ...props }) => {
        if(props.path === 'login') {
            return <Login />;
        } else if(props.path === 'signup') {
            return <Signup />;
        } else if(props.path === 'forgot') {
            if(uuid) { //with uuid to change password
                return <Change uuid={uuid}/>;
            } //default layout for forgot
            return <Forgot />;
        }
    }

    return (
        <LayoutAuthentication {...props}>
            <Render { ...props }/>
        </LayoutAuthentication>
    )
}