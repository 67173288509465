import {commonFailure, commonSuccess, getHeaders, sessionExpired} from "utils/Requests/Axios";
import {serialize} from "utils/Forms";
import axios from "axios";
import {isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller} from "utils/Types";

export async function PlantsAdd(formData, successCallBack = () => {}, failCallBack = () => {}) {
    //complete progress bar
    window.progressReference.current.continuousStart();
    //success event when request is god
    const successHandler = async (response) => {
        //end the progress bar..
        window.progressReference.current.complete();
        //on success true
        if (response.data.success) {
            //show message of success sign up
            successCallBack('request.successfully');
        } else { //other events
            successCallBack('request.unknown');
        }
    }

    //fail event when request fail
    const failHandler = async (error) => {
        //end the progress bar..
        window.progressReference.current.complete();
        //for each error status
        if (error.response && error.response.status) {
            if (error.response.status === 429) {
                return failCallBack('request.max_requests');
            } else if (error.response.status === 400) {
                return failCallBack('request.bad_request');
            } else if (error.response.status === 403) {
                return failCallBack('request.unauthorized');
            } else if (error.response.status === 401) {
                return sessionExpired();
            }
        } //default fail error
        failCallBack('request.unknown');
    }

    //make a request
    await axios.post(
        `${global.config.api_url_v1}plant/reclaim`,
        serialize(formData),
        getHeaders(),
    ).then(async function (response) {
        await successHandler(response);
    }).catch(async function (error) {
        await failHandler(error);
    });
}

export function getUserPlants(config, successCallBack, failCallBack) {
    //default endpoint
    let endpoint = "";
    //set endpoint in function of caller device
    if(isSolarThermalByCaller(config)) {
        endpoint = "plant/solar/thermal/index";
    } else if(isSolarPhotovoltaicByCaller(config)) {
        endpoint = "plant/solar/photovoltaic/index";
    } else if(isThermalByCaller(config)) {
        endpoint = "plant/thermal/index";
    } else if(isWaterByCaller(config)) {
        endpoint = "plant/water/index";
    } else {
        return failCallBack();
    }

    //set limit...
    endpoint += '?limit=' + config.pagination.perPage;
    endpoint += '&offset=' + (config.pagination.perPage * (config.pagination.page - 1));
    endpoint += '&sortCol=' + config.sort.column;
    endpoint += '&sortDir=' + config.sort.direction;

    if(config.search) {
        endpoint += '&' + Object.keys(config.search).map(key => {
            if(!config.search[key] || (Array.isArray(config.search[key]) && config.search[key].length < 1)) {
                return '';
            } else {
                return key + '=' + config.search[key];
            }
        }).join('&');
    }

    axios.get( `${global.config.api_url_v1}${endpoint}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function getUserPlantsDataById(id, successCallBack, failCallBack) {
    axios.get( `${global.config.api_url_v1}plant/${id}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}

export function deleteUserPlant(id, successCallBack, failCallBack) {
    axios.delete( `${global.config.api_url_v1}plant/${id}`, getHeaders())
        .then(function (response) { commonSuccess(response, successCallBack); })
        .catch(function (error) { failCallBack(); commonFailure(error); });
}