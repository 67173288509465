import React, {useEffect, useState} from "react";
import NavbarDashboard from "components/Navbar/NavbarDashboard";
import MenuDashboard from "components/Menu/DashboardMenu";
import PageAnalytics from "view/Dashboard/PageAnalytics";
import Skeleton from "react-loading-skeleton";
import {getUserData} from "utils/Requests/User";
import {isAuthOnLoad} from "utils/Requests/Auth";
import PagePlantsAdd from "view/Plants/PagePlantsAdd";
import PageSolar from "view/Plants/PageSolar";
import PagePlant from "view/Plants/PagePlant";
import {_SOLAR_PHOTOVOLTAIC, _SOLAR_THERMAL, _THERMAL, _WATER} from "utils/Types";
import PageThermal from "view/Plants/PageThermal";
import PageWater from "view/Plants/PageWater";
import PageUser from "view/Settings/PageUser";
import {getUserAvatar} from "utils/Menu";
import {getLanguage} from "../utils/Language";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

let refreshTokenInterval = null;

// wrapper for desktop a mobile view
function PageComponent({...props}) {
    // set props
    const { userData, setUserData, isLoading } = props;

    // render needed page
    return (
        <>
            { /* dash main page */ }
            { (props.path === 'dash') && <PageAnalytics {...props} userName={userData.user_name} />}
            { /* user path */ }
            { props.path === 'user' && <PageUser {...props} isLoading={isLoading} user={userData} updateUser={setUserData} /> }
            { /* add plant */ }
            { props.path === 'plants/add' && <PagePlantsAdd {...props} /> }
            { /* thermal */ }
            { props.path === 'plants/thermal' && <PageSolar {...props} caller={_SOLAR_THERMAL}/> }
            { props.path === 'plants/thermal/:id' && <PagePlant {...props} caller={_SOLAR_THERMAL}/> }
            { /* photovoltaic */ }
            { props.path === 'plants/photovoltaic' && <PageSolar {...props} caller={_SOLAR_PHOTOVOLTAIC}/> }
            { props.path === 'plants/photovoltaic/:id' && <PagePlant {...props} caller={_SOLAR_PHOTOVOLTAIC}/> }
            { /* temperature */ }
            { props.path === 'plants/temperature' && <PageThermal {...props} caller={_THERMAL} /> }
            { props.path === 'plants/temperature/:id' && <PagePlant {...props} caller={_THERMAL} /> }
            { /* temperature */ }
            { props.path === 'plants/water' && <PageWater {...props} caller={_WATER} /> }
            { props.path === 'plants/water/:id' && <PagePlant {...props} caller={_WATER} /> }
        </>
    )
}

export default function PageDashboard({ ...props }) {
    //set user data...
    const [userData, setUserData] = useState({
        user_email: null,
        user_id: null,
        user_language: getLanguage(),
        user_last_name: null,
        user_full_name: <Skeleton style={{width: "100px"}} />,
        user_name: <Skeleton inline style={{width: "100px", lineHeight: "21px"}} />,
        user_avatar: <Skeleton circle width={40} height={40} containerClassName="avatar-skeleton" />
    }); //state to load user...
    const [isLoading, setLoading] = useState(true);
    //after component is rendered...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //fix for load one moment
        if(!isLoading) { return }
        ///get user data
        getUserData((user) => {
            //prevent crash with async function
            if (!isMounted) return null;
            //get user full name
            const fullName = `${user.user_name} ${user.user_last_name}`;
            //set user data...
            setUserData({
                ...userData,
                ...user,
                user_full_name: fullName,
                user_avatar: getUserAvatar(fullName)
            }); //set loading to false
            setLoading(false);
        }, () => {
            //prevent crash with async function
            if (!isMounted) return null;
            //set user data...
            setUserData({
                ...userData,
                user_full_name: 'Error...',
                user_name: 'Error...'
            }); //set loading to false
            setLoading(false);
        });
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [isLoading, userData]);

    //refresh token
    useEffect(() => {
        //refresh token every x seconds -> each 30 minutes
        refreshTokenInterval = setInterval(() => { isAuthOnLoad() }, 1800000);
        return () => { //remove interval callback when component unmount
            clearInterval(refreshTokenInterval);
        }
    });

    return (
        <>
            <div className={`wrapper ps-0 pe-0 ${!isMobileOnly && "wrapper ps-1 pe-1"}`}>
                {isMobileOnly ? <>
                    <MenuDashboard {...props} userName={userData.user_full_name} userAvatar={userData.user_avatar}/>
                    <div className="content-overlay"/>
                    <PageComponent {...props} userData={userData} setUserData={setUserData} isLoading={isLoading}/>
                </> : <>
                    <NavbarDashboard userName={userData.user_full_name} userAvatar={userData.user_avatar}/>
                    <MenuDashboard {...props} />
                    <div className="content-overlay"/>
                    <PageComponent {...props} userData={userData} setUserData={setUserData} isLoading={isLoading}/>
                </>}
            </div>
        </>
    )
}