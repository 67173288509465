import React from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller} from "utils/Types";

const { v4: uuid } = require('uuid');

/** LINKS **/
export function DashboardLink({...props}) {
    const {t} = useTranslation();
    return(
        <>
            { !props.disabled && <Link to='/dashboard'>{t('dashboard')}</Link> }
            { props.disabled && <span>{t('dashboard')}</span> }
        </>
    )
}

export function SolarThermalLink({...props}) {
    const {t} = useTranslation();
    return(
        <>
            { !props.disabled && <Link to='/dashboard/plants/thermal'>{t('thermal')}</Link> }
            { props.disabled && <span>{t('thermal')}</span> }
        </>
    )
}

export function PhotovoltaicLink({...props}) {
    const {t} = useTranslation();
    return(
        <>
            { !props.disabled &&<Link to='/dashboard/plants/photovoltaic'> {t('photovoltaic')} </Link> }
            { props.disabled && <span>{t('photovoltaic')}</span> }
        </>
    )
}

export function ThermalLink({...props}) {
    const {t} = useTranslation();
    return(
        <>
            { !props.disabled &&<Link to='/dashboard/plants/temperature'> {t('temperature')} </Link> }
            { props.disabled && <span>{t('temperature')}</span> }
        </>
    )
}

export function WaterLink({...props}) {
    const {t} = useTranslation();
    return(
        <>
            { !props.disabled &&<Link to='/dashboard/plants/water'> {t('water')} </Link> }
            { props.disabled && <span>{t('water')}</span> }
        </>
    )
}

export function PlantLink({...props}) {
    return(
        <>
            { !props.disabled &&<Link to='#'> {props.text} </Link> }
            { props.disabled && <span>{props.text}</span> }
        </>
    )
}

/** CRUMB ITEMS **/
export function BreadcrumbDashboardItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <DashboardLink {...props} />
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbPlantsItem({...props}) {
    const {t} = useTranslation();
    return(
        <>
            <BreadcrumbItem>
                { t('plants') }
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbSolarThermalItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <SolarThermalLink {...props} />
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbPhotovoltaicItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <PhotovoltaicLink {...props} />
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbThermalItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <ThermalLink {...props} />
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbPlantItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <PlantLink {...props} />
            </BreadcrumbItem>
        </>
    )
}

export function BreadcrumbWaterItem({...props}) {
    return(
        <>
            <BreadcrumbItem>
                <WaterLink {...props} />
            </BreadcrumbItem>
        </>
    )
}


/** CONSTRUCTOR **/
export default function BreadcrumbConstructor({...props}) {
    return (
        <Breadcrumb className='mt-1 mb-1'>
            {props.crumbs.map(function (config) {
                if (config.id === 'dashboard') {
                    return <BreadcrumbDashboardItem key={uuid()} {...config} />
                } else if (config.id === 'plants') {
                    return <BreadcrumbPlantsItem key={uuid()} {...config} />
                } else if (isSolarThermalByCaller({caller: config.id})) {
                    return <BreadcrumbSolarThermalItem key={uuid()} {...config} />
                } else if (isSolarPhotovoltaicByCaller({caller: config.id})) {
                    return <BreadcrumbPhotovoltaicItem  key={uuid()} {...config} />
                } else if (isThermalByCaller({caller: config.id})) {
                    return <BreadcrumbThermalItem  key={uuid()} {...config} />
                } else if (isWaterByCaller({caller: config.id})) {
                    return <BreadcrumbWaterItem  key={uuid()} {...config} />
                }   else if(config.id === 'plant') {
                    return <BreadcrumbPlantItem key={uuid()} {...config} />
                }
                return '<span></span>';
            })}
        </Breadcrumb>
    )
}