import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactApexChart from "react-apexcharts/dist/react-apexcharts.min";
import { Progress } from 'reactstrap';
import {
    geTimeByTimeLine,
} from "utils/Time";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import {getPlotData} from "utils/Requests/Analysis";
import {
    apexChartLabel,
    dayGeneralTooltipFormatter, dayTooltipFormatter,
    getEcoSavings,
    getEnergyFormat,
    getMoneyUnit,
    getNormalizedArrayForPlot,
    getSolarPhotovoltaicEnergyNormalizedArrayForPlot,
    getWeightCO2Format,
    hoursGeneralTooltipFormatter,
    hoursTooltipFormatter,
    yearGeneralTooltipFormatter, yearTooltipFormatter,
} from "utils/Formats";
import {getLanguage} from "utils/Language";
import {
    isSolarPhotovoltaicByCaller,
    getProgressBarClassByCaller,
    getColorProductionByCaller, isSolarThermalByCaller, isThermalByCaller,  isWaterByCaller
} from "utils/Types";
import i18next from "i18next";
import {
    getPlotSubtitle,
    getPlotTimeMenu,
    getPlotComponentByLoader
} from "../../utils/Plots/PlotsCommon";
import {
    getSolarPlotApexObject,
    getThermalPlotApexObject,
    getWaterPlot
} from "../../utils/Plots/PlotsApexCharts";
import config from "/app/src/config.js";
import {useSearchParams} from "react-router-dom";
import _ from "lodash";

const isMobileOnly = config.mbl_version;

//when request is success
const successRequest = (configuration, setConfiguration, response) => {
    //get default object for plot
    let defaultPlotData = { };
    //for solar plants...
    if(isSolarThermalByCaller(configuration) || isSolarPhotovoltaicByCaller(configuration)) {
        //get blank format
        defaultPlotData = getSolarPlotApexObject();
        //set data to plot
        defaultPlotData.options.xaxis.categories = response.data.labels;
        //set colors production for photovoltaic
        defaultPlotData.series[0].color = getColorProductionByCaller(configuration);
        defaultPlotData.options.stroke.colors[0] = getColorProductionByCaller(configuration);
        defaultPlotData.options.fill.colors[0] = getColorProductionByCaller(configuration);

        //set series data
        if (isSolarPhotovoltaicByCaller(configuration)) { //wh a production and co2
            defaultPlotData.series[0].data = getSolarPhotovoltaicEnergyNormalizedArrayForPlot(response.data.partials.production);
            defaultPlotData.series[2].data = getSolarPhotovoltaicEnergyNormalizedArrayForPlot(response.data.partials.savings_co2);
        } else if (isSolarThermalByCaller(configuration)) {
            defaultPlotData.series[0].data = getNormalizedArrayForPlot(response.data.partials.production);
            defaultPlotData.series[2].data = getNormalizedArrayForPlot(response.data.partials.savings_co2);
        }
        defaultPlotData.series[3].data = getNormalizedArrayForPlot(response.data.partials.savings_eco);
        //set texts
        defaultPlotData.series[0].name = i18next.t('plant.production');
        defaultPlotData.series[2].name = i18next.t('plant.equivalent_emissions');
        defaultPlotData.series[3].name = i18next.t('plant.savings');
        defaultPlotData.options.yaxis[0].seriesName = i18next.t('plant.production_estimated');
        defaultPlotData.options.yaxis[2].seriesName = i18next.t('plant.equivalent_emissions');
        defaultPlotData.options.yaxis[3].seriesName = i18next.t('plant.savings');
        defaultPlotData.options.yaxis[0].title.text = `${i18next.t('plant.production')} (kWh)`;
        defaultPlotData.options.yaxis[2].title.text = `${i18next.t('plant.equivalent_emissions')} (KgCO₂)`;
        defaultPlotData.options.yaxis[3].title.text = `${i18next.t('plant.savings')} (${getMoneyUnit()})`;
        //not show estimated if not year
        if (configuration.text === 'time.year') {
            defaultPlotData.series[1].data = getNormalizedArrayForPlot(response.data.partials.estimated);
            defaultPlotData.series[1].name = i18next.t('plant.production_estimated');
            defaultPlotData.options.yaxis[1].seriesName = i18next.t('plant.production_estimated');
            defaultPlotData.options.yaxis[1].title.text = `${i18next.t('plant.production_estimated')} (kWh)`;
        } else {
            defaultPlotData.series.splice(1,1);
            defaultPlotData.options.yaxis.splice(1,1);
        }

        //set title to plot
        if(response.data.totals.plants > 1) {
            defaultPlotData.options.title.text = i18next.t('plant.plot_title', {num: response.data.totals.plants});
        }

        defaultPlotData.options.chart.defaultLocale = getLanguage();
        //other analysis data
        defaultPlotData.plants = response.data.totals.plants;
        // - production absolute and related
        defaultPlotData.estimated = response.data.totals.estimated;
        //set series data
        // -- start: fix for solar pv: in wh to kwh
        defaultPlotData.production = response.data.totals.production;
        if (isSolarPhotovoltaicByCaller(configuration)) {
            defaultPlotData.production = defaultPlotData.production / 1000;
        }
        // -- end: fix
        defaultPlotData.rated_production = (defaultPlotData.production / defaultPlotData.estimated) * 100;
        defaultPlotData.rated_production = isNaN(defaultPlotData.rated_production) ? 0 : defaultPlotData.rated_production;
        // - co2 savings and related
        // -- start: fix for solar pv: in wh to kwh
        defaultPlotData.savings_co2 = response.data.totals.savings_co2;
        if (isSolarPhotovoltaicByCaller(configuration)) {
            defaultPlotData.savings_co2 = (defaultPlotData.savings_co2 / 1000);
        }
        defaultPlotData.savings_co2 = parseFloat(defaultPlotData.savings_co2.toFixed(2));
        // -- end: fix
        defaultPlotData.savings_co2_estimated = parseFloat(response.data.totals.savings_co2_estimated.toFixed(2));
        //FIX FOR SOME WRONG DATA TO SET %
        //defaultPlotData.rated_co2 = parseFloat((defaultPlotData.savings_co2 / defaultPlotData.savings_co2_estimated) * 100).toFixed(2);
        defaultPlotData.rated_co2 = defaultPlotData.rated_production;

        // - eco savings and related
        defaultPlotData.savings_eco = parseFloat(response.data.totals.savings_eco.toFixed(2));
        // -- start: fix for solar pv: in kwh to wh
        defaultPlotData.savings_eco_estimated = response.data.totals.savings_eco_estimated;
        if (isSolarPhotovoltaicByCaller(configuration)) {
            defaultPlotData.savings_eco_estimated = (defaultPlotData.savings_eco_estimated * 1000);
        }
        defaultPlotData.savings_eco_estimated = parseFloat(defaultPlotData.savings_eco_estimated.toFixed(2));
        // -- end: fix
        //FIX FOR SOME WRONG DATA TO SET %
        //defaultPlotData.rated_eco = ((defaultPlotData.savings_eco / defaultPlotData.savings_eco_estimated) * 100).toFixed(2);
        defaultPlotData.rated_eco = defaultPlotData.rated_production;
        defaultPlotData.loaded = true;

        // remove some data for mobile view
        if(isMobileOnly) {
            // set limit
            const limitIdx = configuration.text === 'time.year'?2:1
            // only show production
            defaultPlotData.series = defaultPlotData.series.filter(function(item, index) {
                return index < limitIdx
            }) // only show production
            defaultPlotData.options.yaxis = defaultPlotData.options.yaxis.filter(function(item, index) {
                return index < limitIdx
            })
            // label over yaxis
            defaultPlotData.options.yaxis[0].title.text = undefined;
            defaultPlotData.options.yaxis[0].title.rotate = 0;
            defaultPlotData.options.yaxis[0].title.offsetX = 20;
            defaultPlotData.options.yaxis[0].title.offsetY = -160;
            // disable download
            defaultPlotData.options.chart.toolbar.tools.download = false;
        }
    //for thermal plants...
    } else if(isThermalByCaller(configuration)) {
        //get blank format
        defaultPlotData = getThermalPlotApexObject();
        //set data to plot
        defaultPlotData.options.xaxis.categories = response.data.labels;
        //set colors production for photovoltaic
        defaultPlotData.series[0].color = getColorProductionByCaller(configuration);
        defaultPlotData.options.stroke.colors[0] = getColorProductionByCaller(configuration);
        defaultPlotData.options.fill.colors[0] = getColorProductionByCaller(configuration);
        //set series data
        defaultPlotData.series[0].data = response.data.partials.production;

        if(parseInt(configuration.filterZero) === 1) { // filter values with 0 value
            defaultPlotData.series[0].data = defaultPlotData.series[0].data.map((val, idx) => {
                return val !== 0 ? val : null;
            })
        }

        defaultPlotData.series[1].data = response.data.partials.min;
        defaultPlotData.series[2].data = response.data.partials.max;
        //set texts
        defaultPlotData.series[0].name = `${i18next.t('measured_temperature')}`;
        defaultPlotData.series[1].name = `${i18next.t('minimum_female')}`;
        defaultPlotData.series[2].name = `${i18next.t('maximum_female')}`;
        //set yaxis data
        defaultPlotData.options.yaxis.title.text = `${i18next.t('temperature')} (ºC)`;
        //defaultPlotData.options.yaxis.min = (response.data.totals.min > 0 && (response.data.totals.min - 3) > 0)?response.data.totals.min - 3:0;
        //defaultPlotData.options.yaxis.max = response.data.totals.max + 3;
        //set title to plot
        if(response.data.totals.plants > 1) {
            defaultPlotData.options.title.text = i18next.t('plant.plot_title', {num: response.data.totals.plants});
        }
        defaultPlotData.options.chart.defaultLocale = getLanguage();
        //other analysis data
        defaultPlotData.plants = response.data.totals.plants;
        defaultPlotData.loaded = true;
        // remove some data for mobile view
        if(isMobileOnly) {
            // label over yaxis
            defaultPlotData.options.yaxis.title.text = undefined;
            // disable download
            defaultPlotData.options.chart.toolbar.tools.download = false;
        }
    } else if(isWaterByCaller(configuration)) {
        //get blank format
        defaultPlotData = getWaterPlot();
        //set data to plot
        defaultPlotData.options.xaxis.categories = response.data.labels;
        //set colors production for photovoltaic
        defaultPlotData.series[0].color = getColorProductionByCaller(configuration);
        defaultPlotData.options.stroke.colors[0] = getColorProductionByCaller(configuration);
        defaultPlotData.options.fill.colors[0] = getColorProductionByCaller(configuration);
        //set series data
        defaultPlotData.series[0].data = response.data.partials.consumption;
        defaultPlotData.series[0].name = i18next.t('plant.consumption'); //name in legend
        defaultPlotData.options.yaxis[0].seriesName = i18next.t('plant.consumption'); //name for series (equal to other for fusion)
        defaultPlotData.options.yaxis[0].title.text = `${i18next.t('plant.consumption')} (${i18next.t('units.liters')})`; //name in y axis

        defaultPlotData.series[1].data = getNormalizedArrayForPlot(response.data.partials.cost);
        defaultPlotData.series[1].name = i18next.t('cost');
        defaultPlotData.options.yaxis[1].seriesName = i18next.t('cost');
        defaultPlotData.options.yaxis[1].title.text = `${i18next.t('cost')} (${getMoneyUnit()})`;

        if(configuration.plantId && configuration.timeLine.text === 'time.today') {
            defaultPlotData.series[2].data = Array(response.data.partials.consumption.length).fill(response.data.totals.max);
            defaultPlotData.series[2].name = i18next.t('limit');
            defaultPlotData.options.yaxis[2].seriesName = i18next.t('plant.consumption');
            defaultPlotData.options.yaxis[2].title.text = `${i18next.t('limit')} (${i18next.t('units.liters')})`;
        } else {
            defaultPlotData.series.splice(2,1);
            defaultPlotData.options.yaxis.splice(2,1);
        }

        //set title to plot
        if(response.data.totals.plants > 1) {
            defaultPlotData.options.title.text = i18next.t('plant.plot_title', {num: response.data.totals.plants});
        }

        defaultPlotData.options.chart.defaultLocale = getLanguage();
        //other analysis data
        defaultPlotData.plants = response.data.totals.plants;
        defaultPlotData.loaded = true;
        // remove some data for mobile view
        if(isMobileOnly) {
            // only show production
            defaultPlotData.series = defaultPlotData.series.filter(function(item, index) {
                return index < 1
            }) // only show production
            defaultPlotData.options.yaxis = defaultPlotData.options.yaxis.filter(function(item, index) {
                return index < 1
            })
            // label over yaxis
            defaultPlotData.options.yaxis[0].title.text = undefined;
            // disable download
            defaultPlotData.options.chart.toolbar.tools.download = false;
        }
    }

    defaultPlotData.options.xaxis.tooltip.prefix = geTimeByTimeLine(configuration.timeLine);

    if (configuration.text === 'time.today') {
        defaultPlotData.options.xaxis.categories = apexChartLabel(response.data.labels, 5, 0);
        defaultPlotData.options.xaxis.labels.showDuplicates =  true;
        defaultPlotData.options.xaxis.labels.hideOverlappingLabels =  false;
        defaultPlotData.options.xaxis.labels.rotate =  0;
        defaultPlotData.options.xaxis.min = 1;
        defaultPlotData.options.xaxis.max = 24;
        defaultPlotData.options.xaxis.labels.trim = false;
        defaultPlotData.options.xaxis.tooltip.formatter = hoursTooltipFormatter;
        defaultPlotData.options.tooltip.x.formatter = hoursGeneralTooltipFormatter;
    } else if(configuration.text === 'time.month') {
        if(response.data.labels.length === 29) {
            defaultPlotData.options.xaxis.categories = apexChartLabel(response.data.labels, 2, 0);
        } else if(response.data.labels.length === 31 || response.data.labels.length === 28) {
            defaultPlotData.options.xaxis.categories = apexChartLabel(response.data.labels, 3, 0);
        } else if(response.data.labels.length === 30) {
            defaultPlotData.options.xaxis.categories = apexChartLabel(response.data.labels, 4, 0);
        }
        defaultPlotData.options.xaxis.min = 1;
        defaultPlotData.options.xaxis.max = response.data.labels.length;
        defaultPlotData.options.xaxis.tooltip.formatter = dayTooltipFormatter;
        defaultPlotData.options.tooltip.x.formatter = dayGeneralTooltipFormatter;
    } else if(configuration.text === 'time.year') {
        defaultPlotData.options.xaxis.min = 1;
        defaultPlotData.options.xaxis.max = 12;
        defaultPlotData.options.xaxis.categories = apexChartLabel(response.data.labels, 2, 0);
        defaultPlotData.options.xaxis.tooltip.formatter = yearTooltipFormatter;
        defaultPlotData.options.chart.originalLabels = response.data.labels;
        defaultPlotData.options.tooltip.x.formatter = yearGeneralTooltipFormatter;
    }

    //set to re-render view
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: false}, plot: defaultPlotData })
} //when request fails
const failRequest = (configuration, setConfiguration, response) => {
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: true}, plot: configuration.plot })
}

export function CardPlotSummary({...props}) {
    const chartRef = useRef();
    const [searchParams] = useSearchParams();
    // get query params for set filter or not for 0 values
    const queryParams = Object.fromEntries([...searchParams]);
    // use translation
    const {t} = useTranslation();
    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        /*text: (isThermalByCaller(props) || isWaterByCaller(props))?'time.today':'time.year',
        url: (isThermalByCaller(props) || isWaterByCaller(props))?'time.today':`time.year?year=${moment().format('YYYY')}`,*/
        text: 'time.today',
        url: 'time.today',
        plot: getSolarPlotApexObject(),
        loaders: {plot: false, request: true, error: false},
        //timeLine: (isThermalByCaller(props) || isWaterByCaller(props))?{text: 'time.today', clicks: 0}:{text: 'time.year', clicks: 0},
        timeLine: {text: 'time.today', clicks: 0},
        isLoading: true,
        filterZero: _.get(queryParams, 'filterzero', 1)
    });

    //event to first load plot...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if (!configuration.loaders.request) {
            return
        }
        //set energy request
        const setData = (configuration, callback = () => {
        }) => {
            getPlotData({...configuration, plantId: configuration.plantId, interval: configuration.url}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                successRequest(configuration, setConfiguration, response, callback);
            }, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                failRequest(configuration, setConfiguration, response);
            });
        } //call to load data
        setData(configuration);
        //prevent crash with async function
        return () => {
            isMounted = false;
        };
    }, [configuration])

    const translateEffect = t('plant.production_estimated');
    //rerender the plot if language is changed...
    useEffect(() => {
        //update configuration
        setConfiguration(configuration => {
            //only if plot is loaded
            if (!configuration.loaders.plot || configuration.loaders.error || configuration.loaders.request) {
                return configuration;
            } //rerender
            return {...configuration, loaders: {plot: false, request: true, error: false}}
        });
    }, [translateEffect]);

    //event to fix series
    useEffect(() => {
        //TypeError: Cannot read properties of null (reading 'getAttribute')
        //only if plot is loaded
        if (!configuration.loaders.plot || configuration.loaders.error || configuration.loaders.request) {
            return;
        }

        try {
            chartRef?.current?.chart.hideSeries(i18next.t('plant.equivalent_emissions'));
        } catch (error) {
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
        }

        try {
            chartRef?.current?.chart.hideSeries(i18next.t('limit'));
        } catch (error) {
            // expected output: ReferenceError: nonExistentFunction is not defined
            // Note - error messages will vary depending on browser
        }
    }, [configuration.loaders, configuration.caller]);

    //set default title
    let title; let units;
    if(isSolarThermalByCaller(configuration)) {
        title = t('analytics.solar_thermal_production_analysis');
        units = 'kWh';
    } else if(isSolarPhotovoltaicByCaller(configuration)) {
        title = t('analytics.solar_photovoltaic_production_analysis');
        units = 'kWh';
    } else if (isThermalByCaller(configuration)) {
        title = t('analytics.thermal_production_analysis');
        units = 'ºC'
    } else if (isWaterByCaller(configuration)) {
        title = t('analytics.water_consumption_analysis');
        units = t('units.liters')
    }

    //set char component by default and styles
    const charComponent = <ReactApexChart ref={chartRef} options={configuration.plot.options} series={configuration.plot.series} type="line" height={isMobileOnly?250:350}/>;
    const isSecondPlot = (!isThermalByCaller(configuration) && !isWaterByCaller(configuration));

    return (
        <div className={`card card-${configuration.caller}-plot ${isMobileOnly && "card-mbl"}`}>
            { /* header */ }
            <CardSimpleHeader subtitle={ getPlotSubtitle(configuration, setConfiguration) } title={title}
                              rightComponent={ getPlotTimeMenu(configuration, setConfiguration) }
            />
            { /* horizontal line between header and plot */ }
            {!isMobileOnly && <hr style={{marginTop: 0}}/> }
            { /* body */}
            <div className={`card-body pb-2 pt-0 ${isMobileOnly ? "card-body-mbl pl-0-mbl pr-0-mbl pb-0-mbl pt-2" : ""}`}>
                { /* first plot */ }
                <div className={`row ${isMobileOnly ? "row-mbl":"pb-50 pt-50"}`}>
                    { /* get plot units for mobile */}
                    { isMobileOnly && <span style={{fontSize: '12px', fontFamily: 'arial'}}>{units}</span> }
                    { /* render plot */}
                    <div className={`col-12 ${isMobileOnly && "col-mbl"}`} style={{minWidth: '0px'}}>
                        { getPlotComponentByLoader({ ...configuration, loaderHeight: 336 }, charComponent) }
                    </div>
                </div>
                { /* second plot */ }
                { isSecondPlot && <> <hr style={{marginTop: 0}}/>
                    <div className={`row avg-sessions pt-50 ${isMobileOnly && "row-mbl p-1"}`}>
                        <div className="col-12 mb-2">
                            <div className={"d-flex justify-content-between mb-0"}>
                                { /* title production vs estimated */ }
                                <div><p className={"mb-0"} style={{fontWeight: 500}}>{ t('plant.production_total') } vs. { t('estimated').toLowerCase() }</p></div>
                                { /* achieved production summary */ }
                                <div><p className={"mb-0"}>{ getPlotComponentByLoader(configuration, <>η: { configuration.plot.rated_production.toFixed(0)}%</>) } </p></div>
                            </div>
                            { /* value */ }
                            <p className="mb-50">{ getPlotComponentByLoader(configuration, <>{getEnergyFormat(configuration.plot.production * 1000)} vs. {getEnergyFormat(configuration.plot.estimated * 1000)}</>) }</p>
                            { /* progressbar */ }
                            { getPlotComponentByLoader(configuration, <Progress className={getProgressBarClassByCaller(configuration)} value={configuration.plot.rated_production} style={{height: "10px"}}/>) }
                        </div>
                        { !isMobileOnly && <>
                            <div className="col-12 mb-2">
                                { /* title emissions vs estimated */ }
                                <p className="mb-0" style={{fontWeight: 500}}>{ t('plant.equivalent_emissions_total') } vs. {t('estimated_plural_female').toLowerCase()}</p>
                                { /* value */ }
                                <p className="mb-50">{ getPlotComponentByLoader(configuration, <>{getWeightCO2Format(configuration.plot.savings_co2)} vs. {getWeightCO2Format(configuration.plot.savings_co2_estimated)}</>) }</p>
                                { /* progressbar */ }
                                { getPlotComponentByLoader(configuration, <Progress className='progress-bar-success' value={configuration.plot.rated_co2} style={{height: "10px"}}/>) }
                            </div>
                            <div className="col-12">
                                { /* title savings vs estimated */ }
                                <p className="mb-0" style={{fontWeight: 500}}>{ t('plant.savings_total') } vs. {t('estimated_plural_male').toLowerCase()}</p>
                                { /* value */ }
                                <p className="mb-50">{ getPlotComponentByLoader(configuration, <>{getEcoSavings(configuration.plot.savings_eco)} vs. {getEcoSavings(configuration.plot.savings_eco_estimated)}</>) }</p>
                                { /* progressbar */ }
                                { getPlotComponentByLoader(configuration, <Progress className='progress-bar-secondary' value={configuration.plot.rated_eco} style={{height: "10px"}}/>) }
                            </div>
                        </>}
                    </div>
                </>}
            </div>
        </div>
    )
}