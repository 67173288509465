import i18next from "i18next";
import {getEcoSavings} from "../Formats";
import {
    getOverproductionTextByCaller,
    getUnderproductionTextByCaller,
    isThermalByCaller,
    isWaterByCaller
} from "../Types";

//plots defaults configs
export function getSolarPlotApexObject() {
    return {
        plants: 0,
        production: 0,
        estimated: 0,
        rated_production: 0,
        savings_co2: 0,
        savings_co2_estimated: 0,
        rated_co2: 0,
        savings_eco: 0,
        savings_eco_estimated: 0,
        rated_eco: 0,
        series: [{
            name: '',
            type: 'column',
            data: [],
            color: '#fac108'
        },{
            name: "",
            type: 'column',
            data: [],
            color: '#d9d9d9'
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#46c76f',
            show: false
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#82868b'
        }],
        options: {
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8,
                    }
                }
            },
            legend: {
                showForNullSeries: true,
            },
            title: {
                text: undefined,
                align: 'center',
                style: {
                    fontSize:  '14px',
                    fontWeight:  undefined,
                    fontFamily:  "'Montserrat',Helvetica,Arial,serif",
                    color:  '#263238'
                }
            },
            chart: {
                animations: {
                    enabled: false,
                    animateGradually: {
                        enabled: false
                    },
                    dynamicAnimation: {
                        enabled: false
                    }
                },
                locales: [
                    require("apexcharts/dist/locales/es.json"),
                    require("apexcharts/dist/locales/ca.json"),
                    require("apexcharts/dist/locales/en.json")
                ],
                defaultLocale: 'es',
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                },
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                width: [2, 2, 2, 2],
                colors: ['#fac108', '#d9d9d9', '#46c76f', '#82868b'],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '90%'
                }
            },
            fill: {
                opacity: [0.50, 0.50, 1, 1],
                colors: ['#fac108', '#d9d9d9', '#46c76f', '#82868b'],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            }, //x-axis labels
            //labels: [],
            markers: { size: 0 },
            xaxis: {
                labels: {
                    show: true,
                },
                tooltip: {
                    enabled: true
                },
                categories: []
            },
            yaxis: [{
                seriesName: '',
                decimalsInFloat: 2,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#d9d9d9' },
                //labels: { style: { colors: '#FF9F43' } },
                title: { text: "Columns"/*, style: { color: '#FF9F43' }*/ }
            },{
                seriesName: '',
                decimalsInFloat: 2,
                show: false,
                title: { text: "Columns" }
            },{
                seriesName: '',
                decimalsInFloat: 2,
                opposite: true,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#46c76f' },
                title: { text: "Line" }
            },{
                seriesName: '',
                decimalsInFloat: 2,
                opposite: true,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#82868b' },
                title: { text: "Line" }
            }],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        if (typeof value !== "undefined" && value !== null) {
                            if(seriesIndex === 0 || seriesIndex === 1) {
                                return `${value.toFixed(2)} kWh`;
                            } else if(seriesIndex === 2) {
                                return `${value.toFixed(2)} KgCO₂`;
                            } else {
                                return getEcoSavings(value);
                            }
                        }
                        return i18next.t('no_data');
                    }
                },
                x: {
                    show: true
                }
            }
        }
    };
}

export function getThermalPlotApexObject() {
    return {
        plants: 0,
        series: [{
            name: '',
            type: 'line',
            data: [],
            color: '#fac108'
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#676767'
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#3f3f3f'
        }],
        options: {
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8,
                    }
                }
            },
            legend: {
                showForNullSeries: true,
            },
            title: {
                text: undefined,
                align: 'center',
                style: {
                    fontSize:  '14px',
                    fontWeight:  undefined,
                    fontFamily:  "'Montserrat',Helvetica,Arial,serif",
                    color:  '#3f3f3f'
                }
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0],
                formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                    //get value of current series
                    const value = w.config.series[seriesIndex].data[dataPointIndex];
                    if(value === null) {
                        return '';
                        //if series is not a temperature...
                    } else if(seriesIndex !== 0) {
                        return w.config.series[seriesIndex].data[dataPointIndex];
                    }

                    //val less than min
                    if(value < w.config.series[1].data[dataPointIndex]) {
                        return val;
                        //val less equal min
                    } else if(value === w.config.series[1].data[dataPointIndex]) {
                        return val;
                        //val greater than max
                    } else if(value > w.config.series[2].data[dataPointIndex]) {
                        return val;
                        //val less equal than max
                    } else if(value === w.config.series[2].data[dataPointIndex]) {
                        return val;
                    }
                },
                style: {
                    fontSize: '10px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: [function(opts) {
                        const val = opts.series[0][opts.dataPointIndex];
                        if(val === null) { return null; }
                        //val less than min
                        if(val < opts.series[1][opts.dataPointIndex]) {
                            return '#fc153f';
                            //val less equal min
                        } else if(val === opts.series[1][opts.dataPointIndex]) {
                            return '#FF9318FF';
                            //val greater than max
                        } else if(val > opts.series[2][opts.dataPointIndex]) {
                            return '#fc153f';
                            //val less equal than max
                        } else if(val === opts.series[2][opts.dataPointIndex]) {
                            return '#FF9318FF';
                        } else {
                            return '#7ed949'
                        }
                    }]
                }
            },
            chart: {
                animations: {
                    enabled: false,
                    animateGradually: {
                        enabled: false
                    },
                    dynamicAnimation: {
                        enabled: false
                    }
                },
                collapsedSeries: [0],
                collapsedSeriesIndices: [0],
                locales: [
                    require("apexcharts/dist/locales/es.json"),
                    require("apexcharts/dist/locales/ca.json"),
                    require("apexcharts/dist/locales/en.json")
                ],
                defaultLocale: 'ca',
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                },
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                width: [3, 2, 2],
                colors: ['#fac108', '#676767', '#3f3f3f'],
                curve: 'smooth',
                dashArray: [0, 8, 8]
            },
            fill: {
                opacity: [1, 0.8, 0.8],
                colors: ['#fac108', '#676767', '#3f3f3f'],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100]
                }
            }, //x-axis labels
            //labels: [],
            markers: { size: 0 },
            xaxis: {
                labels: {
                    show: true,
                },
                tooltip: {
                    enabled: true
                },
                categories: []
            },
            yaxis: {
                title: {
                    text: 'Temperature'
                }/*,
                min: 5,
                max: 40*/
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        if (typeof value !== "undefined" && value !== null) {
                            return `${value}ºC`;
                        }
                        return i18next.t('no_data');
                    }
                },
                x: {
                    show: true
                }
            }
        }
    };
}


export function getWaterPlot() {
    return {
        plants: 0,
        series: [{
            name: '',
            type: 'column',
            width: '50%',
            data: [],
            color: '#d0f3ff'
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#464646'
        },{
            name: "",
            type: 'line',
            data: [],
            color: '#c74651'
        }],
        options: {
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8,
                    }
                }
            },
            legend: {
                showForNullSeries: true,
            },
            title: {
                text: undefined,
                align: 'center',
                style: {
                    fontSize:  '14px',
                    fontWeight:  undefined,
                    fontFamily:  "'Montserrat',Helvetica,Arial,serif",
                    color:  '#263238'
                }
            },
            chart: {
                animations: {
                    enabled: false,
                    animateGradually: {
                        enabled: false
                    },
                    dynamicAnimation: {
                        enabled: false
                    }
                },
                locales: [
                    require("apexcharts/dist/locales/es.json"),
                    require("apexcharts/dist/locales/ca.json"),
                    require("apexcharts/dist/locales/en.json")
                ],
                defaultLocale: 'ca',
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                },
                height: 350,
                type: 'line',
                stacked: false,
            },
            stroke: {
                width: [2, 2, 2],
                colors: ['#0e7b93', '#464646', '#c74651'],
                curve: 'smooth',
                dashArray: [0, 0, 8]
            },
            plotOptions: {
                bar: {
                    columnWidth: '60%'
                }
            },
            fill: {
                opacity: [0.50, 0.80, 1],
                colors: ['#d0f3ff', '#464646', '#c74651'],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100]
                }
            }, //x-axis labels
            //labels: [],
            markers: { size: 0 },
            xaxis: {
                labels: {
                    show: true,
                },
                tooltip: {
                    enabled: true
                },
                categories: []
            },
            yaxis: [{
                seriesName: '',
                decimalsInFloat: 0,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#d9d9d9' },
                //labels: { style: { colors: '#FF9F43' } },
                title: { text: "Columns"/*, style: { color: '#FF9F43' }*/ }
            },{
                seriesName: '',
                decimalsInFloat: 0,
                opposite: true,
                show: true,
                title: { text: "Line" },
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#464646' },
            },{
                seriesName: '',
                show: false,
                decimalsInFloat: 0,
                opposite: true,
                axisTicks: { show: true },
                axisBorder: { show: true, color: '#c74651' },
                title: { text: "Line" }
            }],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        if (typeof value !== "undefined" && value !== null) {
                            if(seriesIndex === 0) {
                                return `${value.toFixed(0)} ${i18next.t('units.liters')}`;
                            } else if(seriesIndex === 1) {
                                return getEcoSavings(value);
                            } else if(seriesIndex === 2) {
                                return `${value.toFixed(0)} ${i18next.t('units.liters')}`;
                            }
                            return value;
                        }
                        return i18next.t('no_data');
                    }
                },
                x: {
                    show: true
                }
            }
        }
    };
}

export function getEventsPlotApexObject(cfg) {
    return {
        series: [{
            name:i18next.t(getOverproductionTextByCaller(cfg)),
            data: [44, 55]
        }, {
            name: i18next.t(getUnderproductionTextByCaller(cfg)),
            data: [53, 32]
        }, {
            name: i18next.t('events.no_reading'),
            data: [12, 17]
        }, {
            name: i18next.t('events.ok'),
            data: [9, 7]
        }],
        options: {
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.8,
                    }
                }
            },
            chart: {
                raw: cfg.rawData,
                width: '100%',
                type: 'bar',
                stacked: true,
                stackType: '100%',
                animations: {
                    enabled: false,
                    animateGradually: {
                        enabled: false
                    },
                    dynamicAnimation: {
                        enabled: false
                    }
                },
                locales: [
                    require("apexcharts/dist/locales/es.json"),
                    require("apexcharts/dist/locales/ca.json"),
                    require("apexcharts/dist/locales/en.json")
                ],
                defaultLocale: 'ca',
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 1,
                colors: [
                    '#00cfe8',
                    '#887ef3',
                    '#ea5455',
                    '#46c76f'
                ]
            }/*,
            title: {
                text: '100% Stacked Bar'
            }*/,
            xaxis: {
                categories: [i18next.t('plant.daily_status_extended_female_pl'), i18next.t('plant.hourly_status_extended_female_pl')],
            },
            yaxis: (function getLabelsYName(){
                if(isThermalByCaller(cfg) || isWaterByCaller(cfg)) {
                    return { show: false };
                }
                return { show: true };
            }()),
            tooltip: {
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    const data = w.config.chart.raw;
                    const val = series[seriesIndex][dataPointIndex];
                    let percent = ((val / data.total) * 100).toFixed(2);
                    //{underproduction: 0, overproduction: 1, no_reading: 0, ok: 11, total: 12}
                    return `<b>${w.config.series[seriesIndex].name}</b>${percent}% (${val}/${data.total} ${i18next.t('plants').toLowerCase()})`;
                }
            },
            fill: {
                opacity: [1,1,1,1],
                colors: [
                    '#e0f9fc',
                    '#eeedfd',
                    '#fceaea',
                    '#e5f8ed'
                ]
            },
            legend: {
                show: false,
                position: 'top',
                horizontalAlign: 'center',
                markers: {
                    strokeColor: '#fff',
                    fillColors: ['#46cfe8', '#7367f0', '#ea5455', '#46c76f']
                }
            }
        }
    };
}