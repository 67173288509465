import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import {CardDotWithDataVertical} from "components/Cards/CardDotWithData";
import {getTimeByTextLink} from "utils/Time";
import MenuTime from "components/Menu/TimeMenu";
import Skeleton from "react-loading-skeleton";
import {getAnalysisEcoSavings} from "utils/Requests/Analysis";
import {getEcoSavings} from "utils/Formats";
import {RiHandCoinLine} from "react-icons/ri";
import {isWaterByCaller} from "../../utils/Types";

const loaderSkeleton = <Skeleton width={100}/>

const getTitle = (t, configuration) => {
    if(isWaterByCaller(configuration)) {
        return t('cost');
    } //solar
    return t('plant.savings');
}

export function CardEcoSavings({...props}) {
    const {t} = useTranslation();
    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.today',
        url: 'time.today',
        dates: null,
        value: loaderSkeleton,
        defaultValue: loaderSkeleton,
        isLoading: true
    });

    //first load
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if(!configuration.isLoading) { return }
        //set energy request
        const setECOSavings = (endpoint) => {
            //plant/analysis/solar/production/text
            getAnalysisEcoSavings({...configuration, interval: endpoint}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                setConfiguration({...configuration, url: endpoint, isLoading: false, value: getEcoSavings(response.total)});
            }, () => {
                //prevent crash with async function
                if (!isMounted) return null;
                setConfiguration({...configuration, isLoading: false, value: 'Error...'});
            });
        } //call to load data
        setECOSavings(configuration.url);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    return (
        <div className="card card-statistics">
            <CardSimpleHeader { ...configuration} title={ getTitle(t, configuration) }
                              rightComponent={<MenuTime configuration={configuration} handler={setConfiguration} />}
            />
            <div className="card-body statistics-body">
                <CardDotWithDataVertical {...configuration} avatarClass={"avatar-lg"} colorClass='bg-light-secondary'
                                         icon={<RiHandCoinLine width={30} height={30} />}
                                         text={getTimeByTextLink(configuration)} number={configuration.value} />
            </div>
        </div>
    );
}