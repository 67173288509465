import React from "react";
import {useTranslation} from "react-i18next";
import {Lock} from "react-feather";
import SettingsPasswordInput from "../Forms/Inputs/settings/SettingsPasswordInput";
import {useForm} from "react-hook-form";
import {changeUserPassword} from "utils/Requests/Auth";
import {getPasswordValue} from "utils/Forms";
import HelpBlockInput from "../Forms/HelpBlockInput";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function CardChangePassword({...props}) {
    const {t} = useTranslation();
    //call to use hook
    const methods = useForm({ mode: 'onSubmit' }); //get used methods
    const { handleSubmit, reset, formState: { isSubmitting, isDirty } } = methods;
    //call on submit
    const onSubmit = async (data) => {
        await changeUserPassword({
            user_password: getPasswordValue(data.user_password),
            user_password_new: getPasswordValue(data.user_password_new)
        }, () => {
            reset();
        });
    }

    return (
        <>
            <div className={`card ${isMobileOnly && "card-mbl"}`}>
                <div className={`card-header ${!isMobileOnly && "border-bottom"}`}>
                    <h4 className="card-title d-flex flex-row">
                        <Lock size={20}/>
                        <span style={{marginLeft: "5px"}}>{t("account.change_password")}</span>
                    </h4>
                </div>
                <div className={`card-body ${isMobileOnly ? "card-body-mbl pt-20-mbl" : "py-2 my-25"}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12">
                            <SettingsPasswordInput isLoading={props.isLoading} methods={methods}/>
                        </div>
                        <div className="col-12 mt-1">
                            <SettingsPasswordInput isLoading={props.isLoading} label={t("new_password")}
                                                   methods={methods}
                                                   name={"user_password_new"}
                                                   helpBlock={<HelpBlockInput text={t('signup.password_helper')}/>}/>
                        </div>
                        <div className="mt-1">
                            <button type="submit" className="btn btn-primary" disabled={!isDirty || isSubmitting}>
                                {t("save")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>)
}