import React from "react";
// import {LanguagesHeader} from "components/Langauge";
import BrandSimple from "components/Titles/BrandSimple";
import {getBackPage} from "utils/Pages/Back";

export default function NavbarSimple({...props}) {
    //get current path to back
    const path = getBackPage();

    return (
        <>
            <nav className="header-navbar navbar align-items-center navbar-shadow navbar navbar-expand-lg mb-3 navbar-light"
                 style={{borderRadius: "0.428rem", padding: ".8rem 15px", }}>
                <div className="navbar-container d-flex justify-content-between align-items-center">
                    <BrandSimple to={ path } />
                    {/*<LanguagesHeader />*/}
                </div>
            </nav>
        </>
    )
}