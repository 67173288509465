import React, {useState} from "react";
import { Eye, EyeOff } from 'react-feather';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import HelpBlockInput from "components/Forms/HelpBlockInput";

export default function PasswordInput(props) {
    const { t } = useTranslation();
    const [passwordShown, setPasswordShown] = useState(false);

    //toggle to show or not show a password
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    }

    //set default label
    let label = t('password');
    //if caller is a forgot form
    if(props.forgot) {
        label = t('new_password');
    }

    return (
        <div className="mb-1">
            <div className="d-flex justify-content-between">
                <label className="form-label" htmlFor="user_password">
                    { label }
                </label>
                { /* if caller is login, show forgot password link */}
                { props.login &&
                    <Link to="/forgot">
                        <span>{ t('login.forgot_password') }</span>
                    </Link>
                }
            </div>
            <div className="input-group input-group-merge form-password-toggle">
                <input { ...props } type={ passwordShown ? "text" : "password" } style={{  }} className="form-control form-control-merge" id="password" name="user_password" minLength="8" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="user_password" required/>
                <span className="input-group-text cursor-pointer" style={{ borderLeft: "2px solid #D8D6DE", borderRadius: "0 0.357rem 0.357rem 0", backgroundColor: "rgb(235 233 233 / 10%)" }} onClick={togglePassword}>
                    { passwordShown ? <EyeOff /> : <Eye /> }
                </span>
                <div className="invalid-feedback"> { t('validations.password') } </div>
            </div>
            { /* if caller is not login, show password helper */}
            { !props.login && <div><HelpBlockInput text={t('signup.password_helper')} /></div> }
        </div>
    )
}
