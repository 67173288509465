import React from "react";
import {CardHeaderNoIcon} from "components/Cards/CardHeaderWithIcon";
import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {getMonthName, getTimeDateTextFull} from "utils/Time";
import { Table } from 'reactstrap';
import {
    getAuxEnergyTechTranslationKey,
    getAuxEnergyTranslationKey,
    getIconByPlantStatus, isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller
} from "utils/Types";
import {
    BsThermometerHalf,
    BsThermometerSnow,
    BsThermometerSun,
} from "react-icons/bs";
import {
    RiContrastDrop2Fill,
    RiContrastDrop2Line
} from "react-icons/ri";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

// descriptions
export function SolarThermalPlantDesc({...props}) {
    const {t} = useTranslation();

    return (<>
        <div>
            {props.data.plant_name??<Skeleton />}
        </div>
        <div>
            {props.data.plant_address??<Skeleton />}
        </div>
        <div>
            {props.data.plant_town_name?`${props.data.plant_town_name} (${props.data.plant_province_name})`:<Skeleton />}
        </div>
        <hr />
        <div style={{marginBottom: '5px', color: '#323232'}}>
            {t('plant.aux_energy')}:
        </div>
        <div>
            {props.data.plant_aux_anergy_name?t(getAuxEnergyTranslationKey(props.data.plant_aux_energy_id)):<Skeleton />}
        </div>
        <div style={{fontSize: '13px'}}>
            {props.data.plant_aux_energy_price?`${t('price')}: ${props.data.plant_aux_energy_price}€/kWh.`:<Skeleton />}
        </div>
        <div style={{marginBottom: '5px', fontSize: '13px'}}>
            {props.data.plant_aux_energy_emissions?`${t('emissions')}: ${props.data.plant_aux_energy_emissions}kgC0₂/kWh.`:<Skeleton />}
        </div>
        <div>
            {props.data.plant_aux_energy_client_technology_name?t(getAuxEnergyTechTranslationKey(props.data.plant_aux_energy_client_technology_id)):<Skeleton />}
        </div>
        <div style={{fontSize: '13px'}}>
            {props.data.plant_aux_energy_client_technology_efficiency?`${t('efficiency')}: ${props.data.plant_aux_energy_client_technology_efficiency}`:<Skeleton />}
        </div>
        <hr />
        <div style={{marginBottom: '5px', color: '#323232'}}>
            {t('plant.collector_field')}:
        </div>
        <div>
            {props.data.plant_collector_area?`${props.data.plant_collector_area}m²`:<Skeleton />}
        </div>
        <hr />
        { !props.data.plant_daily_alert && props.data.plant_daily_alert !== 0 && <Skeleton /> }
        { (props.data.plant_daily_alert || props.data.plant_daily_alert === 0) &&
            <>
                <div style={{marginBottom: '8px', color: '#323232'}}>{t('alerts')}</div>
                <div className={'mt-0 d-flex justify-content-start'}>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_daily_alert/*, t('plant.daily_status_extended')*/)}
                            &nbsp;
                        { t('plant.daily_status_extended_female') }
                    </div>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_hourly_alert/*, t('plant.hourly_status_extended')*/)}
                        &nbsp;
                        {t('plant.hourly_status_extended_female')}
                    </div>
                </div>
            </>
        }
    </>)
}

export function SolarPhotovoltaicPlantDesc({...props}) {
    const {t} = useTranslation();

    return (<>
        <div>{props.data.plant_name??<Skeleton />}</div>
        <div>{props.data.plant_address??<Skeleton />}</div>
        <div>{props.data.plant_town_name?`${props.data.plant_town_name} (${props.data.plant_province_name})`:<Skeleton />}</div>
        <hr />
        <div style={{marginBottom: '5px', color: '#323232'}}>
            {t('plant.aux_energy')}:
        </div>
        <div>
            {t('aux_energy.electricity')}
        </div>
        <div style={{fontSize: '13px'}}>
            {props.data.plant_aux_energy_price?`${t('price')}: ${props.data.plant_aux_energy_price * 1000}€/kWh.`:<Skeleton />}
        </div>
        <div style={{marginBottom: '5px', fontSize: '13px'}}>
            {props.data.plant_aux_energy_emissions?`${t('emissions')}: ${props.data.plant_aux_energy_emissions}kgC0₂/kWh.`:<Skeleton />}
        </div>
        <hr />
        <div style={{marginBottom: '5px', color: '#323232'}}>
            {t('plant.collector_field')}:
        </div>
        <div>
            {props.data.plant_collector_area?`${t('area')}: ${props.data.plant_collector_area}m²`:<Skeleton />}
        </div>
        <div>
            {props.data.plant_power?`${t('power')}: ${props.data.plant_power}kWp`:<Skeleton />}
        </div>
        <hr />
        { !props.data.plant_daily_alert && props.data.plant_daily_alert !== 0 && <Skeleton /> }
        { (props.data.plant_daily_alert || props.data.plant_daily_alert === 0) &&
            <>
                <div style={{marginBottom: '8px', color: '#323232'}}>{t('alerts')}</div>
                <div className={'mt-0 d-flex justify-content-start'}>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_daily_alert/*, t('plant.daily_status_extended')*/)}
                        &nbsp;
                        { t('plant.daily_status_extended_female') }
                    </div>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_hourly_alert/*, t('plant.hourly_status_extended')*/)}
                        &nbsp;
                        {t('plant.hourly_status_extended_female')}
                    </div>
                </div>
            </>
        }
    </>)
}

export function ThermalPlantDesc({...props}) {
    // const {t} = useTranslation();

    return (<>
        <div>{props.data.plant_name??<Skeleton />}</div>
        <div>{props.data.plant_address??<Skeleton />}</div>
        <div>{props.data.plant_town_name?`${props.data.plant_town_name} (${props.data.plant_province_name})`:<Skeleton />}</div>
        <hr />
        { !props.data.plant_hourly_alert && props.data.plant_hourly_alert !== 0 && <Skeleton /> }
        { (props.data.plant_hourly_alert || props.data.plant_hourly_alert === 0) &&
            <div className={'mt-1 d-flex justify-content-start'}>
                <div className={"me-1"}>
                    { getIconByPlantStatus(props, props.data.plant_hourly_alert /*, t('plant.hourly_status_extended')*/)}
                </div>
            </div>
        }
    </>)
}

export function WaterPlantDesc({...props}) {
    const {t} = useTranslation();

    return (<>
        <div>{props.data.plant_name??<Skeleton />}</div>
        <div>{props.data.plant_address??<Skeleton />}</div>
        <div>{props.data.plant_town_name?`${props.data.plant_town_name} (${props.data.plant_province_name})`:<Skeleton />}</div>
        <hr />
        <div style={{marginBottom: '5px', color: '#323232'}}>
            {t('plant.consumption')}:
        </div>
        <div>
            {props.data.plant_max_production?`${t('plant.consumption_max')}: ${props.data.plant_max_production}L/h`:<Skeleton />}
        </div>
        <div>
            {props.data.plant_provider_price?`${t('price')}: ${props.data.plant_provider_price}€/m3`:<Skeleton />}
        </div>
        <hr />
        { !props.data.plant_daily_alert && props.data.plant_daily_alert !== 0 && <Skeleton /> }
        { (props.data.plant_daily_alert || props.data.plant_daily_alert === 0) &&
            <>
                <div style={{marginBottom: '8px', color: '#323232'}}>{t('alerts')}</div>
                <div className={'mt-0 d-flex justify-content-start'}>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_daily_alert/*, t('plant.daily_status_extended')*/)}
                        &nbsp;
                        { t('plant.daily_status_extended_female') }
                    </div>
                    <div className={"me-1 d-flex align-items-center"}>
                        {getIconByPlantStatus(props, props.data.plant_hourly_alert/*, t('plant.hourly_status_extended')*/)}
                        &nbsp;
                        {t('plant.hourly_status_extended_female')}
                    </div>
                </div>
            </>
        }
    </>)
}

// settings
export function ProjectedDataMonthly({...props}) {
    const {t} = useTranslation();

    return (<>
        <Table size="sm" responsive>
            <thead>
            <tr>
                <th>{t('month')}</th>
                <th>{
                    isWaterByCaller(props)
                        ?t('units.liters')
                        :<span style={{textTransform: "lowercase"}}>k<span style={{textTransform: "uppercase"}}>W</span>h</span>}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{ getMonthName('01') }</td>
                <td>{props.data.plant_production_jan??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('02') }</td>
                <td>{props.data.plant_production_feb??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('03') }</td>
                <td>{props.data.plant_production_mar??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('04') }</td>
                <td>{props.data.plant_production_apr??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('05') }</td>
                <td>{props.data.plant_production_may??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('06') }</td>
                <td>{props.data.plant_production_jun??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('07') }</td>
                <td>{props.data.plant_production_jul??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('08') }</td>
                <td>{props.data.plant_production_aug??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('09') }</td>
                <td>{props.data.plant_production_sep??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('10') }</td>
                <td>{props.data.plant_production_oct??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('11') }</td>
                <td>{props.data.plant_production_nov??<Skeleton />}</td>
            </tr>
            <tr>
                <td>{ getMonthName('12') }</td>
                <td>{props.data.plant_production_dec??<Skeleton />}</td>
            </tr>
            </tbody>
        </Table>
    </>)
}

const MeasurementRow = (units, data, title, icon, iconClass = 'bg-light-primary') => {
    return(<>
            <div className="transaction-item mb-1">
                <div className="d-flex">
                    <div className={`avatar rounded ${iconClass} me-1`} style={{padding: "5px"}}>
                        <span className="avatar-content">{icon}</span>
                    </div>
                    <div>
                        <h6 className="transaction-title" style={{marginBottom: 0 }}>
                            { (Number.isInteger(data))? `${data}${units}`:data }
                        </h6>
                        { title }
                    </div>
                </div>
            </div>
        </>
    );
}

export function WaterCurrentMeasurementData({...props}) {
    const {t} = useTranslation();
    //get int number
    let iconClass;
    let tMax = (!props.data.plant_max_production)?<Skeleton />:props.data.plant_max_production;
    let tCur = (!props.data.plant_current_value)?'??':props.data.plant_current_value;
    //set danger text is not in limit temperature
    if(Number.isInteger(tMax) && Number.isInteger(tCur)) {
        if(tMax < tCur) {
            iconClass = 'bg-light-danger';
        } else if(tMax === tCur) {
            iconClass = 'bg-light-warning';
        } else if(tMax > tCur) {
            iconClass = 'bg-light-success';
        }
    }

    return(
        <>
            { MeasurementRow('L/h', tMax, `${t('limit')}`, <RiContrastDrop2Fill size={'23px'}/>)}
            { MeasurementRow('L/h', tCur, t('last_reading'), <RiContrastDrop2Line size={'25px'} />, iconClass) }
        </>
    );
}

export function ThermalCurrentMeasurementData({...props}) {
    const {t} = useTranslation();

    //get int number
    let iconClass;
    let tMax = (!props.data.plant_margin_production_over)?<Skeleton />:props.data.plant_margin_production_over;
    let tMin = (!props.data.plant_margin_production_under)?<Skeleton />:props.data.plant_margin_production_under;
    let tCur = (!props.data.plant_current_value)?'??':props.data.plant_current_value;
    //set danger text is not in limit temperature
    if(Number.isInteger(tMax) && Number.isInteger(tMin) && Number.isInteger(tCur)) {
        if(tMax < tCur || tCur < tMin) {
            iconClass = 'bg-light-danger';
        } else if(tMax === tCur || tCur === tMin) {
            iconClass = 'bg-light-warning';
        } else if(tMax > tCur || tCur < tMin) {
            iconClass = 'bg-light-success';
        }
    }

    return(
        <>
            { MeasurementRow('ºC', tMax, `${t('maximum_female')}`, <BsThermometerSun size={'25px'} style={{marginLeft: "5px"}}/>)}
            { MeasurementRow('ºC', tCur, t('last_reading'), <BsThermometerHalf size={'25px'} />, iconClass) }
            { MeasurementRow('ºC', tMin, `${t('minimum_female')}`, <BsThermometerSnow size={'25px'}  style={{marginLeft: "5px"}}/>) }
        </>
    );
}

export function PlantSummary({...props}) {
    const {t} = useTranslation();
    //common data
    let title;
    let information;
    let subtitle = '';
    //plant data location + aux energy
    if(props.type === 1) {
        title = t('description');
        if(isSolarThermalByCaller(props)) {
            information = <SolarThermalPlantDesc {...props} />;
        } else if(isSolarPhotovoltaicByCaller(props)) {
            information = <SolarPhotovoltaicPlantDesc {...props} />;
        } else if(isThermalByCaller(props)) {
            information = <ThermalPlantDesc {...props} />;
        } else if(isWaterByCaller(props)) {
            information = <WaterPlantDesc {...props} />;
        }
    //plant projected data
    } else if(props.type === 2) {
        title = t('estimated_production');
        if(isSolarThermalByCaller(props)) {
            information = <ProjectedDataMonthly {...props} />;
        } else if(isSolarPhotovoltaicByCaller(props)) {
            information = <ProjectedDataMonthly {...props} />;
        } else if(isThermalByCaller(props)) {
            title = t('current_measurement');
            if(props.data.plant_current_value_date) {
                subtitle = getTimeDateTextFull(props.data.plant_current_value_date);
            }
            information = <ThermalCurrentMeasurementData {...props} />;
        } else if(isWaterByCaller(props)) {
            title = t('current_measurement');
            if(props.data.plant_current_value_date) {
                subtitle = getTimeDateTextFull(props.data.plant_current_value_date);
            }
            information = <WaterCurrentMeasurementData {...props} />;
        }
    }

    return (
        <div className={`card ${isMobileOnly && "card-mbl"}`}>
            <CardHeaderNoIcon title={title} subtitle={subtitle} />
            <div className={`card-body mt-1 ${isMobileOnly && "card-body-mbl pt-20-mbl"}`}>
                { information }
            </div>
        </div>
    )
}