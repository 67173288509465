import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import PageAuthentication from "view/Authentication/PageAuthentication";
import {getTextUrlClean} from "../../utils/Types";
import _ from "lodash";

export default function ProtectedRoute({...props}) {
    //if group caller is a auth component and user is logged in...
    if(['login', 'signup'].includes(props.caller)) {
        if(props.isAuth) {
            // get first render plant type
            const plantType = _.get(window, 'renderLogic.plant_type', -1)
            const plantId = _.get(window, 'renderLogic.plant_id', -1)
            const plantName = _.get(window, 'renderLogic.plant_name', -1)
            // if set plant types and plant id and name...
            if(plantType !== -1) {
                // get base url in function of plant type
                let baseUrl = 'plants/thermal'
                // PV
                if(plantType === 0) {
                    baseUrl = 'plants/photovoltaic'
                } else if(plantType === 1) { // ST
                    baseUrl = 'plants/thermal'
                } else if(plantType === 2) { // T
                    baseUrl = 'plants/temperature'
                } else if(plantType === 3) { // W
                    baseUrl = 'plants/water'
                } // if it has only on plant, redirect to plant detail
                if(plantId !== -1 && plantName !== -1) {
                    return <Navigate to={`/dashboard/${baseUrl}/${plantId}-${getTextUrlClean(plantName)}`} />;
                } // if it has more ont plant of same type, redirect to plant list type
                return <Navigate to={`/dashboard/${baseUrl}`} />;
            } // redirect to home if exist plants of diff types
            return <Navigate to="/" />;
        } else if(props.caller === 'login') {
            return <PageAuthentication path="login"/>
        } else if(props.caller === 'signup') {
            return <PageAuthentication path="signup"/>
        }
    } else if(props.caller === 'root' && !props.isAuth) {
        return <Navigate to="/login" />;
    }

    return <Outlet />
}