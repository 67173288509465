import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from 'App';
import 'config';
import 'i18n';
import PageMessages from 'view/PageMessages';

//https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
    dsn: (function(){
        if("development" === process.env.NODE_ENV) {
            return null;
        } else {
            return "https://41eb263a42f942508fa29610be14cd22@o1218844.ingest.sentry.io/6360836";
       }
    })(),
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
});

ReactDOM.render(
  <>
      <React.StrictMode> { /* render twice in development */ }
          <Suspense fallback={ <PageMessages element="loader" />}>
              <App />
          </Suspense>
      </React.StrictMode>
  </>,
  document.getElementById('root')
);