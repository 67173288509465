import {getLanguage} from "utils/Language.js";
import {disableElementRequestCaller, enableElementRequestCaller, validate} from "utils/Forms";
import i18n from "i18next";
import {removeTokensSessions} from "./Auth";

export const getUrlParams = (url, init = true) => {
    url = (init)?'https://foo.com/' + url:url;
    return (new URL(url)).searchParams;
}

export const getHeaders = () => {
    //sent request with credentials data
    return {
        'headers': {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': getLanguage(),
            'Authorization': `Bearer ${localStorage.getItem('t1')}`
        }
    };
}

export const afterSubmit = (e, handler = () => { }) => {
    //end the progress bar..
    window.progressReference.current.complete();
    //enable button
    enableElementRequestCaller(e);
    //call to handler
    handler();
}

export const beforeSubmit = (e = null, handler = () => { }) => {
    //revoke all default events
    e.preventDefault();
    e.stopPropagation();
    //disable submit button
    disableElementRequestCaller(e);
    //call to after submit is not validated...
    if(!validate(e)) { return afterSubmit(e); }
    //complete progress bar
    window.progressReference.current.continuousStart();
    //call a handler
    handler();
}

export const sessionExpired = () => {
    //not show again if is expired flag yet...
    if(window.session_expired) { return; }
    //not event again...
    window.session_expired = true;
    //when session expires...
    global.config.notyf_object.error(i18n.t('user.no_session'));
    //remove tokens
    removeTokensSessions();
    //event to redirect
    setTimeout(() => {
        window.setIsAuth(0);
    }, 2000);
}

export const commonFailure = (error) => {
    //check errors
    if (error.response && error.response.status) {
        if(error.response.status === 400) {
            return global.config.notyf_object.error(i18n.t('request.bad_request'));
        } else if(error.response.status === 401) { //no session
            return sessionExpired();
        } else if(error.response.status === 429) {
            return global.config.notyf_object.error(i18n.t('request.max_requests'));
        } else if (error.response.status === 404) {
            return global.config.notyf_object.error(i18n.t('request.not_found'));
        }
    } //default fail error or 500
    global.config.notyf_object.error(i18n.t('request.unknown'));
}

//success event when request is good
export const commonSuccess = (response, successCallBack = () => {}) => {
    if(response.data.success) {
        successCallBack(response.data);
    } else {
        global.config.notyf_object.error(i18n.t('request.unknown'));
    }
}