import React, {useRef, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import { Menu, Dropdown, Button } from 'antd';
import {CalendarOutlined, ReloadOutlined} from '@ant-design/icons';
import moment from "moment-timezone";
import {geTimeByTimeLine, isThisMonth, isThisYear, isToday} from "../../utils/Time";

//drop down list
export function DropdownTime({...props}) {
    //translations
    const {t} = useTranslation();
    const configuration = props.configuration;
    //set default text to to menu
    let buttonText = t(configuration.text);
    //set default menu
    let defaultMenu = (configuration.menu)?configuration.menu:['time.today','time.month','time.year'];
    //if is set timeline
    if(configuration.timeLine) {
        //set endpoint
        if(configuration.timeLine.text === 'time.today' && isToday(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } else if(configuration.timeLine.text === 'time.month' && isThisMonth(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } else if(configuration.timeLine.text === 'time.year' && isThisYear(geTimeByTimeLine(configuration.timeLine))) {
            buttonText = t(configuration.timeLine.text);
        } else {
            buttonText = t('custom');
        }
    }

    //when element on list is clicked
    const handleMenuClick = (e) => {
        //get selected text
        const text = e.domEvent.target.closest('.ant-dropdown-menu-item').getAttribute('data-text');
        const timeLine = {text: text, clicks: 0 };
        const loaders = { plot: false, request: true, error: false };
        //set endpoint
        if(text === 'time.today') {
            props.handler({ ...configuration,
                text: text, url: `time.today?day=${moment().format('YYYY-MM-DD')}`,
                timeLine: timeLine, loaders: loaders
            });
        } else if(text === 'time.month') {
            props.handler({ ...configuration,
                text: text, url: `time.month?year=${moment().format('YYYY')}&month=${moment().format('MM')}`,
                timeLine: timeLine, loaders: loaders
            });
        } else if(text === 'time.year') {
            props.handler({ ...configuration,
                text: text, url: `time.year?year=${moment().format('YYYY')}`,
                timeLine: timeLine, loaders: loaders
            });
        }
    }
    //check if element is disabled
    const isDisabled = (text) => {
        if(configuration.timeLine && configuration.timeLine.clicks !== 0) { return false;}
        return configuration.text === text;
    }

    //create a menu
    const menu = (
        <Menu>
            {
                defaultMenu.map((text, index) => (
                    <Menu.Item key={text} data-text={text} onClick={handleMenuClick} disabled={isDisabled(text)} className={'dropdown-item-small'}>
                        { t(text) }
                    </Menu.Item>
                ))
            }
        </Menu>
    );

    return (
        <Dropdown.Button disabled={defaultMenu.length === 1} key={buttonText} size='small' trigger='click' overlay={menu}
                         placement="bottomRight" icon={<CalendarOutlined />} >
            { buttonText }
        </Dropdown.Button>
    )
}

export default function TimeIntervalsMenu({...props}) {
    //reference to delete events of button
    const button = useRef();
    const configuration = props.configuration;
    //translations
    const {t} = useTranslation();
    //set use effect dependency
    const useEffectDependency = t(configuration.text);
    //disable all event on button
    useEffect(() => {
        const elem = button.current.querySelector('.ant-btn-group button:first-child');
        if(elem) { elem.replaceWith(elem.cloneNode(true)); }
    }, [useEffectDependency]);

    //reload button
    const reload = (e) => {
        //remove blur on click button
        e.target.closest('.no-focus').blur();
        //handler to reload
        props.handler({ ...configuration, loaders: { plot: false, request: true, error: false } });
    }

    return (
        <div className="time-card-wrapper d-flex align-content-center" ref={button}>
            { !props.configuration.timeLine.disabledHistory && <DropdownTime {...props} /> }
            <div style={{marginLeft: "-1px"}}>
                <Button className={"no-focus"} icon={<ReloadOutlined />} size='small' onClick={reload}/>
            </div>
        </div>
    )

}