import React from "react";
import { Link } from "react-router-dom"
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function BrandSimple({...props}) {
    // default style for desktop
    let style = {fontSize: "3.5em"}
    // style for smartphone
    if(isMobileOnly) { style = {...style, fontSize: "2em"} }
    //get simple brand
    let brand = <div className="brand-text-black" style={style}>Seye{/*<sub style={{fontSize: "10px"}}>Alpha</sub> */}</div>;
    //if isset to on the caller...
    if(props.to) { //set link on logo
        brand = <Link to={props.to}>{brand}</Link>
    }

    return ( <> { brand } </>)
}