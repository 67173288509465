import Avatar from "react-avatar";
import React from "react";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export function addMultiChildEvent(reference) {
    //event for multy child
    reference.current.querySelectorAll('.dropdown').forEach(function(dd) {
        dd.addEventListener('hide.bs.dropdown', window.boostrap_dropdown_child_method);
    }); //event for click menu when is clicked
    reference.current.querySelectorAll('.dropdown-item-click-close').forEach(function(dd) {
        dd.addEventListener('click', window.boostrap_dropdown_close_on_click);
    }); //event for auto open and close
    reference.current.querySelectorAll('.dropdown-auto').forEach(function(dd) {
        dd.addEventListener('mouseenter', window.boostrap_dropdown_auto_open);
        dd.addEventListener('mouseleave', window.boostrap_dropdown_auto_close);
    }); //event only for auto close
    reference.current.querySelectorAll('.dropdown-auto-close').forEach(function(dd) {
        dd.addEventListener('mouseleave', window.boostrap_dropdown_auto_close);
    });
}

export function removeMultiChildEvent(reference) {
    if(!reference.current) { return; }
    reference.current.querySelectorAll('.dropdown').forEach(function(dd) {
        dd.removeEventListener('hide.bs.dropdown', window.boostrap_dropdown_child_method);
    });
    reference.current.querySelectorAll('.dropdown-item-click-close').forEach(function(dd) {
        dd.removeEventListener('click', window.boostrap_dropdown_close_on_click);
    }); //remove event for auto menu
    reference.current.querySelectorAll('.dropdown-auto').forEach(function(dd) {
        dd.removeEventListener('mouseenter', window.boostrap_dropdown_auto_open);
        dd.removeEventListener('mouseleave', window.boostrap_dropdown_auto_close);
    }); //remove event for auto close only
    reference.current.querySelectorAll('.dropdown-auto-close').forEach(function(dd) {
        dd.removeEventListener('mouseleave', window.boostrap_dropdown_auto_close);
    });
}

export function getUserAvatar(fullName) {
    return <Avatar color='#5e709b' name={fullName} textSizeRatio={2} maxInitials={3} round={true} size={isMobileOnly?30:40}/>;
}