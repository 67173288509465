import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import React from "react";
//import NavbarButtonLanguage from "components/Navbar/Buttons/LanguageButtonNavbar";
import NavbarButtonUser from "components/Navbar/Buttons/UserButtonNavbar";
//import NavbarButtonNotification from "components/Navbar/Buttons/NotificationButtonNavbar";
import BrandSimple from "components/Titles/BrandSimple";

export default function NavbarDashboard({...props}) {
    return (
        <>
            <div className="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border navbar-fixed-1" role="navigation" data-menu="menu-wrapper" data-menu-type="floating-nav">
                <div className="navbar-header" />
                <div className="navbar-container d-flex content">
                    <ul className="nav navbar-nav">
                        <li className="nav-item">
                            <BrandSimple to="/dashboard"/>
                        </li>
                    </ul>
                    <ul className="nav navbar-nav align-items-center ms-auto">
                        <NavbarButtonUser {...props} />
                    </ul>
                </div>
            </div>
        </>
    )
}