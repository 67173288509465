import React, {useEffect, useRef, useState} from "react";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import {useTranslation} from "react-i18next";
import ReactApexChart from "react-apexcharts/dist/react-apexcharts.min";
import {
    getPlotComponentByLoader,
    getPlotSubtitle, getPlotTimeMenu
} from "../../utils/Plots/PlotsCommon";
import {
    getEventsPlotApexObject
} from "../../utils/Plots/PlotsApexCharts";
import {getEventsPlotData} from "../../utils/Requests/Analysis";
import {
    getOverproductionTextByCaller,
    getUnderproductionTextByCaller,
    isThermalByCaller, isWaterByCaller
} from "../../utils/Types";
import {Minus, Plus, ThumbsUp} from "react-feather";
import { TbDatabaseExclamation } from "react-icons/tb";


//when request is success
const successRequest = (configuration, setConfiguration, response) => {
    let data = response.alerts;
    if(configuration.type === 'status') {
        data = response.status;
    }

    let total = 0;
    Object.keys(data).forEach(key => {
        total += data[key];
    });

    //get default object for plot
    let defaultPlotData = getEventsPlotApexObject({ caller: configuration.caller, rawData: { ...data, total: total }});
    // [event][status]
    // [overproduction][status]
    defaultPlotData.series[0].data[0] = response.status.overproduction;
    // [underproduction][status]
    defaultPlotData.series[1].data[0] = response.status.underproduction;
    // [no_reading][status]
    defaultPlotData.series[2].data[0] = response.status.no_reading;
    // [ok][status]
    defaultPlotData.series[3].data[0] = response.status.ok;
    // [event][alerts]
    // [overproduction][alerts]
    defaultPlotData.series[0].data[1] = response.alerts.overproduction;
    // [underproduction][alerts]
    defaultPlotData.series[1].data[1] = response.alerts.underproduction;
    // [no_reading][alerts]
    defaultPlotData.series[2].data[1] = response.alerts.no_reading;
    // [ok][alerts]
    defaultPlotData.series[3].data[1] = response.alerts.ok;
    //remove not needed data...
    if(isThermalByCaller(configuration) || isWaterByCaller(configuration)) {
        //remove status
        // [event][status]
        // [overproduction][status]
        defaultPlotData.series[0].data.splice(0,1);
        // [underproduction][status]
        defaultPlotData.series[1].data.splice(0,1);
        // [no_reading][status]
        defaultPlotData.series[2].data.splice(0,1);
        // [ok][status]
        defaultPlotData.series[3].data.splice(0,1);
        //xaxis label
        defaultPlotData.options.xaxis.categories.splice(0,1);
    } //set to re-render view
    defaultPlotData.loaded = true;
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: false}, plot: defaultPlotData })
}
//when request fails
const failRequest = (configuration, setConfiguration, response) => {
    setConfiguration({...configuration, text: configuration.text, url: configuration.url, loaders: {plot: true, request: false, error: true}, plot: configuration.plot })
}

export function CardPlotEvents({...props}) {
    const {t} = useTranslation();
    const chartRef = useRef();

    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.today',
        url: 'time.today',
        plot: getEventsPlotApexObject({ caller: props.caller }),
        loaders: {plot: false, request: true, error: false},
        timeLine: {text: 'time.today', clicks: 0, disabledHistory: true},
        isLoading: true
    });

    //event to first load plot...
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if (!configuration.loaders.request) { return }
        //set energy request
        const setData = (configuration, callback = () => {}) => {
            getEventsPlotData({...configuration, plantId: configuration.plantId, interval: configuration.url}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                successRequest(configuration, setConfiguration, response, callback);
            }, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                failRequest(configuration, setConfiguration, response);
            });
        } //call to load data
        setData(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    //set char component by default
    const charComponent = <ReactApexChart ref={chartRef} options={configuration.plot.options} series={configuration.plot.series} type="bar" height={150}/>;
    const title = (isWaterByCaller(configuration) || isThermalByCaller(configuration))?t('plant.alerts.hourly'):t('alerts');
    const charToggleSeries= function(e, serie) {
        e.currentTarget.classList.toggle("legend-deselect");
        chartRef?.current?.chart.toggleSeries(serie);
    } //set char legend
    const charLegend = <>
        <ul style={{margin: "0px", padding: "0px"}}>
            <li className="chartjs-legend-li" onClick={(e) => charToggleSeries(e, t(getOverproductionTextByCaller(configuration)))}>
                <div id="label-10555319-4b33-4f65-b706-3271b2f53d46" className="align-self-center">
                    <div className="bg-light-info avatar-no-background chartjs-label-icon">
                        <Plus size={16} />
                    </div>
                </div>
                <p className="chartjs-label-text align-self-center ms-50">{ t(getOverproductionTextByCaller(configuration)) }</p>
            </li>
            <li className="chartjs-legend-li" onClick={(e) => charToggleSeries(e, t(getUnderproductionTextByCaller(configuration)))}>
                <div id="label-f6ddbf3a-dd18-41b6-bea2-e0fd41dadf73" className="align-self-center">
                    <div className="bg-light-primary avatar-no-background chartjs-label-icon">
                        <Minus size={16} />
                    </div>
                </div>
                <p className="chartjs-label-text align-self-center ms-50">{ t(getUnderproductionTextByCaller(configuration)) }</p>
            </li>
            <li className="chartjs-legend-li" onClick={(e) => charToggleSeries(e, t('events.no_reading'))}>
                <div id="label-56c034a4-17c3-4bb2-a8ff-ed28d239680d" className="align-self-center">
                    <div className="bg-light-danger avatar-no-background chartjs-label-icon">
                        <TbDatabaseExclamation size={16} />
                    </div>
                </div>
                <p className="chartjs-label-text align-self-center ms-50">{t('events.no_reading') }</p>
            </li>
            <li className="chartjs-legend-li" onClick={(e) => charToggleSeries(e, t('events.ok'))}>
                <div id="label-e08c4140-7bb3-4032-aa3e-e5a3abc33de1" className="align-self-center">
                    <div className="bg-light-success avatar-no-background chartjs-label-icon">
                        <ThumbsUp size={16} />
                    </div>
                </div>
                <p className="chartjs-label-text align-self-center ms-50">{ t('events.ok') }</p>
            </li>
        </ul>
    </>;


    return (
        <div className={`card card-${configuration.caller}-plot`}>
            { /* header */ }
            <CardSimpleHeader subtitleClass="font-small-1" style={{paddingBottom: "0px"}} subtitle={ getPlotSubtitle(configuration, setConfiguration) } title={title} rightComponent={ getPlotTimeMenu(configuration, setConfiguration) } />
            { /* body */ }
            <div className={"card-body pb-0 pt-0"}>
            { /* first plot */ }
                <div className="d-flex pb-0 pt-0" style={{overflowX: "auto"}}>
                    <div className="flex-grow-1 align-self-center grey-apex-charts-tooltip">
                        { getPlotComponentByLoader({ ...configuration, loaderHeight: 150 }, charComponent) }
                    </div>
                    <div className="legend-wrapper align-self-center hide-450-575" style={{marginTop: "-5px"}}>
                        { getPlotComponentByLoader({ ...configuration }, charLegend) }
                    </div>
                </div>
            </div>
        </div>
    )
}