const languages = require('utils/Language.js')
const {Notyf} = require("notyf");
const {isMobileOnly} = require("react-device-detect");

module.exports = global.config = {
    languages: languages.getPermittedLanguages(),
    brand_url: 'img/seye.svg',
    api_url_v1: 'https://' + process.env.REACT_APP_API_ENDPOINT +'/api/v1/',
    notyf_object: new Notyf({
        position: {x:'center',y:'top'}
    }),
    notyf_object_dash: new Notyf({
        position: {x:'right',y:'bottom'}
    }),
    mbl_version: (() => {
        return isMobileOnly
    })()
};