import React from "react";
import 'assets/css/loader.css';
import {Trans, useTranslation} from "react-i18next";
import LayoutSimplePage from "components/Layout/LayoutSimplePage";
import PenToolDivider from "components/Divider/PenToolDivider";
import SimpleDivider from "components/Divider/SimpleDivider";
import CardTable from "components/Cards/CardTable";
import {getLanguage} from "utils/Language";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFirefox, faChrome, faInternetExplorer, faSafari, faOpera, faEdge } from '@fortawesome/free-brands-svg-icons';

export default function PageCookies({...props}) {
    const { t } = useTranslation();

    const ownCookieTable = {
        "title": t('cookies.block_02_t1_title'),
        "description": t('cookies.block_02_t1_description'),
        "headers": [{
                "text": t('cookies.block_02_t1_c1_name')
            },{
                "text": t('cookies.block_02_t1_c2_name'),
                "style": {minWidth: "330px"}
            },{
                "text": t('cookies.block_02_t1_c3_name'),
                "style": {minWidth: "280px"}
        }],
        "rows": [
            [{
                "text": t('cookies.block_02_t1_r1_c1_name')
            },{
                "text": t('cookies.block_02_t1_r1_c2_name')
            },{
                "text":  t('cookies.block_02_t1_r1_c3_name')
            }],[{
                "text": t('cookies.block_02_t1_r2_c1_name')
            },{
                "text": t('cookies.block_02_t1_r2_c2_name')
            },{
                "text":  t('cookies.block_02_t1_r2_c3_name')
            }]
        ]
    }

    const thirdCookiesTable = {
        "title": t('cookies.block_02_t2_title'),
        "description": t('cookies.block_02_t2_description'),
        "headers": [{
            "text": t('cookies.block_02_t2_c1_name')
        },{
            "text": t('cookies.block_02_t2_c2_name'),
            "style": {minWidth: "300px"}
        },{
            "text": t('cookies.block_02_t2_c3_name'),
            "style": {minWidth: "500px"}
        },{
            "text": t('cookies.block_02_t2_c4_name'),
            "style": {minWidth: "230px"}
        }],
        "rows": [
            [{
                "text": t('cookies.block_02_t2_r1_c1_name')
            },{
                "text": t('cookies.block_02_t2_r1_c2_name')
            },{
                "text": t('cookies.block_02_t2_r1_c3_name')
            },{
                "text":  <Trans i18nKey="cookies.block_02_t2_r1_c4_name" components={[
                   <a href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage' target='_blank' rel="noreferrer">use</a>,
                   <br />,
                   <a href={ 'https://policies.google.com/privacy?hl=' + getLanguage() } target='_blank' rel="noreferrer">privacity</a>,
                ]} />
            }]
        ]
    }

    //get current language
    const lang = getLanguage();
    //firefox cookie link default
    let firefoxCookieLink = 'https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection';
    let explorerCookieLink = 'https://support.microsoft.com/en-gb/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc';
    let safariCookieLink = 'https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac';
    let operaCookieLink = 'https://help.opera.com/en/latest/web-preferences/#cookies';
    let edgeCookieLink = 'https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd';
    //set cookies link in function of user lang
    if(lang === 'ca') {
        firefoxCookieLink = 'https://support.mozilla.org/ca/kb/activa-i-desactiva-les-galetes-que-les-pagines-web';
        safariCookieLink = 'https://support.apple.com/ca-es/guide/safari/sfri11471/mac';
    } else if(lang === 'es') {
        firefoxCookieLink = 'https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias';
        explorerCookieLink = 'https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc';
        safariCookieLink = 'https://support.apple.com/es-es/guide/safari/sfri11471/mac';
        edgeCookieLink = 'https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd';
    }


    return (
        <LayoutSimplePage>
            { /* Title */}
            <h2>{ t('cookies.title') }</h2>
            <p className="text-justify">{ t('cookies.description') }</p>
            <PenToolDivider />
            { /* Que eś una cookie? */}
            <h3 className="mb-1">{ t('cookies.block_01_title') }</h3>
            <p className="text-justify">
                { t('cookies.block_01_p1') }
            </p>
            <SimpleDivider />
            { /* Quines? */}
            <h3 className="mb-1">{ t('cookies.block_02_title') }</h3>
            <p className="text-justify">
                { t('cookies.block_02_p1') }
            </p>
            <p className="text-justify">
                <Trans i18nKey="cookies.block_02_p2" components={ [<b />] } />
            </p>
            <p className="text-justify">
                <Trans i18nKey="cookies.block_02_p3" components={ [<b />] } />
            </p>
            <p className="text-justify">
                <Trans i18nKey="cookies.block_02_p4" components={ [<b />] } />
            </p>
            <p className="text-justify">
                { t('cookies.block_02_p5') }
            </p>
            <div className="mt-2" />
            <CardTable table={ownCookieTable}/>
            <CardTable table={thirdCookiesTable}/>
            <p className="text-justify">
                { t('cookies.block_02_p6') }
            </p>
            <SimpleDivider />
            { /* Advertencia */}
            <h3 className="mb-1">{ t('cookies.block_03_title') }</h3>
            <p className="text-justify">
                <Trans i18nKey="cookies.block_03_p1" components={ [<a href='https://www.rdmes.com/#contacte' target='_blank' rel="noreferrer">contact</a>] } />
            </p>
            <SimpleDivider />
            { /* Ajuda */}
            <h3 className="mb-1">{ t('cookies.block_04_title') }</h3>
            <p className="text-justify">
                { t('cookies.block_04_p1') }
            </p>
            <p className="text-justify">
                { t('cookies.block_04_p2') }
            </p>
            <div className="d-flex flex-row" style={{fontSize: "25px"}}>
                <a className="" style={{color: "#5e5f60"}} href={ `https://support.google.com/chrome/bin/answer.py?hl=${getLanguage()}&answer=95647`} target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faChrome} /></a>
                <a className="ps-1" style={{color: "#5e5f60"}} href={ firefoxCookieLink } target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faFirefox} /></a>
                <a className="ps-1" style={{color: "#5e5f60"}} href={ explorerCookieLink } target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faInternetExplorer} /></a>
                <a className="ps-1" style={{color: "#5e5f60"}} href={ safariCookieLink } target='_blank' rel="noreferrer"> <FontAwesomeIcon icon={faSafari} /></a>
                <a className="ps-1" style={{color: "#5e5f60"}} href={ operaCookieLink } target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faOpera} /></a>
                <a className="ps-1" style={{color: "#5e5f60"}} href={ edgeCookieLink } target='_blank' rel="noreferrer"><FontAwesomeIcon icon={faEdge} /></a>
            </div>
            <SimpleDivider />
            { /* Google Analytics Disabler */}
            <h3 className="mb-1">{ t('cookies.block_05_title') }</h3>
            <p className="text-justify">
                <Trans i18nKey="cookies.block_05_p1" components={[<a href={ 'https://tools.google.com/dlpage/gaoptout?hl=' + getLanguage() } target='_blank' rel="noreferrer">link</a>]} />
            </p>
        </LayoutSimplePage>
    )
}