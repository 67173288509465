import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {User} from "react-feather";
import {useForm} from "react-hook-form";
import SettingsTextInput from "../Forms/Inputs/settings/SettingsTextInput";
import SettingsEmailInputs from "../Forms/Inputs/settings/SettingsEmailInputs";
import SettingsComboInput from "../Forms/Inputs/settings/SettingsComboInput";
import {updateAccountRequest} from "utils/Requests/Auth";
import {getLanguage, setLanguage} from "utils/Language";
import {getUserAvatar} from "utils/Menu";
import i18n from "i18next";
import {removeBlurButton} from "utils/Buttons";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export default function CardProfileDetails({...props}) {
    const {t} = useTranslation();
    //set form reference
    const formRef = useRef();
    //call to use hook
    const methods = useForm({ mode: 'onSubmit' }); //get used methods
    const { handleSubmit, reset, formState: { isSubmitting, isDirty } } = methods;

   //call on submit
    const onSubmit = async (data) => {
        //if element are submit...
        if(!isDirty) { return global.config.notyf_object.success(i18n.t('account.update_account_success')); }
        //call to update account...
        await updateAccountRequest(data, () => {
            //if language change update the language interface...
            if(getLanguage() !== data.user_language) {
                //set language to interface
                setLanguage(data.user_language);
            } //update user data...
            props.updateUser({...props.user, ...data,
                user_avatar: getUserAvatar(`${data.user_name} ${data.user_last_name}`),
                user_full_name: `${data.user_name} ${data.user_last_name}`
            }); //save the last request data
            reset(data);
        });
    }

    useEffect(() => {
        //if is loading not load data...
        if(props.isLoading) { return }
        //on load, set data to form
        reset({
            user_email: props.user.user_email,
            user_name: props.user.user_name,
            user_last_name: props.user.user_last_name,
            user_language: getLanguage()
        });
    }, [reset, props.isLoading, props.user])

    return (
        <>
            <div ref={formRef} className={`card ${isMobileOnly && "card-mbl"}`}>
                <div className={`card-header ${!isMobileOnly && "border-bottom"}`}>
                    <h4 className="card-title d-flex flex-row">
                        <User size={20}/>
                        <span style={{marginLeft: "5px"}}>{t("account.profile_details")}</span>
                    </h4>
                </div>
                <div className={`card-body ${isMobileOnly ? "card-body-mbl pt-20-mbl" : "py-2 my-25"}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={"row"}>
                            <div className="col-lg-3 col-md-6 mb-1">
                                <SettingsTextInput isLoading={props.isLoading} methods={methods} label={t("name")}
                                                   placeholder="John" name={"user_name"}/>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-1">
                                <SettingsTextInput isLoading={props.isLoading} methods={methods} label={t('last_name')}
                                                   placeholder="Doe" name={"user_last_name"}/>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-1">
                                <SettingsEmailInputs isLoading={props.isLoading} methods={methods} name={"user_email"}/>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-1">
                                <SettingsComboInput isLoading={props.isLoading} label={t("language")}
                                                    options={[{value: 'ca', label: 'Català'}, {
                                                        value: 'es',
                                                        label: 'Castellano'
                                                    }, {value: 'en', label: 'English'}]} methods={methods}
                                                    name={"user_language"}/>
                            </div>
                        </div>
                        <div className="mt-1">
                            <button type="submit" className="btn btn-primary me-1"
                                    disabled={isSubmitting || props.isLoading}>
                                {t("save")}
                            </button>
                            <button type="button" onClick={() => {
                                removeBlurButton(formRef.current).then(() => reset());
                            }} className="btn btn-outline-secondary" disabled={isSubmitting || props.isLoading}>
                                {t("reset")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>)
}