import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import {CardDotWithDataVertical} from "components/Cards/CardDotWithData";
import {getTimeByTextLink} from "utils/Time";
import MenuTime from "components/Menu/TimeMenu";
import Skeleton from "react-loading-skeleton";
import {getAnalysisProduction} from "utils/Requests/Analysis";
import {getEnergyFormat, getWaterFormat} from "utils/Formats";
import {
    getBgClassByCaller,
    getIconComponentByCaller,
    isSolarPhotovoltaicByCaller, isWaterByCaller
} from "utils/Types";

const loaderSkeleton = <Skeleton width={100}/>

const getTitle = (t, configuration) => {
    if(isWaterByCaller(configuration)) {
        return t('plant.consumption_abbrev');
    } //solar
    return t('plant.production');
}

export function CardProduction({...props}) {
    const {t} = useTranslation();
    //current object data
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.today',
        url: 'time.today',
        dates: null,
        value: loaderSkeleton,
        defaultValue: loaderSkeleton,
        isLoading: true
    });

    //first load
    useEffect(() => {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //first load...
        if(!configuration.isLoading) { return }
        //set energy request
        const setProduction = (endpoint) => {
            //plant/analysis/solar/production/text
            getAnalysisProduction({ ...configuration, interval: endpoint}, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                //default values
                let val = getEnergyFormat(response.data.thermal*1000); //st num get in kwh
                //if solar photovoltaic
                if(isSolarPhotovoltaicByCaller(configuration)) {
                    val = getEnergyFormat(response.data.photovoltaic); //pv get num in wh
                } else if (isWaterByCaller(configuration)) {
                    val = getWaterFormat(response.data.consumption); //get water consumption
                }
                setConfiguration({...configuration, url: endpoint, isLoading: false, value: val});
            }, () => {
                //prevent crash with async function
                if (!isMounted) return null;
                setConfiguration({...configuration, isLoading: false, value: 'Error...'});

            });
        } //call to load data
        setProduction(configuration.url);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration]);

    return (
        <div className="card card-statistics">
            <CardSimpleHeader { ...configuration} title={ getTitle(t, configuration) }
                              rightComponent={<MenuTime configuration={configuration} handler={setConfiguration} />}
            />
            <div className="card-body statistics-body">
                <CardDotWithDataVertical {...configuration} avatarClass={"avatar-lg"} colorClass={getBgClassByCaller(props)}
                                         icon={getIconComponentByCaller(configuration, {width: 30, height: 30})}
                                         text={getTimeByTextLink(configuration)} number={configuration.value} />

            </div>
        </div>
    );
}