/* CHARTS JS */
import i18next from "i18next";
import {toFont as chartsJStoFont} from 'chart.js/helpers';
import {Minus, Plus, ThumbsUp} from "react-feather";
import React from "react";
import {getOverproductionTextByCaller, getUnderproductionTextByCaller} from "../Types";
import {render} from "react-dom";
import { TbDatabaseExclamation } from "react-icons/tb";

const { v4: uuid } = require('uuid');

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    if(!legendContainer) { return; }
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;
        legendContainer.appendChild(listContainer);
    }
    return listContainer;
};

const eventsHtmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart, options.containerID);
        if(!ul) { return; }
        // Remove old legend items
        while (ul.firstChild) { ul.firstChild.remove(); }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.classList.add('chartjs-legend-li');

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const box = document.createElement('div');
            box.id = `label-${uuid()}`;
            box.classList.add('align-self-center');
            box.classList.add('mb-10-360');
            // Text
            const textContainer = document.createElement('p');
            textContainer.classList.add('chartjs-label-text');
            textContainer.classList.add('align-self-center');
            textContainer.classList.add('hide-360');
            textContainer.classList.add('ms-50');
            textContainer.style.color = item.fontColor;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.append(box);

            let icon = '';
            let classSuffix = '';
            if(item.index === 0) {
                icon = <Plus size={16} />;
                classSuffix = 'info'
            } else if(item.index === 1) {
                icon = <Minus size={16} />;
                classSuffix = 'primary'
            } else if(item.index === 2) {
                icon = <TbDatabaseExclamation size={16} />;
                classSuffix = 'danger'
            } else if(item.index === 3) {
                icon = <ThumbsUp size={16} />;
                classSuffix = 'success'
            }
            render(<div className={`bg-light-${classSuffix} avatar-no-background chartjs-label-icon`}>{icon}</div>, box);

            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};

export function getEventsPlotChartsJs(cfg) {
    return {
        rawData: cfg.rawData,
        labels: [
            i18next.t(getOverproductionTextByCaller(cfg)),
            i18next.t(getUnderproductionTextByCaller(cfg)),
            i18next.t('events.no_reading'),
            i18next.t('events.ok')
        ],
        datasets: [
            {
                data: [12, 19, 3, 5],
                backgroundColor: [
                    '#e0f9fc',
                    '#eeedfd',
                    '#fceaea',
                    '#e5f8ed'
                ],
                borderColor: [
                    '#00cfe8',
                    '#887ef3',
                    '#ea5455',
                    '#46c76f'
                ],
                borderWidth: 1,
            },
        ],
        plugins: [eventsHtmlLegendPlugin],
        options: {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                raw: {
                    data: cfg.rawData
                },
                tooltip: {
                    // Disable the on-canvas tooltip
                    enabled: false,
                    external: function(context) {
                        const selected = context.tooltip.dataPoints[0];
                        const data = context.tooltip.dataPoints[0].chart.config._config.options.plugins.raw.data;
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        let innerHtml = '<thead>';
                        let eventTotals = 0;

                        if(selected.dataIndex === 0) {
                            eventTotals = data.overproduction;
                        } else if(selected.dataIndex === 1) {
                            eventTotals = data.underproduction;
                        } else if(selected.dataIndex === 2) {
                            eventTotals = data.no_reading;
                        } else if(selected.dataIndex === 3) {
                            eventTotals = data.ok;
                        }

                        innerHtml += `<tr><th>${selected.label}</th></tr>`;
                        innerHtml += '</thead><tbody>';
                        innerHtml += `<tr><td>${selected.parsed}% (${eventTotals}/${data.total} ${i18next.t('plants').toLowerCase()})</td></tr>`;
                        innerHtml += '</tbody>';

                        let tableRoot = tooltipEl.querySelector('table');
                        tableRoot.innerHTML = innerHtml;

                        const position = context.chart.canvas.getBoundingClientRect();
                        const bodyFont = chartsJStoFont(tooltipModel.options.bodyFont);

                        // Display, position, and set styles for font
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.font = bodyFont.string;
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.backgroundColor = 'rgba(34,34,34,0.83)';
                        tooltipEl.style.border = '1px solid black';
                        tooltipEl.style.padding = '5px';
                        tooltipEl.style.color = '#fff';
                        tooltipEl.style.borderRadius = '3px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: cfg.legend,
                },
                legend: {
                    display: false,
                    position: 'right'
                    /*labels: {
                        boxWidth: 0,
                        generateLabels: function(chart) {
                            const data = chart.data;
                            if (data.labels.length && data.datasets.length) {
                                return data.labels.map(function(label, i) {
                                    const ds = data.datasets[0];
                                    const arcOpts = chart.options.elements.arc;

                                    return {
                                        text: label,
                                        fillStyle: ds.backgroundColor[i],
                                        strokeStyle: ds.borderColor[i],
                                        //lineWidth: 1,
                                        hidden: arcOpts._context.chart._hiddenIndices[i],
                                        index: i
                                    };
                                });
                            }
                            return [];
                        }
                    }*/
                }
            }
        }
    }
}