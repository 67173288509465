import { useEffect , useRef} from "react";
import { useLocation } from "react-router-dom";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value
    });
    return ref.current;
}

export default function Previous() {
    const { pathname } = useLocation();
    const prevLocation = usePrevious(pathname);
    useEffect(() => {
        window.currLocation = pathname;
        window.prevLocation = prevLocation;
    }, [pathname, prevLocation]);
    return null;
}