import React from "react";
import {isDashboardType} from "../../utils/Types";

export function CardSimpleHeader({...props}) {
    return (
        <>
            <div className={`card-header align-items-center`} style={{...props.style}} ref={element => {
                    if (element && isDashboardType(props)) element.style.setProperty('padding-bottom', '0', 'important');
                }}
            >
                <div className={"d-flex align-items-center"}>
                    {props.icon && props.icon} <h4 className="card-title">{props.title}</h4>
                </div>
                { props.rightComponent && props.rightComponent }
                <span className={`${props.subtitleClass}`} style={{width: '100%'}}>{ props.subtitle }</span>
            </div>
        </>
    )
}