import React, {useState, useRef} from "react";
import {Card} from "reactstrap";
// import {Droplet, Sun, Thermometer, Zap} from "react-feather";
import {useTranslation} from "react-i18next";
import {PlantsProductionTable} from "components/Table/PlantsProductionTable";
import {CardSimpleHeader} from "components/Cards/CardSimpleHeader";
import MenuTime from "components/Menu/TimeMenu";
import {isSolarPhotovoltaicByCaller, isSolarThermalByCaller, isThermalByCaller, isWaterByCaller} from "utils/Types";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

/* grid */
export function CardPlantProductionTables({...props}) {
    const {t} = useTranslation();
    const dataTableContainerReference = useRef();
    const [configuration, setConfiguration] = useState({
        ...props,
        text: 'time.hourly'
    });

    //set a title and icon for table
    // let icon = '';
    let title = t('plant.production_real');
    let rightComponent = <MenuTime configuration={
        {...configuration, menu: ['time.hourly', 'time.daily', 'time.monthly'], notCustom: true, notRefresh: true}
    } handler={setConfiguration} />
    //if is solar thermal
    if(isSolarThermalByCaller(configuration)) {
        // icon = <Sun size={20} style={{marginRight: "5px"}}/>;
    } else if(isSolarPhotovoltaicByCaller(configuration)) {
        // icon = <Zap size={20} style={{marginRight: "5px"}}/>;
    } else if(isThermalByCaller(configuration)) {
        title = t('measurements');
        rightComponent = null;
        // icon = <Thermometer size={20} style={{marginRight: "5px"}}/>;
    } else if(isWaterByCaller(configuration)) {
        title = t('measurements');
        // icon = <Droplet size={20} style={{marginRight: "5px"}}/>;
    }

    /* render component */
    return (
        <>
            <span ref={dataTableContainerReference}>
            <Card className={isMobileOnly ? "card-mbl": ""}>
                <CardSimpleHeader icon={undefined} title={title}
                    rightComponent={ rightComponent }
                />
                { /* horizontal line between header and table */ }
                {!isMobileOnly && <hr style={{marginTop: 0}}/> }
                {/* Fix for reset grid on change time. Other method save the status of table: page, rows per page, sort, etc... */}
                { configuration.text === 'time.hourly' && <PlantsProductionTable caller={configuration.caller} plantId={configuration.plantId} tableProps={configuration.tableProps} time={configuration.text}/> }
                { configuration.text === 'time.daily' && <PlantsProductionTable caller={configuration.caller} plantId={configuration.plantId} tableProps={configuration.tableProps} time={configuration.text}/> }
                { configuration.text === 'time.monthly' && <PlantsProductionTable caller={configuration.caller} plantId={configuration.plantId} tableProps={configuration.tableProps} time={configuration.text}/> }
            </Card>
        </span>
        </>
    );
}