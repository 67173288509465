import {useTranslation} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import React from "react";

export default function SettingsTextInput({ ...props }) {
    const { t } = useTranslation();

    //get use hook form methods
    const { register, formState: { errors } } = props.methods;
    //set a validation object
    const validation = {
        required: "validations.required"
    }; //create register
    const inputProps = register(props.name, (props.validation)?{ ...props.validation, ...validation }:validation);

    return (
        <>
            <label htmlFor={props.name} className="form-label form-label">
                { props.label }
            </label>
            { props.isLoading && <div><Skeleton style={{lineHeight: "39px"}} /></div> }
            { !props.isLoading && <input {...inputProps} placeholder={(props.placeholder)??props.label} type="search" className={`form-control ${(errors[props.name]?.message)?"is-invalid":""}`} /> }
            {  errors[props.name]?.message && <div className="invalid-feedback-always">{t(errors[props.name]?.message)}</div> }
        </>
    )
}