import {Trans, useTranslation} from "react-i18next";
import {AvatarSuchAsIcon} from "components/Icon/AvatarSuchAsIcon";
import {translateFromObject} from "utils/Language";
import {Link} from "react-router-dom";
import React from "react";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

export function CardWelcome({...props}) {
    const {t} = useTranslation();

    return (
        <div className={`card card-congratulations ${isMobileOnly ? "card-mbl" : ""}`}>
            <div className={`card-body text-center`} style={isMobileOnly ? {borderTop: '1px #e8e8e8 solid', padding: "15px 10px"}: {}}>
                { !isMobileOnly && <div className="avatar avatar-xl bg-primary shadow">
                    <AvatarSuchAsIcon icon={props.data.avatar}/>
                </div> }
                <div className="text-center">
                    <h1 className={`text-white ${isMobileOnly ? "mb-0" : "mb-1" }`}>
                        { t('hello') } { props.userName },
                    </h1>
                    <p className={`card-text m-auto ${isMobileOnly ? "w-75" : "w-75" }`} style={{fontWeight: "bold"}}>
                        { translateFromObject(props.data.title) }
                    </p>
                    <p className={`card-text m-auto ${isMobileOnly ? "w-75" : "w-75" }`}>
                        <Trans i18nKey={props.data.message} components={
                            [
                                <Link style={{color: "#91e9c5"}} to="/dashboard/plants/add">
                                    { t("here").toLowerCase() }
                                </Link>
                            ]
                        } />
                    </p>
                </div>
            </div>
        </div>
    )
}