import React from "react";
import {Link} from "react-router-dom";
import { setBackPage } from "utils/Pages/Back";
import {useTranslation} from "react-i18next";

export default function PrivacyPolicyLink({...props}) {
    const { t } = useTranslation();

    //assert to needed props back link
    if(!props.back) {
        throw new Error("The props.back is needed");
    }

    return (
        <Link to="/terms" onClick={() => { setBackPage(props.back); }}>
            { t('tos.input') }
        </Link>
    )
}